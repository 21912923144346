<template>
  <div class="holder-messages-list">
    <div class="messages-list" ref="messageList">
      <ul>
        <Message
          v-for="(item) in messages"
          :key="item.id"
          :item="item"
          :messageSearch="messageSearch"
          :ref="'message-' + item.id"
          @changeCheckbox="$emit('changeCheckbox', $event)"
          @metaInfoPopup="openMetaInfoPopup"
          @downloadFile="$emit('downloadFile', $event)"
        />
      </ul>
    </div>
    <MetaInfoPopup ref="metaInfoPopup" :metaInfo="metaInfo"/>
  </div>
</template>

<script>
import MetaInfoPopup from '@/components/popups/MetaInfoPopup';
import Message from '@/components/сases/case-messages/Message';

export default {
  name: 'SetMessages',
  emits: ['downloadFile', 'changeCheckbox'],
  components: {
    Message,
    MetaInfoPopup,
  },
  data() {
    return {
      metaInfo: {},
    };
  },
  props: {
    messages: {
      type: Array
    },
    messageSearch: {
      type: String,
    }
  },
  methods: {
    openMetaInfoPopup(metaInfo) {
      this.metaInfo = metaInfo;
      this.$refs.metaInfoPopup.openPopup();
    },
    getMessageRef(id) {
      const messageComponent = this.$refs['message-' + id] ? this.$refs['message-' + id][0] : null;
      return messageComponent ? messageComponent.$el : null;
    },
  },
};
</script>

<style scoped>
.holder-messages-list {
  display: flex;
  position: relative;
  flex-direction: column;
}

</style>
