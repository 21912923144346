<template>
  <vue-editor v-model="content" />
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "RichTextEditor",
  components: {
    VueEditor,
  },
  props: {
    defaultText: {
      type: String,
    }
  },
  data() {
    return {
      content: "",
    }
  },
  watch: {
    content(val) {
      this.$emit("input", val);
    },
    defaultText(val) {
      this.content = val;
      // this.$emit("input", val);
    }
  },
}
</script>
<style scoped>
</style>