<template>
  <div class="wrapper">
    <Sidebar/>
    <div class="content-wrapper">
      <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
      />
      <HeaderRegistered :returnPath="returnPath" :subTitle="caseTitle"/>
      <main class="main">
        <section class="case-export-section">
          <div class="top-bar" :class="{ active: isAdvancedFilterApplied || isAdvancedFilter}">
            <div v-if="!isAdvancedFilter && !isAdvancedFilterApplied" class="tab-wrapper">
              <div
                class="tab-btn"
                :class="{ active: currentTab === CASE_EXPORT_TABS.messages }"
                @click="currentTab = CASE_EXPORT_TABS.messages"
              >
                <span class="tab-btn-text">Mobile chats</span>
              </div>
              <div
                class="tab-btn"
                :class="{ active: currentTab === CASE_EXPORT_TABS.email }"
                @click="currentTab = CASE_EXPORT_TABS.email"
              >
                <span class="tab-btn-text">Email</span>
              </div>
              <div
                class="tab-btn"
                :class="{ active: currentTab === CASE_EXPORT_TABS.media }"
                @click="currentTab = CASE_EXPORT_TABS.media"
              >
                <span class="tab-btn-text">Media</span>
              </div>
              <div
                class="tab-btn"
                :class="{ active: currentTab === CASE_EXPORT_TABS.callLogs }"
                @click="currentTab = CASE_EXPORT_TABS.callLogs"
              >
                <span class="tab-btn-text">Call Logs</span>
              </div>
              <div
                class="tab-btn"
                :class="{ active: currentTab === CASE_EXPORT_TABS.contacts }"
                @click="currentTab = CASE_EXPORT_TABS.contacts"
              >
                <span class="tab-btn-text">Contacts</span>
              </div>
              <div
                class="tab-btn"
                :class="{ active: currentTab === CASE_EXPORT_TABS.apps }"
                @click="currentTab = CASE_EXPORT_TABS.apps"
              >
                <span class="tab-btn-text">Apps</span>
              </div>
            </div>
            <div class="settings-wrapper">
              <template v-if="isAdvancedFilterApplied || isAdvancedFilter">
                <div v-if="isAdvancedFilter" class="filter-types">
                  <button
                    :class="{selected: !filterTypeSaved}"
                    class="filter-type"
                    @click="filterTypeSaved = false"
                  >
                    Default
                  </button>
                  <button
                    :class="{selected: filterTypeSaved}"
                    class="filter-type"
                    @click="filterTypeSaved = true"
                  >
                    Saved
                  </button>
                </div>
              </template>
              <div class="advanced-change-button-wrapper">
                <button v-if="stringifiedRules" class="advanced-change-button add-to-favorites"
                        @click="openSaveFilterPatternPopup">
                  <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.9221 0.09375V22.3134C15.9219 22.5357 15.7975 22.7394 15.5996 22.8409C15.4017 22.9424 15.1636 22.9248 14.9829 22.795L7.99416 17.7856L1.00567 22.795C0.824907 22.9248 0.586905 22.9424 0.388995 22.8409C0.191085 22.7394 0.066638 22.5357 0.0664062 22.3134V0.09375H15.9221Z"
                      fill="#082655"/>
                  </svg>
                  <span>Add To Favorites</span>
                </button>
                <button
                  v-if="isAdvancedFilterApplied || isAdvancedFilter"
                  :disabled="!isAdvancedFilterApplied"
                  class="reset-filters"
                  @click="resetFilters"
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z"
                      fill="#FF9537"/>
                  </svg>
                  Reset Filters
                </button>
                <button @click="changeFilterView" class="advanced-change-button">
                  <svg :class="{'advanced-mode': isAdvancedFilter}" width="24" height="25" viewBox="0 0 24 25"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.7236 19.6382L10.7236 21.1382C10.3912 21.3044 10 21.0627 10 20.691V13.7071C10 13.5745 9.94732 13.4473 9.85355 13.3536L4.14645 7.64645C4.05268 7.55268 4 7.4255 4 7.29289V5C4 4.72386 4.22386 4.5 4.5 4.5H19.5C19.7761 4.5 20 4.72386 20 5V7.29289C20 7.4255 19.9473 7.55268 19.8536 7.64645L14.1464 13.3536C14.0527 13.4473 14 13.5745 14 13.7071V19.191C14 19.3804 13.893 19.5535 13.7236 19.6382Z"
                      stroke="#082655"/>
                  </svg>

                  <span>Advanced</span>

                  <svg :class="{'advanced-mode-arrow': isAdvancedFilter}" width="16" height="17" viewBox="0 0 16 17"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                      fill="#8692A6"/>
                  </svg>
                </button>
                <div class="case-action-btn-wrap" v-if="!isAdvancedFilter">
                  <button
                    :disabled="!checkPermission(['export_case']) || timeRemaining > 0"
                    class="btn"
                    @click="openExportCasePopup"
                    style="height: 40px"
                  >Export Data
                  </button>
                  <div class="timer-wrapper">
                    <div class="timer" v-if="timeRemaining > 0">
                      <span>Time remaining for another case export request: </span>{{ timeRemaining }} sec.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isLoaded" v-show="isAdvancedFilter" class="advanced-filter-wrap">
              <SavedFilters
                v-if="filterTypeSaved"
                @attachFilter="attachFilter"
                @backToDefault="filterTypeSaved = false"
              />
              <div v-else class="control-section">
                <div class="col-lg-12 querybuilder-control">
                  <ejs-querybuilder
                    ref="querybuilder"
                    :dataSource="dataSource"
                    :maxGroupCount="100"
                    :rule="importRules"
                    @change="ruleChanged"
                  >
                    <e-columns>
                      <e-column
                        v-for="(col, idx) in columns"
                        :key="idx"
                        :field="col.field"
                        :label="col.label"
                        :type="col.type"
                        :template='col.template'
                        :operators="col.operators"
                        format="dd/MM/yyyy"
                      />
                    </e-columns>
                  </ejs-querybuilder>
                  <div class="advanced-btn-wrapper">
                    <button class="btn btn-apply" type="button" @click="addRule">+ Add Rule</button>
                    <button class="btn btn-apply" type="button" @click="addGroup">+ Add Group</button>
                    <button
                      :disabled="emptyRule"
                      class="btn btn-apply"
                      type="button"
                      @click="filter(false)"
                    >Apply
                    </button>
                    <span>{{ resultCount }} matches</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isAdvancedFilterApplied && !isAdvancedFilter" class="advanced-filter-wrap">
              <div class="filter-tags-close">
                <button class="advanced-change-button" @click="isOpenedFilterList = !isOpenedFilterList">
                  <span>{{ isOpenedFilterList ? 'Close' : 'Show' }} Applied Filters</span>
                  <svg
                    :class="{'advanced-mode-arrow': isOpenedFilterList}"
                    class="filter-tags-arrow"
                    fill="none"
                    height="17"
                    viewBox="0 0 16 17"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                      fill="#8692A6"
                    />
                  </svg>
                </button>
              </div>

              <ul v-show="isOpenedFilterList" class="control-tags">
                <li
                  v-for="tag of filteredTags"
                  :key="tag.id"
                  :class="{ 'removed': tag.removed }"
                  class="filter-tag"
                >
<!--                  <button type="button" @click="removeFilter(tag)">-->
<!--                    <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path-->
<!--                        d="M14 2.3335C7.54833 2.3335 2.33333 7.5485 2.33333 14.0002C2.33333 20.4518 7.54833 25.6668 14 25.6668C20.4517 25.6668 25.6667 20.4518 25.6667 14.0002C25.6667 7.5485 20.4517 2.3335 14 2.3335ZM19.8333 18.1885L18.1883 19.8335L14 15.6452L9.81166 19.8335L8.16666 18.1885L12.355 14.0002L8.16666 9.81183L9.81166 8.16683L14 12.3552L18.1883 8.16683L19.8333 9.81183L15.645 14.0002L19.8333 18.1885Z"-->
<!--                        data-v-1f88d880=""-->
<!--                        fill="#D7DCE5"-->
<!--                      />-->
<!--                    </svg>-->
<!--                  </button>-->

                  <strong>{{ tag.label | filteringLabel }}</strong>:&nbsp;
                  <span>{{ tag.initialValue || tag.value }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="holder-table">
            <h1>Export History</h1>
            <table cellpadding="0" cellspacing="0" border="0">
              <TableHeader>
                <tr>
                  <th>
                    <div class="table-header-box">
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z"
                          fill="#D7DCE6"/>
                      </svg>
                      <span class="header-table-text">Date</span>
                    </div>
                  </th>
                  <th class="w-300">
                    <div class="table-header-box">
                      <UserIcon/>
                      <span class="header-table-text">User Name</span>
                    </div>
                  </th>

                  <th>
                    <div class="table-header-box">
                      <CaseIcon/>
                      <span class="header-table-text">Case name</span>
                    </div>
                  </th>

                  <th>
                    <div class="table-header-box">
                      <CaseIcon/>
                      <span class="header-table-text">format</span>
                    </div>
                  </th>

                  <th>
                    <div :id="`sort_export_in_case_${caseId}_by_status`" class="table-header-box">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 16C6.55208 16 5.21354 15.6432 3.98438 14.9297C2.75521 14.2161 1.78385 13.2448 1.07031 12.0156C0.356771 10.7865 0 9.44792 0 8C0 6.55208 0.356771 5.21354 1.07031 3.98438C1.78385 2.75521 2.75521 1.78385 3.98438 1.07031C5.21354 0.356771 6.55208 0 8 0C9.44792 0 10.7865 0.356771 12.0156 1.07031C13.2448 1.78385 14.2161 2.75521 14.9297 3.98438C15.6432 5.21354 16 6.55208 16 8C16 9.44792 15.6432 10.7865 14.9297 12.0156C14.2161 13.2448 13.2448 14.2161 12.0156 14.9297C10.7865 15.6432 9.44792 16 8 16ZM1 8C1 8.94792 1.17969 9.84896 1.53906 10.7031C1.89844 11.5573 2.40104 12.3073 3.04688 12.9531L8 8V1C7.05208 1 6.14583 1.1849 5.28125 1.55469C4.41667 1.92448 3.67188 2.42188 3.04688 3.04688C2.42188 3.67188 1.92448 4.41667 1.55469 5.28125C1.1849 6.14583 1 7.05208 1 8Z"
                          fill="#D7DCE6"/>
                      </svg>
                      <span class="header-table-text">status</span>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </TableHeader>
              <CaseExportItem
                :caseExportList="caseExportList"
                @downloadItem="downloadItem"
              />
              <div class="loader-bg" v-if="loadFlag">
                <div class="loader-p"></div>
              </div>
            </table>
            <pagination
              v-if="caseExportList && caseExportList.length && count !== 1"
              v-model="page"
              :total-pages="count"
              :link-gen="linkGen"
            ></pagination>
          </div>
          <SaveFilterPattern
            ref="saveFilterPattern"
            @saveFilterPattern="saveFilterPattern"
          />
        </section>
      </main>
    </div>
    <ExportPopup
      ref="exportPopup"
      @export="actionExportCase"
      :isFiltersApplied="isAdvancedFilterApplied"
      :isMessagesExport="currentTab === CASE_EXPORT_TABS.messages"
    />
    <CommonDialog
      ref="exportCaseDialog"
      :show-footer="false"
      :closable="false"
    >
      <h2 class="dialog-message-title">Exporting case</h2>
      <p class="dialog-create-description" style="display:block; font-size: 14px;">Please wait a bit. The case is being
        exported.</p>
      <div class="loader-p"></div>
    </CommonDialog>
  </div>
</template>

<script>
import checkPermission from "../../../utils/permission";
import CaseIcon from "../../icons/CaseIcon"
import UserIcon from "../../icons/UserIcon";
import Sidebar from "../../Sidebar";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import HeaderRegistered from "../../layout/HeaderRegistered";
import TableHeader from "../../table/TableHeader";
import CaseExportItem from "./CaseExportItem";
import Pagination from "@/components/common/Pagination.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";
import SavedFilters from "@/components/сases/case-messages/SavedFilters.vue";
import SaveFilterPattern from "@/components/popups/SaveFilterPattern.vue";
import {createElement, getComponent} from "@syncfusion/ej2-base";
import {AutoComplete, CheckBoxSelection, DropDownList, MultiSelect} from "@syncfusion/ej2-dropdowns";
import {Query} from "@syncfusion/ej2-data";
import Vue from "vue";
import {QueryBuilderPlugin} from "@syncfusion/ej2-vue-querybuilder";
import {DateRangePicker} from "@syncfusion/ej2-calendars";
import moment from "moment/moment";
import ExportPopup from "@/components/popups/ExportPopup.vue";
import {
  QueryBuilderComponent,
  ColumnDirective,
  ColumnsDirective
} from "@syncfusion/ej2-vue-querybuilder";
import debounce from "@/mixins/debounce";
import {CASE_EXPORT_TABS} from "@/constants/const";

MultiSelect.Inject(CheckBoxSelection);

Vue.use(QueryBuilderPlugin);

const types = ['value', 'deleteRule', 'deleteGroup', 'condition'];

export default {
  name: "CaseExport",
  components: {
    ExportPopup,
    SaveFilterPattern,
    SavedFilters,
    CommonDialog,
    Pagination,
    CaseIcon,
    UserIcon,
    CaseExportItem,
    TableHeader,
    HeaderRegistered,
    AlertErrorMsg,
    Sidebar,
    'ejs-querybuilder': QueryBuilderComponent,
    'e-column': ColumnDirective,
    'e-columns': ColumnsDirective,
  },
  data() {
    let isOperators = ['is', 'not_is'];
    let containOperators = ['contain', 'not_contain'];

    return {
      CASE_EXPORT_TABS,
      currentTab: CASE_EXPORT_TABS.messages,

      // ADVANCED FILTER
      isAdvancedFilterApplied: false,
      isLoaded: true,
      filterTypeSaved: false,
      isAdvancedFilter: false,
      clientId: '',
      isOpenedFilterList: true,
      filteredTags: [],
      dataSource: [],
      initialData: [],
      emptyRule: true,
      stringifiedRules: '',
      resultCount: 0,
      columns: [],

      isRequired: {isRequired: true},
      defaultImportRules: {},
      importRules: {},
      dateTemplate: {
        create: () => {
          return createElement('input', {attrs: {'type': 'text'}});
        },
        destroy: (args) => {
          let daterangepicker = getComponent(document.getElementById(args.elementId), 'daterangepicker');
          if (daterangepicker)
            daterangepicker.destroy();
        },
        write: (args) => {
          let daterangepicker = new DateRangePicker({
            placeholder: 'Select date range',
            disabled: true,
            change: (e) => {
              this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
            },
          });

          if (args.values) {
            daterangepicker.startDate = new Date(this.formatDate(args.values[0]));
            daterangepicker.endDate = new Date(this.formatDate(args.values[1]));
          }

          daterangepicker.appendTo('#' + args.elements.id);
        }
      },
      defaultTemplate: {
        create: () => {
          return createElement('input', {attrs: {'type': 'text'}});
        },
        destroy: (args) => {
          let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect');
          if (multiselect)
            multiselect.destroy();
          let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
          if (autoComplete)
            autoComplete.destroy();
        },
        write: (args) => {
          let ds = []

          this.initialData.forEach(item => {
            if (item.fieldName === args.field && item.values) {
              item.values.forEach(el => {
                let elName = this.capitalizeFirstLetter(el);
                ds.push({id: el, value: elName})
              })
            }
          });

          if (containOperators.indexOf(args.operator) > -1) {
            let autoCompleteObj = new AutoComplete({
              dataSource: ds,
              fields: {text: 'value', value: 'id'},
              placeholder: `Find a ${this.stringModify(args.field)}`,
              highlight: true,
              itemTemplate: "<span class='filtering-name'>${id}</span>",
              change: (e) => {
                let name = '';

                if (e.itemData) {
                  name = e.itemData.id;
                }

                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
              }
            });
            autoCompleteObj.appendTo('#' + args.elements.id);
          } else if (isOperators.indexOf(args.operator) > -1) {
            let dropDownObj = new DropDownList({
              dataSource: ds,
              fields: {text: 'value', value: 'id'},
              value: args.values,
              change: (e) => {
                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
              }
            });
            dropDownObj.appendTo('#' + args.elements.id);
          } else {
            let multiSelectObj = new MultiSelect({
              dataSource: ds,
              value: args.values,
              fields: {text: 'value', value: 'id'},
              mode: 'CheckBox',
              placeholder: `Select ${this.stringModify(args.field)}`,
              change: (e) => {
                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
              },
              filtering: function (e) {
                let query = new Query();
                //frame the query based on search string with filter type.
                query = (e.text != "") ? query.where("value", "startswith", e.text, true) : query;
                //pass the filter data source, filter query to updateData method.
                e.updateData(ds, query);
              }
            });
            multiSelectObj.appendTo('#' + args.elements.id);
            if (args.values) {
              multiSelectObj.change(multiSelectObj);
            }
          }
        }
      },

      returnPath: true,
      caseId: this.$route.params.slug,
      caseExportList: [],
      loadFlag: false,
      errorMessage: '',
      caseTitle: '',
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      dialog: {
        status: "",
        text: "Starting..."
      },
      progressPercentage: 0,
      queryFilterParams: null,
      timeRemaining: 0,
      intervalId: null,
      disabledFiltersId: [],
      defaultQueryFilterParams: [],
      msgFilters: null,
    }
  },
  computed: {
    subTitle() {
      return ` Case ${this.caseId}`
    },
  },
  filters: {
    filteringLabel(label) {
      let labelsList = label.split(" ")
      labelsList = labelsList.map(phrase => {
        if (phrase === 'a' || phrase === "for" || phrase === 'Search' || phrase === 'the') {
          return
        }
        return phrase;
      });

      return labelsList.join(" ")
    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.getCaseExport()
        } else {
          this.page = 1
        }
      }
    },
    currentTab() {
      this.getCaseExportSettings();
      this.getCaseExport();
    }
  },
  mixins: [capitalizeFirstLetter, debounce],
  methods: {

    stringModify(str) {
      return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? ' ' : ''}${letter.toLowerCase()}`
          : letter;
      }).join('');
    },

    // ADVANCED FILTERING

    openSaveFilterPatternPopup() {
      this.$refs.saveFilterPattern.openPopups();
    },
    async saveFilterPattern(filterName) {
      await this.$load(async () => {
        try {
          await this.$api.cases.saveFilterPattern(this.caseId, {
            name: filterName,
            value: this.stringifiedRules,
          });
          this.stringifiedRules = '';
        } catch (error) {
          this.$emit('show-error', error.response.data.errors.message.toString());
        }
      })
    },
    filter(getResultCount) {
      if (!getResultCount) {
        this.stringifiedRules = JSON.stringify({
          clientId: this.clientId,
          rules: this.$refs.querybuilder.ej2Instances.rule.properties
        });

        localStorage.setItem('rules', this.stringifiedRules);
        this.queryFilterParams = this.getQueryParams(true);
      }

      this.filtersAmountApplied = this.queryFilterParams.reduce((a, b) => a + b.rules.length, 0)
      this.queryFilterParams = this.queryFilterParams.filter(param => param.rules.length);

      this.queryFilterParams.forEach(item => {
        if (item.rules.length) {
          item.rules.forEach((el, i) => {
            if (!el.value) {
              item.rules.splice(i, 1);
              return;
            }
            if (el.field === 'date') {

              if (el.value.length) {
                let dateObj = {
                  field: "date",
                  label: "Date",
                  operator: "gth",
                  type: "date",
                  value: ""
                }
                let dateFromObj = Object.assign({}, dateObj)
                let dateToObj = Object.assign({}, dateObj)
                let dateFrom = el.value[0].split('/')
                let dateTo = el.value[1].split('/')
                let dateFromTime = `${dateFrom[2]}-${dateFrom[1]}-${dateFrom[0]}`
                let dateToTime = `${dateTo[2]}-${dateTo[1]}-${dateTo[0]}`
                dateFrom = moment(dateFromTime).valueOf()
                dateTo = moment(dateToTime).endOf('day').valueOf()
                dateFromObj.value = dateFrom
                dateFromObj.operator = 'gth'
                dateToObj.value = dateTo
                dateToObj.operator = 'lth'
                dateFromObj.initialValue = dateFromTime
                dateToObj.initialValue = dateToTime
                item.rules.push(dateFromObj)
                item.rules.push(dateToObj)
                item.rules.splice(i, 1);
              }
            }
          })
        }
      })

      if (getResultCount) {
        this.getCountForExport();
      } else {
        this.filteredTags = [];
        this.isAdvancedFilterApplied = true;
        this.isAdvancedFilter = false;
        this.defaultQueryFilterParams = this.queryFilterParams;
        this.filterTags(this.queryFilterParams);
      }
    },
    removeFilter(disabledTag) {
      let areThereRules = false;

      if (disabledTag.removed) {
        const disabledFilterId = this.disabledFiltersId.findIndex(el => el === disabledTag.id);

        this.disabledFiltersId.splice(disabledFilterId, 1);
      } else {
        this.disabledFiltersId.push(disabledTag.id);
      }

      disabledTag.removed = !disabledTag.removed;

      this.queryFilterParams = this.defaultQueryFilterParams.map(parameter => {
        const rules = parameter.rules.filter(rule => {
          for (let i = 0; i < this.disabledFiltersId.length; i++) {
            if (rule.id === this.disabledFiltersId[i]) {
              return false;
            }
          }

          return true;
        });

        if (rules.length) {
          areThereRules = true;
        }

        return {
          ...parameter,
          rules: rules,
        };
      }).filter(queryParam => !!queryParam.rules.length);

      if (areThereRules) {
        this.debounce(() => this.filter(false), 1000);
      } else {
        this.resetFilters();
      }
    },

    // QUERY BUILDER

    changedQueryBuilder(e) {
      if (e.type === 'insertRule') {
        return;
      }

      this.queryFilterParams = this.getQueryParams();

      if (this.isValidQueryFilterParams) {
        this.resultCount = 0
        return;
      }

      if (e.type === 'deleteGroup') {
        const groupID = e.groupID.split('group').join('');

        this.queryFilterParams.splice(groupID, 1);
      }

      if (types.some(type => type === e.type)) {
        this.filter(true);
      }
    },
    checkIsNestedRulesNotEmpty(rules) {
      return rules.every(rule => {
        if (rule.value === undefined) return true;

        return rule.rules
          ? this.checkIsNestedRulesNotEmpty(rule.rules)
          : rule.value && rule.value.length;
      });
    },

    // RULES & GROUPS
    addRule(_, rule) {
      let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
      this.$refs.querybuilder.ej2Instances.addRules([rule || {field: 'custodian'}], `group${count}`);
    },
    addGroup(_, group) {
      let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
      this.$refs.querybuilder.ej2Instances.addGroups([group || this.defaultImportRules], `group${count}`);
    },
    setSavedRules(rules) {
      rules.forEach(rule => {
        if (rule.rules && rule.rules.length) {
          this.addGroup(false, {'condition': rule.condition || 'and', 'rules': [{}]});
          this.setSavedRules(rule.rules);
        } else if (rule.value) {
          this.addRule(false, rule);
        }
      });
    },
    setParsedRules(parsedRules) {
      this.importRules = {};
      this.isAdvancedFilterApplied = true;

      setTimeout(() => {
        const {rules} = parsedRules.rules;
        const lastRuleRules = rules[rules.length - 1].rules;

        this.setSavedRules(!lastRuleRules || lastRuleRules && lastRuleRules.length ? rules : rules.reverse());
        this.queryFilterParams = this.getQueryParams(true);

        this.filter(true);
        this.filter();
      }, 1000);
    },
    ruleChanged(event) {
      const rules = [];

      this.$refs.querybuilder.ej2Instances.rule.properties.rules.forEach(rule => {
        if (rule.rules) {
          if (!rule.rules.length) {
            return;
          }

          rules.push(this.checkIsNestedRulesNotEmpty(rule.rules));
          return;
        }

        rules.push(rule.value && !!rule.value.length);
      });

      this.emptyRule = rules.some(rule => rule !== undefined && !rule);

      if (!this.emptyRule || (!this.emptyRule && event.type === 'condition')) {
        this.debounce(this.changedQueryBuilder, 1000, event)
      }
    },

    async attachFilter(filterId) {
      await this.$load(async () => {
        const filter = await this.$api.cases.getFilterPattern(this.caseId, filterId);
        this.filterTypeSaved = false;
        this.setParsedRules(JSON.parse(filter.data.body.value));
      });
    },
    changeFilterView() {
      this.isAdvancedFilter = !this.isAdvancedFilter;
    },
    reset() {
      this.$refs.querybuilder.ej2Instances.reset()
      this.$refs.querybuilder.ej2Instances.levelColl = []
    },
    resetFilters() {
      localStorage.removeItem('rules');
      this.stringifiedRules = '';
      this.isAdvancedFilter = true;
      this.isAdvancedFilterApplied = false;
      this.filtersAmountApplied = 0;
      this.MessagesChatPage = 0
      this.queryFilterParams = null;
      this.filteredTags = [];
      this.isOpenedFilterList = true;
      this.resultCount = 0;
      this.reset();
      this.$refs.querybuilder.ej2Instances.setRules(this.defaultImportRules)
      this.filtersAppliedCount = 0
    },

    checkPermission,
    linkGen(pageNum) {
      return {
        path: `/cases/${this.caseId}/export`,
        query: {page: pageNum}
      }
    },
    downloadItem(item) {
      window.$axiosFiles.get(`/cases/${this.caseId || this.$route.params.case_id}/export/${item.exportId}/download`, {
        responseType: "blob",
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.key);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(error => {
          this.errorMessage = error.response.data.message;
          this.$refs.alert.showAlert();
        });
    },
    openExportCasePopup() {
      this.$refs.exportPopup.openPopups();
    },
    actionExportCase(format, config) {
      if (this.currentTab === CASE_EXPORT_TABS.messages) {
        if (format === 'rsmf' || format === 'csv') {
          this.exportV1(format);
        } else {
          this.exportWithFilters(format, config);
        }
      } else {
        this.exportFilteredCaseItems(format);
      }
    },
    exportFilteredCaseItems(format) {
      this.$load(async () => {
        this.$refs.exportCaseDialog.openPopups();

        const response = await this.$api.caseExport.exportCaseItems(
          this.caseId,
          this.currentTab,
          format,
          this.getMappedRules(),
        );
        // eslint-disable-next-line no-undef
        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

        await this.readStreamData(reader);

        if (!this.dialog.status.includes("error")) {
          this.dialog.text = "Starting...";
          this.dialog.status = "";
          this.progressPercentage = 0;
        }

        this.startTimer();
        await this.getCaseExport();

        setTimeout(() => {
          this.loadFlag = false;
        }, 1000);
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert();
      });
    },
    exportWithFilters(format, config) {
      this.$load(async () => {
        this.$refs.exportCaseDialog.openPopups();

        const response = await this.$api.caseExport.exportCaseWithFilters(
          this.caseId,
          format,
          {
            exportRequest: this.getMappedRules(),
            config: config,
          });
        // eslint-disable-next-line no-undef
        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

        await this.readStreamData(reader);

        if (!this.dialog.status.includes("error")) {
          this.dialog.text = "Starting...";
          this.dialog.status = "";
          this.progressPercentage = 0;
        }

        this.startTimer();
        await this.getCaseExport();

        setTimeout(() => {
          this.loadFlag = false;
        }, 1000);
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert();
      });
    },
    exportV1(format) {
      this.$load(async () => {
        this.$refs.exportCaseDialog.openPopups();

        const response = await this.$api.cases.exportCases(this.caseId, format);
        // eslint-disable-next-line no-undef
        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

        await this.readStreamData(reader);

        if (!this.dialog.status.includes("error")) {
          this.dialog.text = "Starting...";
          this.dialog.status = "";
          this.progressPercentage = 0;
        }

        this.startTimer();
        await this.getCaseExport();

        setTimeout(() => {
          this.loadFlag = false;
        }, 1000);
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert();
      });
    },
    async readStreamData(reader) {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const {value, done} = await reader.read();
        if (done) {
          this.$refs.exportCaseDialog.closePopups();
          break;
        }
        const splitChunks = value.toString().split("\n");
        for (let i = 0; i < splitChunks.length; i++) {
          if (splitChunks[i].trim()) {
            if (splitChunks[i].includes("event:")) {
              const dataStatus = splitChunks[i].split("event:").find(chunk => chunk.trim() !== '');
              if (dataStatus && typeof dataStatus !== "undefined") {
                this.dialog.status = dataStatus;
              }
            }
            if (splitChunks[i].includes("data:")) {
              const dataText = splitChunks[i].split("data:").find(chunk => chunk.trim() !== '');
              if (dataText && typeof dataText !== "undefined") {
                this.dialog.text = dataText;
              }
            }
          }
        }
        this.progressPercentage += 25;
      }
    },
    getCaseExport() {
      this.$load(async () => {
        await this.$api.cases.getExportCases({
          caseId: this.caseId,
          page: this.page,
          size: this.size,
          type: this.currentTab === CASE_EXPORT_TABS.messages ? 'chats' : this.currentTab,
        }).then((res) => {
          this.caseExportList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    startTimer() {
      const duration = 60;
      const endTime = Date.now() + duration * 1000;

      localStorage.setItem('endTime', endTime);

      this.timeRemaining = duration;
      this.startCountdown();
    },
    startCountdown() {
      clearInterval(this.intervalId);

      this.intervalId = setInterval(() => {
        const endTime = localStorage.getItem('endTime');
        if (endTime) {
          const remaining = Math.ceil((endTime - Date.now()) / 1000);

          if (remaining > 0) {
            this.timeRemaining = remaining;
          } else {
            this.timeRemaining = 0;
            clearInterval(this.intervalId);
          }
        } else {
          this.timeRemaining = 0;
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    checkTimer() {
      const endTime = localStorage.getItem('endTime');
      if (endTime) {
        const remaining = Math.ceil((endTime - Date.now()) / 1000);

        if (remaining > 0) {
          this.timeRemaining = remaining;
          this.startCountdown();
        } else {
          this.timeRemaining = 0;
          localStorage.removeItem('endTime');
        }
      }
    },
    getCaseExportSettings() {
      this.isLoaded = false;
      this.$load(async () => {
        const res = await this.$api.caseExport.getSettingExportCase(
          this.caseId,
          this.currentTab,
          {emails: [], chats: [], phones: []}
        );
        this.initialData = res.data.body;
        this.columns = this.buildColumnsFromResponse(res.data.body);
        this.importRules = this.buildDefaultRules(res.data.body);
        this.defaultImportRules = this.buildDefaultRules(res.data.body);
        this.isLoaded = true;
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert()
      });
    },
    buildColumnsFromResponse(responseBody) {
      return responseBody.map(item => {
        const isDateField = item.fieldName === "date";
        return {
          field: item.fieldName,
          label: item.viewName,
          type: isDateField ? "date" : "string",
          template: isDateField ? this.dateTemplate : this.defaultTemplate,
          operators: item.operators.map(op => this.mapOperator(op))
        };
      });
    },
    buildDefaultRules(responseBody) {
      if (!responseBody || !responseBody.length) {
        return {condition: 'and', rules: []};
      }

      const rules = [{},];
      responseBody.slice(0, 2).forEach(item => {
        rules.push({
          field: item.fieldName,
          operator: item.operators[0] || "",
          key: item.operators[0] || "",
          type: item.fieldName === "date" ? "date" : "string",
          label: item.viewName
        });
      });
      return {condition: "and", rules};
    },
    mapOperator(op) {
      switch (op) {
        case "is":
          return {value: "is", key: "is"};
        case "is_not":
          return {value: "is_not", key: "is not"};
        case "contain":
          return {value: "contain", key: "contain"};
        case "not_contain":
          return {value: "not_contain", key: "not contain"};
        case "in":
          return {value: "in", key: "in"};
        case "not_in":
          return {value: "not_in", key: "not in"};
        case "gth":
          return {value: "gth", key: ">"};
        case "lth":
          return {value: "lth", key: "<"};
        default:
          return {value: op, key: op};
      }
    },
    getCountForExport() {
      this.$load(async () => {
        const res = await this.$api.caseExport.getCountFindingForExport(
          this.caseId,
          this.currentTab,
          this.getMappedRules(),
        );
        this.resultCount = res.data.body.count;
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert()
      });
    },
    getQueryParams(setId = false) {
      this.msgFilters = this.$refs.querybuilder.ej2Instances.getValidRules(this.$refs.querybuilder.ej2Instances.rule);
      return this.flatten(this.msgFilters, setId);
    },
    flattenRules(rootRule) {
      if (!this.isCondition(rootRule) || !this.hasRuleset(rootRule)) return;

      const flat = (currentCondition) => {
        if (!this.hasRuleset(currentCondition)) return;

        // Filtering current ruleset to find inner conditions with non-empty rulesets;
        const nestedConditions = currentCondition.rules.filter(
          (item) => this.isCondition(item) && this.hasRuleset(item)
        );

        return [
          {
            ...currentCondition,
            // We exclude inner conditions from the ruleset and leave only the descriptions;
            rules: currentCondition.rules.filter((item) => !this.isCondition(item)),
          },
          ...nestedConditions.reduce(
            // We recursively call this function with all of the nested conditions;
            (acc, condition) => [...acc, ...flat(condition)],
            []
          ),
        ];
      };

      return flat(rootRule);
    },
    flatten(conditionsResult, setId) {
      let conditionsResultArray = this.flattenRules(conditionsResult)

      conditionsResultArray.forEach((item) => {
        if (item.condition === null) {
          item.condition = 'and'
        }
        if (item.rules.length) {
          item.rules.forEach((rule) => {
            if (setId) {
              rule.id = Math.random().toString(36).substr(2, 9);
            }
            if (rule.field !== 'date') {
              if (Array.isArray(rule.value)) {
                rule.value = rule.value.join(';')
              }
            }
          })
        }
      })

      return conditionsResultArray
    },
    isCondition(item) {
      return item.condition !== undefined
    },
    hasRuleset(condition) {
      return !!condition.rules && !!condition.rules.length
    },
    formatDate(date) {
      const initial = date.split(/\//);

      return [initial[1], initial[0], initial[2]].join('/')
    },
    filterTags(rules) {
      rules.forEach(rule => {
        rule.rules ? this.filterTags(rule.rules) : this.filteredTags.push(rule);
      })
    },
    getMappedRules() {
      if (!this.queryFilterParams) {
        return [];
      }
      const mappedRules = this.queryFilterParams.flatMap(queryParam => {
        return queryParam.rules.map(rule => {
          return {
            field: rule.field,
            operator: rule.operator,
            value: rule.value,
          }
        })
      });
      const isRulesPresent = mappedRules.filter(rule => rule.value !== null).length > 0;
      return isRulesPresent ? mappedRules : [];
    }
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.caseTitle = localStorage.getItem('title')
    this.$store.dispatch('profile/getPermissionPerCase', {caseId: this.caseId})

    this.getCaseExport()
    this.getCaseExportSettings();
    this.checkTimer();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
}
</script>

<style scoped lang="scss">
.case-export-section {
  padding: 150px 55px 24px 35px;
  min-height: 100vh;
  position: relative;

  .active {
    box-shadow: 0 4px 10px rgba(77, 106, 147, 0.15);
  }

  .top-bar {
    position: absolute;
    top: 10px;
    right: 55px;
    left: 23px;
    padding: 28px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    z-index: 2;
    background-color: #fff;

    .tab-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 700px;
      min-height: 35px;
      margin-right: 20px;
      border-radius: 79px;
      border: 1px solid var(--case-btn-border-color);

      .tab-btn {
        margin: -1px 0 -1px 0;
        width: 50%;
        max-height: 35px;
        padding: 10px 30px;
        border: 1px solid transparent;
        outline: none;
        background-color: transparent;
        border-radius: 79px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row;
        transition: 0.3s all;
      }

      .tab-btn.active {
        border-color: var(--accent-color);
      }

      .tab-btn-text {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: var(--light-gray);
        transition: 0.3s all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tab-btn.active .tab-btn-text,
      .tab-btn:hover .tab-btn-text {
        color: var(--accent-color);
      }
    }

    .settings-wrapper {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 100%;

      .case-action-btn-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .format-choose-wrapper {
          display: flex;
          flex-direction: column;
          width: 195px;
          margin-right: 16px;
        }

        .format-choose-text {
          font-size: 14px;
          font-weight: 700;
          color: var(--secondary-black-color);
          margin-bottom: 9px;
        }

        .btn {
          align-self: flex-end;
        }

        .timer-wrapper {
          padding: 20px 0 0 20px;

          .timer span {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: var(--secondary-text-color);
          }
        }
      }

      .filter-types {
        display: flex;
        border-radius: 79px;
        outline: 1px solid var(--case-btn-border-color);
        width: fit-content;
        min-height: 35px;

        .filter-type {
          height: 35px;
          position: relative;
          z-index: 2;
          transition: outline 0.3s, color 0.3s;
          border: none;
          border-radius: 79px;
          outline: 1px solid transparent;
          width: 144px;
          padding: 5px 16px;
          background-color: transparent;
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          color: var(--light-gray);
        }

        .filter-type.selected {
          outline: 1px solid var(--accent-color);
          color: var(--accent-color);
        }

        .filter-type:hover {
          color: var(--accent-color);
        }
      }

      .advanced-change-button-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        .add-to-favorites {
          margin-right: 23px;
        }

        .advanced-change-button {
          height: 30px;
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          padding: 0;
          background-color: unset;

          span {
            margin: 0 6px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #373C44;
          }

          &:hover span {
            text-decoration: underline;
          }

          .advanced-mode {
            fill: var(--accent-color);

            &-arrow {
              transform: rotate(180deg);
            }
          }
        }

        .reset-filters {
          background-color: transparent;
          display: flex;
          align-items: center;
          border: none;
          margin-right: 23px;
          padding: 0;
          font-weight: 500;
          font-size: 14px;
          color: var(--secondary-black-color);
        }

        .reset-filters:disabled path {
          fill: var(--case-btn-border-color);
        }

        .reset-filters:disabled {
          cursor: not-allowed;
        }

        .reset-filters svg {
          margin-right: 10px;
        }

        .reset-filters:not(:disabled):hover {
          text-decoration: underline;
        }
      }
    }

    .advanced-filter-wrap {
      width: 100%;
      margin-top: 20px;
      position: relative;

      .advanced-change-button {
        height: 30px;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        padding: 0;
        background-color: unset;

        span {
          margin: 0 6px;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #373C44;
        }

        &:hover span {
          text-decoration: underline;
        }

        .advanced-mode {
          fill: var(--accent-color);

          &-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .holder-table {

    h1 {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-transform: uppercase;
      color: var(--light-gray);
      margin: 0 0 15px;
    }
  }
}

.removed path {
  fill: #ff9537;
}

.control-tags {
  margin-bottom: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-tag {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: var(--accent-color);
  font-size: 13px;
  color: var(--white-color);

  button {
    display: flex;
    margin-right: 8px;
    border: navajowhite;
    padding: 0;
    background-color: unset;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  path {
    transition: fill 0.3s;
  }

  strong {
    text-transform: capitalize;
  }

  &s-close {
    display: flex;
    align-items: center;
  }

  &s-arrow {
    transition: all 0.3s;
  }
}


.filtering-name b {
  color: red;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0 0;
  display: block;
  width: 100%;
}

.dialog-create-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: var(--light-gray);
  margin: 10px 0 0;
}

.progress-bar {
  width: 345px;
  background-color: #EBEFF6;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 30px 30px;
}

.progress {
  height: 5px;
  background-color: var(--accent-color);
  width: 0;
  transition: width 0.3s;
}

.loader-p {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 20px 40px 20px 0;
}
</style>
