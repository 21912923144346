export default function(instance) {
  return {
    getKeys(params) {
      return instance.get(`/keys`, {params})
    },
    sendKey(data) {
      return instance.post(`/keys/send`, data)
    },
    regenerateKey(data) {
      return instance.post(`/keys/regenerate`, data)
    },
    deactivateKey(data) {
      return instance.post(`/keys/deactivate`, data)
    },
  }
}
