<template>
  <header>
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="header-registered" :class="customClass">
      <div class="header-info">
        <div class="header-title">
          <router-link
              v-if="!withoutCaseTitle"
              :to="routePath"
              id="goto_back_without_title"
              tag="h3">
            <span class="header-title-text">{{ prefixText }}&nbsp;{{ caseTitle }}</span>
          </router-link>
          <router-link v-else
                       id="goto_back_with_title"
                       :to="routePath"
                       tag="h3">
            {{ prefixText }}
          </router-link>
        </div>
        <div class="header-btns">
          <CaseDropdown :tooltip-placement="'bottom'" :caseName="caseTitle">
            More...
          </CaseDropdown>
          <template v-if="checkPermission(['view_case'])">
            <div class="view-case-button-wrapper">
              <router-link
                  tag="button"
                  :to="{name: 'Messages'}"
                  :id="`goto_case_${$route.params.slug}_message`"
                  class="btn header-btn"
              >
                View case messages
              </router-link>
            </div>
            <router-link
                tag="button"
                :id="`goto_case_${$route.params.slug}_files`"
                class="btn btn-revert"
                :to="{name: 'Files', params: ''}"
            >
              View Files
            </router-link>
          </template>
          <template v-else>
            <div class="view-case-button-wrapper" id="disabled-view-case-button" ref="disabledViewCaseButton">
              <router-link
                  disabled
                  tag="button"
                  :to="{name: 'Messages'}"
                  :id="`goto_case_${$route.params.slug}_message`"
                  class="btn header-btn"
              >
                View case messages
              </router-link>
            </div>
            <tooltip :target-ref="$refs.disabledViewCaseButton" side="bottom">
              <span>To get access to view case messages, you need to get permission 'View case'</span>
            </tooltip>
            <div id="disabled-view-files-button" ref="disabledViewFilesButton">
              <router-link
                  disabled
                  tag="button"
                  :id="`goto_case_${$route.params.slug}_files`"
                  class="btn btn-revert"
                  :to="{name: 'Files', params: ''}"
              >
              View Files
            </router-link>
            </div>
            <tooltip :target-ref="$refs.disabledViewFilesButton" side="bottom">
              <span>To get access to view files, you need to get permission 'View case'</span>
            </tooltip>
          </template>
        </div>
      </div>
      <nav class="header-nav" v-if="loggingIn">
        <ul class="navigation">
          <li
              id="navigation_header_btn"
              class="nav-item">
            <HeaderNotificationsTemplate
                :notifications="notifications"
                @changedStatus="changeStatus"
            />
          </li>
          <li
              v-if="photoProfile"
              class="nav-item"
              @click.stop="$refs.userDropdown.toggleActive()"
          >
            <div class="holder-img">
              <img
                  class="user-image"
                  :src="photoProfile"
                  alt=""
                  onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
              >
            </div>
          </li>
          <li
              class="nav-item"
              @click.stop="$refs.userDropdown.toggleActive()"
              v-else
          >
            <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17.5" cy="18" r="17.5" fill="#F8FCFC"/>
              <path
                d="M17.5 6.5C13.9236 6.5 11 9.42356 11 13C11 16.5764 13.9236 19.5 17.5 19.5C21.0764 19.5 24 16.5764 24 13C24 9.42356 21.0764 6.5 17.5 6.5Z"
                fill="#8692A6"/>
              <path
                d="M29.9466 24.8139C29.7566 24.3355 29.5033 23.889 29.2183 23.4744C27.7616 21.3057 25.5134 19.8705 22.9802 19.5197C22.6635 19.4878 22.3152 19.5516 22.0618 19.7429C20.7319 20.7316 19.1486 21.2419 17.502 21.2419C15.8553 21.2419 14.272 20.7316 12.9421 19.7429C12.6887 19.5516 12.3404 19.4559 12.0238 19.5197C9.4905 19.8705 7.21059 21.3057 5.78565 23.4744C5.50066 23.889 5.24731 24.3674 5.05735 24.8139C4.96238 25.0053 4.99402 25.2285 5.08899 25.4199C5.34234 25.8664 5.65897 26.3129 5.94396 26.6956C6.38726 27.3016 6.86226 27.8437 7.4006 28.354C7.84391 28.8005 8.35055 29.2151 8.85725 29.6297C11.3588 31.5114 14.3671 32.5 17.4703 32.5C20.5736 32.5 23.5818 31.5113 26.0834 29.6297C26.59 29.247 27.0967 28.8005 27.54 28.354C28.0467 27.8437 28.5533 27.3015 28.9967 26.6956C29.3133 26.281 29.5984 25.8664 29.8517 25.4199C30.0099 25.2285 30.0415 25.0052 29.9466 24.8139Z"
                fill="#8692A6"/>
            </svg>
          </li>
        </ul>
        <HeaderDropdown ref="userDropdown"/>
      </nav>
    </div>
  </header>
</template>

<script>
import checkPermission from '@/utils/permission';
import HeaderNotificationsTemplate from "./HeaderNotificationsTemplate";
import LocalStorageService from '../../services/LocalStorageService'
import CaseDropdown from "../сases/case-buttons/CaseDropdown";
import HeaderDropdown from "./HeaderDropdown";
import Tooltip from "@/components/common/Tooltip.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import { KEY_STATUS } from "@/constants/const";

const localStorageService = LocalStorageService.getService();

export default {
  name: "CaseDetailsHeader",
  components: {
    AlertErrorMsg,
    Tooltip,
    CaseDropdown,
    HeaderDropdown,
    HeaderNotificationsTemplate
  },
  props: {
    prefixText: {
      type: String,
      required: false,
      default: ''
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    routePath: {
      type: Object,
      required: false
    },
    withoutCaseTitle: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      loggingIn: true,
      caseTitle: '',
      notifications: [],
      errorMessage: '',
    }
  },
  methods: {
    checkPermission,
    changeStatus() {
      this.$load(async () => {
        this.notifications = (await this.$api.notifications.getNotViewed()).data.body.content
      })
    },
    getKeys() {
      this.$load(async () => {
          const res = await this.$api.management.getKeys(
            {
              page: this.page,
              size: 100,
              caseId: this.$route.params.slug,
            })

        const expiredKeys = res.data.body.content.filter(key => key.status.toLowerCase() === KEY_STATUS.expired)
        if (expiredKeys.length > 0) {
          this.errorMessage = 'You have expired keys. Generate new keys.';
          this.$refs.alert.showAlert();
        }

        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert();
        }
      );
    },
  },
  mounted() {
    if(localStorageService.getAccessTokenLogin() !== null) {
      this.$store.commit('authentication/loginSuccess', localStorageService.getAccessTokenLogin)
    }

    this.$load(async () => {
      this.notifications = (await this.$api.notifications.getNotViewed()).data.body.content
    })

    this.$store.dispatch('profile/getUser')
    this.$store.dispatch('profile/getPermissionPerCase', {caseId: this.$route.params.slug})
    this.caseTitle = localStorage.getItem('title')

    this.getKeys();
  },
  computed: {
    photoProfile() {
      return this.$store.state.profile.user.photoProfile
    },
  },

}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

ul {
  padding: 0;
  list-style: none;
}

header {
  padding: 40px 55px 0 35px;
}

.header-title {
  width: 100%;
}

.header-title-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

.header-info {
  width: 100%;
  display: flex;
  align-items: center;
}

.header-nav {
  position: relative;
}

.header-btns {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.view-case-button-wrapper {
  margin: 0 20px 0 39px;
  width: 100%;
  max-width: 320px;
}

.header-btn {
  width: 100%;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
  max-width: 300px;
}

.header-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.header-registered {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--input-placeholder-value-color);
}

header h3 {
  display: inline-block;
  white-space: nowrap;
  padding: 0 0 0 23px;
  color: var(--secondary-black-color);
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  margin-right: 30px;
  position: relative;
  cursor: pointer;
}

header h3:before {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 2px;
  left: 0;
  top: 16px;
  transform: rotate(45deg);
  background-color: var(--secondary-text-color);
}

header h3:after {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 2px;
  left: 0;
  top: 10px;
  transform: rotate(-45deg);
  background-color: var(--secondary-text-color);
}


.navigation {
  display: flex;
  align-items: center;
  margin: 0;
}

.nav-item {
  cursor: pointer;
  padding: 0 15px;
  position: relative;
}

.notification-circle {
  position: absolute;
  top: -6px;
  right: 16px;
}

.notification-circle svg circle {
  fill: var(--accent-color);
  transition: all .2s;
}

.notification-circle.notification-empty svg circle {
  fill: #DDE3E3;
  transition: all .2s;
}

.nav-item:last-child {
  padding-right: 0;
}

.nav-item:last-child .holder-img {
  width: 35px;
  height: 35px;
}

.nav-item:last-child img {
  width: 35px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  height: 35px;
}

/*custom classes*/

.header-registered.assign-manager-header .header-btns {
  display: none;
}

.header-registered.assign-manager-header .header-title {
  max-width: unset;
}

.header-registered.case-actions .header-btns {
  display: none;
}

.holder-img {
  width: 30px;
  height: 30px;
}
</style>
