import router from "@/router";

let gotoProfile = {
  methods: {
    gotoUserProfile(id) {
      try {
        let route = router.resolve({ name: 'User Profile', params: { slug: id } });
        if (route && route.href) {
          window.open(route.href, '_blank', 'noopener,noreferrer');
        } else {
          console.error('Failed to resolve route for User Profile');
        }
      } catch (error) {
        console.error('An error occurred while navigating to the user profile:', error);
      }
    },
    gotoClientProfile(id) {
      try {
        let route = router.resolve({ name: 'Custodian Profile', params: { slug: id } });
        if (route && route.href) {
          window.open(route.href, '_blank', 'noopener,noreferrer');
        } else {
          console.error('Failed to resolve route for Custodian Profile');
        }
      } catch (error) {
        console.error('An error occurred while navigating to the custodian profile:', error);
      }
    },
  }
}

export default gotoProfile;