<template>
  <div v-if="activeDialog" class="overlay active">
    <div class="invite-dialog-wrapper">
      <div class="invite-popup-header">
        <div id="close_dialog_invite" class="close-dialog-invite" @click="$router.push({ path: '/invite-new-contact/approved' })">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
              fill="#D7DCE6"
            />
          </svg>
        </div>
        <div class="invite-title">Invite Team</div>
      </div>
      <div class="invitations-content-wrapper">
        <div class="item-wrapper highlighter">
          <div class="invite-subtitle mrg-bt-8">Send to:</div>
          <div class="textarea-wrapper" :class="{ 'form-group--error': $v.sendToEmails.$error }">
            <highlightable-input
              :highlight-enabled="true"
              :highlight="emailPattern"
              v-model="sendToEmails"
              class="invite-textarea"
              :class="{ 'placeholder-none': sendToEmails.length > 0 }"
            />
            <div class="error" v-if="!$v.sendToEmails.containsEmail">The field must contain at least one valid email address</div>
          </div>
        </div>
        <div class="item-wrapper" :class="{ 'form-group--error': $v.chosenRole.$error }">
          <div class="invite-subtitle">Choose Role</div>
          <CustomSelect
            :options="roles"
            v-model="chosenRole"
            @input="$v.chosenRole.$reset"
            placeholder="Role"
          />
          <div class="error" v-if="!$v.chosenRole.isRoleChosen">Choose a role</div>
        </div>
        <div class="item-wrapper">
          <div class="invite-subtitle">Message:</div>
          <div class="check">
            <CustomCheckbox
              :id="'personalized_message'"
              :input-element="personalizedMessage"
              @checked="onCheck"
            />
            <span class="checkbox-text">Apply a personalized invitation letter</span>
          </div>
          <textarea
            :class="{ disabled: personalizedMessage.isActive }"
            name="invite"
            class="invite-textarea"
            placeholder="Type an individual message to your team member"
            v-model="inviteMessage"
          ></textarea>
        </div>
      </div>
      <div class="dialog-btns-wrap">
        <button class="btn dialog-btn" id="dialog_logout_btn" @click="onConfirm">
          <span class="dialog-btn-text">Continue</span>
        </button>
        <button id="dialog_logout_cancel_btn" @click="$router.push({ path: '/invite-new-contact/approved' })" class="btn dialog-cancel">
          <span class="dialog-btn-text cancel">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import HighlightableInput from "vue-highlightable-input";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import {ROLES} from "@/constants/const";

const containsEmail = (value) => {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  return emailRegex.test(value);
};

const isRoleChosen = (value) => {
  return ROLES.some((role) => role.name === value);
}

export default {
  name: "InvitePopup",
  components: {
    CustomCheckbox,
    CustomSelect,
    HighlightableInput,
  },
  emits: ["modelValue", "inviteMessage", "confirmed", "role"],
  data() {
    return {
      personalizedMessage: {
        isActive: false,
        message: "",
      },
      activeDialog: false,
      sendToEmails: "",
      inviteMessage: "",
      chosenRole: "",
      roles: ROLES,
      emailPattern: [
        {
          text: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g,
          style:"background-color:#EBEFF6;" +
            " border-radius: 16px;" +
            "color: #696F79;" +
            "display: inline-block;" +
            "padding: 4px 8px;",
        },
      ]
    };
  },
  validations() {
    return {
      sendToEmails: { containsEmail },
      chosenRole: { isRoleChosen },
    }
  },
  methods: {
    getEmails() {
      return this.sendToEmails;
    },
    openPopups() {
      this.activeDialog = true;
    },
    closePopups() {
      this.activeDialog = false;
    },
    onConfirm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      } else {
        this.$emit('modelValue', this.sendToEmails);
        this.$emit('inviteMessage', this.inviteMessage);
        this.$emit('role', this.chosenRole);
        this.$emit("confirmed");
      }
    },
    onCheck(data) {
      if (data.isActive) {
        this.personalizedMessage.isActive = true;
        this.getPersonalizedMessage();
      } else {
        this.personalizedMessage.isActive = false;
      }
    },
    getPersonalizedMessage() {
      this.$load(async () => {
        const res = await this.$api.profile.getFirm();
        this.inviteMessage = this.stripHtml(res.data.body.inviteText);
      })
    },
    stripHtml(htmlString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      return doc.body.textContent || "";
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay.active {
  animation: show 0.3s linear;
}

.invite-dialog-wrapper {
  width: 100%;
  max-width: 711px;
  min-height: 639px;
  border: 1px solid #D7DCE6;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.invite-popup-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.invitations-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.highlighter {
  position: relative;
  display: inline-block;
}

.close-dialog-invite {
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.close-dialog-invite svg {
  width: 100%;
  height: 100%;
}

.close-dialog-invite svg path {
  transition: 0.5s all;
}

.close-dialog-invite:hover svg path {
  fill: var(--accent-color);
}

.invite-title {
  font-size: 26px;
  font-weight: 700;
  color: var(--black-color);
}

.invite-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary-black-color);
}

.invite-dialog-wrapper .dialog-btn {
  min-width: 140px;
  width: 100%;
  max-width: 213px;
  height: 48px;
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.invite-dialog-wrapper .dialog-btn:hover {
  background-color: var(--white-color);
  border-color: var(--accent-color);
}

.invite-dialog-wrapper .dialog-btn:hover .dialog-btn-text {
  color: var(--accent-color);
}

.dialog-btns-wrap {
  margin-top: 19px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
}

.dialog-cancel {
  min-width: 140px;
  width: 100%;
  max-width: 213px;
  height: 48px;
  padding: 15px;
  cursor: pointer;
  transition: 0.5s all;
  background-color: var(--white-color);
  border-color: var(--accent-color);
}

.dialog-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: var(--white-color);
  transition: 0.5s all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.dialog-btn-text.cancel {
  font-size: 14px;
  font-weight: 500;
  color: var(--accent-color);
}

.invite-textarea {
  width: 100%;
  resize: none;
  min-height: 104px;
  outline: none;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 16px !important;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  position: relative;
  z-index: 2;
  color: var(--secondary-text-color);
}

.invite-textarea::before {
  content: "Enter contact e-mail divided by space or “,”";
  color: var(--light-gray);
  pointer-events: none;
}

.placeholder-none::before {
  content: "";
}

.check {
  display: flex;
  gap: 5px;

  .checkbox-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 7px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--secondary-text-color);
    padding: 8px 0 0;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
