<template>
  <li
    class="messages-list-item"
    :class="{ active: (isEditMode && editingMessage) && (item.id === editingMessage.id) }"
    :style="{
      justifySelf: item.isClient ? 'flex-end' : 'flex-start',
      justifyContent: item.isClient ? 'space-between' : 'flex-start',
    }"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="check">
      <CustomCheckbox
        :id="'message' + item.id"
        :inputElement="item"
        @checked="changeCheckbox"
      />
    </div>
    <div class="holder-contacts" :style="{ justifyContent: item.isClient ? 'flex-end' : 'flex-start', }">
      <div v-if="!item.isClient" class="contacts-imgs">
        <div class="img-item">
          <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" fill="white" r="11.5" stroke="#FF9537"/>
            <path
              d="M12.0001 4.11523C9.5477 4.11523 7.54297 6.11996 7.54297 8.57238C7.54297 11.0248 9.5477 13.0295 12.0001 13.0295C14.4525 13.0295 16.4573 11.0248 16.4573 8.57238C16.4573 6.11996 14.4525 4.11523 12.0001 4.11523Z"
              fill="#FF9537"
            />
            <path
              d="M20.5348 16.6731C20.4045 16.3451 20.2308 16.0389 20.0354 15.7546C19.0366 14.2675 17.4949 13.2834 15.7578 13.0428C15.5407 13.0209 15.3019 13.0646 15.1281 13.1959C14.2162 13.8738 13.1305 14.2237 12.0014 14.2237C10.8722 14.2237 9.78656 13.8738 8.87458 13.1959C8.70086 13.0646 8.462 12.9991 8.24489 13.0428C6.50779 13.2834 4.94442 14.2675 3.96732 15.7546C3.7719 16.0389 3.59817 16.3669 3.46792 16.6731C3.40279 16.8043 3.42449 16.9574 3.48961 17.0886C3.66334 17.3948 3.88045 17.701 4.07588 17.9634C4.37985 18.3789 4.70557 18.7507 5.07472 19.1006C5.3787 19.4068 5.72611 19.6911 6.07356 19.9754C7.78892 21.2657 9.85173 21.9436 11.9797 21.9436C14.1076 21.9436 16.1704 21.2656 17.8858 19.9754C18.2332 19.713 18.5806 19.4068 18.8846 19.1006C19.232 18.7507 19.5794 18.3789 19.8835 17.9634C20.1006 17.6791 20.296 17.3948 20.4697 17.0886C20.5782 16.9574 20.5999 16.8043 20.5348 16.6731Z"
              fill="#FF9537"
            />
          </svg>
        </div>
      </div>
      <div class="message-item-wrapper">
        <div class="message-wrapper">
          <div
            class="message-item"
            :style="{ backgroundColor: item.isClient ? '#F3F8FF' : '#F6F6F6' }"
            @click="startEditMessage"
          >
            <div class="title-contact">
              <div class="span-name" :style="{ flexDirection: item.isClient ? 'row-reverse' : 'row'} ">
                {{ item.senderName }}
                <button @click.stop="getMetaInfo(item.id)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.5 7.5L7.52733 7.48667C7.61282 7.44396 7.70875 7.42664 7.80378 7.43677C7.8988 7.4469 7.98893 7.48404 8.0635 7.54381C8.13806 7.60357 8.19394 7.68345 8.22451 7.77399C8.25508 7.86453 8.25907 7.96193 8.236 8.05467L7.764 9.94533C7.74076 10.0381 7.74463 10.1356 7.77513 10.2263C7.80563 10.3169 7.86149 10.3969 7.93609 10.4568C8.01069 10.5166 8.10089 10.5538 8.196 10.564C8.2911 10.5741 8.38712 10.5568 8.47267 10.514L8.5 10.5M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8ZM8 5.5H8.00533V5.50533H8V5.5Z"
                      stroke="#8692A6" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <div class="span-chat">
                  <div class="chat-icon">
                    <ImgOfMessengers :nameOfMessanger="item.messenger.toLowerCase()"/>
                  </div>
                </div>
              </div>
              <div
                class="btn-change-message-version"
                :class="{'hidden': editMode}"
                @click="changeMessageVersion"
              >
                <button v-if="item.editedMessage && item.editedMessage !== item.message && !showOriginalMessage">
                  <span>Edited</span>
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.8276 7.04374C12.9993 7.12788 13.0516 7.30523 12.9442 7.43986C12.6223 7.84374 12.2397 8.21114 11.8048 8.53115L12.6856 9.22166C12.8287 9.33391 12.8287 9.51593 12.6856 9.62822C12.5423 9.74044 12.3102 9.74044 12.167 9.62822L11.2408 8.90205C10.657 9.24395 9.99986 9.508 9.28475 9.6741L9.6142 10.6381C9.66656 10.7914 9.55048 10.949 9.3549 10.9901C9.15929 11.0312 8.95824 10.9402 8.90583 10.7869L8.57006 9.80432C8.22412 9.8506 7.86693 9.87469 7.50001 9.87469C7.13308 9.87469 6.77589 9.8506 6.42994 9.80432L6.09419 10.7868C6.04179 10.9402 5.84074 11.0312 5.64514 10.9901C5.44953 10.9491 5.33344 10.7914 5.38585 10.6381L5.71525 9.6741C5.00015 9.508 4.34302 9.24394 3.75919 8.90204L2.83299 9.62822C2.6898 9.74044 2.45764 9.74044 2.31445 9.62822C2.17126 9.51593 2.17126 9.33391 2.31445 9.22165L3.1952 8.53114C2.76033 8.21113 2.37775 7.84374 2.05578 7.43986C1.94846 7.30523 2.00066 7.12788 2.17238 7.04374C2.3441 6.95959 2.57032 7.00052 2.67764 7.13515C3.02926 7.57623 3.4586 7.96629 3.95238 8.28947C3.96031 8.29422 3.96805 8.29926 3.97558 8.30458C4.93991 8.92913 6.14727 9.29976 7.50001 9.29976C9.55906 9.29976 11.2814 8.44103 12.3224 7.13515C12.4297 7.00052 12.6559 6.95959 12.8276 7.04374Z"
                          fill="#8692A6"/>
                  </svg>
                </button>
                <button v-if="item.editedMessage && item.editedMessage !== item.message && showOriginalMessage">
                  <span>Original</span>
                  <svg width="15" height="16" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12 4C12 4 9.75 0 6 0C2.25 0 0 4 0 4C0 4 2.25 8 6 8C9.75 8 12 4 12 4ZM0.87975 4C1.24239 4.53445 1.65948 5.03223 2.12475 5.48582C3.09 6.42327 4.41 7.27273 6 7.27273C7.59 7.27273 8.90925 6.42327 9.876 5.48582C10.3413 5.03223 10.7584 4.53445 11.121 4C10.7584 3.46554 10.3413 2.96777 9.876 2.51418C8.90925 1.57673 7.58925 0.727273 6 0.727273C4.41 0.727273 3.09075 1.57673 2.124 2.51418C1.65873 2.96777 1.24164 3.46554 0.879 4H0.87975Z"
                          fill="#696F79"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M6 2.18235C5.50272 2.18235 5.02581 2.37391 4.67417 2.71488C4.32254 3.05586 4.125 3.51832 4.125 4.00053C4.125 4.48274 4.32254 4.94521 4.67417 5.28618C5.02581 5.62716 5.50272 5.81871 6 5.81871C6.49728 5.81871 6.97419 5.62716 7.32583 5.28618C7.67746 4.94521 7.875 4.48274 7.875 4.00053C7.875 3.51832 7.67746 3.05586 7.32583 2.71488C6.97419 2.37391 6.49728 2.18235 6 2.18235ZM3.375 4.00053C3.375 3.32544 3.65156 2.67799 4.14384 2.20062C4.63613 1.72326 5.30381 1.45508 6 1.45508C6.69619 1.45508 7.36387 1.72326 7.85616 2.20062C8.34844 2.67799 8.625 3.32544 8.625 4.00053C8.625 4.67563 8.34844 5.32308 7.85616 5.80044C7.36387 6.27781 6.69619 6.54599 6 6.54599C5.30381 6.54599 4.63613 6.27781 4.14384 5.80044C3.65156 5.32308 3.375 4.67563 3.375 4.00053Z"
                          fill="#696F79"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="desc-contact">
              <div class="relative">
                <span v-if="item.isBlured" class="blurred"></span>
                <span :style="{background: highlightColor}" class="background"></span>
                <p
                  v-if="!editMode"
                  :class="{ underline: !!highlightColor, 'nocopy': item.isBlured }"
                  :data-color="highlightColor"
                  :style="{
                      color: highlightColor ? '#000' : '#696F79FF',
                      textDecorationColor: highlightColor,
                      textAlign: item.isClient ? 'right' : 'left',
                     }"
                  v-html="messageToShow() || ' '"
                ></p>
                <textarea
                  v-else
                  ref="editMessageTextarea"
                  type="text"
                  v-model="newMessage"
                  @input="adjustTextareaHeight"
                  @keydown.enter.exact.prevent="confirmMessageChange"
                  @keydown.shift.enter.exact="addNewLine"
                  @keyup.esc="cancelMessageChange"
                  class="edit-message-textarea"
                ></textarea>
              </div>
            </div>
            <div class="span-date-wrap">
              <span class="span-date">{{ parseDate(item.date) }}</span>
              <span class="span-date">{{ parseDateTime(item.date) }}</span>
            </div>
            <div class="tag-wrap" v-if="item.tags && item.tags.length">
              <span
                class="tag"
                v-for="(tag, index) in item.tags"
                :key="index"
                v-text="tag"
              />
            </div>
          </div>
          <div v-if="item.attachments.length" class="attachment-wrapper">
            <Attachments
              v-for="(attachment, index) in item.attachments"
              :key="attachment.id + index"
              :attachment="attachment"
              @downloadFile="$emit('downloadFile', $event)"
              :with-recognition-text="true"
            />
          </div>
        </div>
        <div v-if="item.isClient" class="contacts-imgs" style="margin-left: 10px;">
          <div class="img-item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7.5" fill="white" stroke="#082655"/>
              <path
                d="M8.00073 2.74316C6.36578 2.74316 5.0293 4.07965 5.0293 5.71459C5.0293 7.34954 6.36578 8.68602 8.00073 8.68602C9.63567 8.68602 10.9722 7.34954 10.9722 5.71459C10.9722 4.07965 9.63567 2.74316 8.00073 2.74316Z"
                fill="#082655"/>
              <path
                d="M13.6893 11.1157C13.6024 10.897 13.4866 10.6929 13.3564 10.5034C12.6905 9.51197 11.6627 8.8559 10.5047 8.69552C10.3599 8.68096 10.2007 8.71009 10.0849 8.79757C9.47687 9.24954 8.7531 9.48281 8.00034 9.48281C7.24758 9.48281 6.52381 9.24954 5.91582 8.79757C5.8 8.71009 5.64077 8.66636 5.49602 8.69552C4.33796 8.8559 3.29571 9.51197 2.64431 10.5034C2.51403 10.6929 2.39821 10.9116 2.31138 11.1157C2.26796 11.2032 2.28242 11.3053 2.32584 11.3928C2.44166 11.5969 2.5864 11.801 2.71668 11.9759C2.91933 12.253 3.13648 12.5008 3.38258 12.7341C3.58523 12.9382 3.81684 13.1277 4.04847 13.3172C5.19204 14.1774 6.56725 14.6294 7.98588 14.6294C9.4045 14.6294 10.7797 14.1774 11.9233 13.3172C12.1549 13.1423 12.3865 12.9382 12.5892 12.7341C12.8208 12.5008 13.0524 12.2529 13.2551 11.9759C13.3998 11.7864 13.5301 11.5969 13.6459 11.3928C13.7183 11.3053 13.7327 11.2032 13.6893 11.1157Z"
                fill="#082655"/>
            </svg>
          </div>
        </div>
        <div class="message-item-info">

          <div class="message-item-info--right">

            <div class="settings-wrap">
              <button class="button-comment" @click="startEditMessage" v-if="!editMode && !isEditMode" v-show="false">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_11891_7270)">
                    <path
                      d="M4 20.0003H8L18.5 9.5003C19.0304 8.96987 19.3284 8.25045 19.3284 7.5003C19.3284 6.75016 19.0304 6.03074 18.5 5.5003C17.9696 4.96987 17.2501 4.67188 16.5 4.67188C15.7499 4.67187 15.0304 4.96987 14.5 5.5003L4 16.0003V20.0003Z"
                      stroke="#696F79" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.5 6.5L17.5 10.5" stroke="#696F79" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_11891_7270">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button class="button-comment" @click="confirmMessageChange" v-else-if="!isEditMode" v-show="false">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_11916_3225)">
                    <path d="M5 12L10 17L20 7" stroke="#082655" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_11916_3225">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                v-if="!isEditMode && checkPermission(['add_comments_highlights'])"
                v-click-outside="closeTools"
                class="button-comment"
                @click="openTools"
                :style="{ opacity: item.commentsCount > 0 || isHovered ? 1 : 0 }">
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 11.6521C9 11.7443 8.96488 11.8328 8.90237 11.898C8.83986 11.9633 8.75507 11.9999 8.66667 11.9999C8.57826 11.9999 8.49348 11.9633 8.43096 11.898C8.36845 11.8328 8.33333 11.7443 8.33333 11.6521C8.33333 11.5598 8.36845 11.4714 8.43096 11.4061C8.49348 11.3409 8.57826 11.3043 8.66667 11.3043C8.75507 11.3043 8.83986 11.3409 8.90237 11.4061C8.96488 11.4714 9 11.5598 9 11.6521ZM9 11.6521H8.66667M12.3333 11.6521C12.3333 11.7443 12.2982 11.8328 12.2357 11.898C12.1732 11.9633 12.0884 11.9999 12 11.9999C11.9116 11.9999 11.8268 11.9633 11.7643 11.898C11.7018 11.8328 11.6667 11.7443 11.6667 11.6521C11.6667 11.5598 11.7018 11.4714 11.7643 11.4061C11.8268 11.3409 11.9116 11.3043 12 11.3043C12.0884 11.3043 12.1732 11.3409 12.2357 11.4061C12.2982 11.4714 12.3333 11.5598 12.3333 11.6521ZM12.3333 11.6521H12M15.6667 11.6521C15.6667 11.7443 15.6315 11.8328 15.569 11.898C15.5065 11.9633 15.4217 11.9999 15.3333 11.9999C15.2449 11.9999 15.1601 11.9633 15.0976 11.898C15.0351 11.8328 15 11.7443 15 11.6521C15 11.5598 15.0351 11.4714 15.0976 11.4061C15.1601 11.3409 15.2449 11.3043 15.3333 11.3043C15.4217 11.3043 15.5065 11.3409 15.569 11.4061C15.6315 11.4714 15.6667 11.5598 15.6667 11.6521ZM15.6667 11.6521H15.3333M20 11.6521C20 15.8779 16.4178 19.3042 12 19.3042C11.2331 19.3052 10.4694 19.2001 9.72889 18.9916C8.68512 19.7576 7.41184 20.1057 6.14222 19.972C6.00106 19.9578 5.8605 19.9377 5.72089 19.9117C6.15899 19.373 6.4582 18.7265 6.59022 18.0335C6.67022 17.6096 6.472 17.1978 6.17511 16.8963C4.82667 15.5273 4 13.6825 4 11.6521C4 7.42628 7.58222 4 12 4C16.4178 4 20 7.42628 20 11.6521Z"
                    stroke="#696F79"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span v-if="item.commentsCount > 0" class="comments-counter">{{ item.commentsCount }}</span>
              </button>
              <div class="highlight-wrapper">
                <div
                  v-show="showTools"
                  class="tools"
                >
                  <span
                    class="item"
                    @click.stop="openComments"
                  >
                    <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 11.6521C9 11.7443 8.96488 11.8328 8.90237 11.898C8.83986 11.9633 8.75507 11.9999 8.66667 11.9999C8.57826 11.9999 8.49348 11.9633 8.43096 11.898C8.36845 11.8328 8.33333 11.7443 8.33333 11.6521C8.33333 11.5598 8.36845 11.4714 8.43096 11.4061C8.49348 11.3409 8.57826 11.3043 8.66667 11.3043C8.75507 11.3043 8.83986 11.3409 8.90237 11.4061C8.96488 11.4714 9 11.5598 9 11.6521ZM9 11.6521H8.66667M12.3333 11.6521C12.3333 11.7443 12.2982 11.8328 12.2357 11.898C12.1732 11.9633 12.0884 11.9999 12 11.9999C11.9116 11.9999 11.8268 11.9633 11.7643 11.898C11.7018 11.8328 11.6667 11.7443 11.6667 11.6521C11.6667 11.5598 11.7018 11.4714 11.7643 11.4061C11.8268 11.3409 11.9116 11.3043 12 11.3043C12.0884 11.3043 12.1732 11.3409 12.2357 11.4061C12.2982 11.4714 12.3333 11.5598 12.3333 11.6521ZM12.3333 11.6521H12M15.6667 11.6521C15.6667 11.7443 15.6315 11.8328 15.569 11.898C15.5065 11.9633 15.4217 11.9999 15.3333 11.9999C15.2449 11.9999 15.1601 11.9633 15.0976 11.898C15.0351 11.8328 15 11.7443 15 11.6521C15 11.5598 15.0351 11.4714 15.0976 11.4061C15.1601 11.3409 15.2449 11.3043 15.3333 11.3043C15.4217 11.3043 15.5065 11.3409 15.569 11.4061C15.6315 11.4714 15.6667 11.5598 15.6667 11.6521ZM15.6667 11.6521H15.3333M20 11.6521C20 15.8779 16.4178 19.3042 12 19.3042C11.2331 19.3052 10.4694 19.2001 9.72889 18.9916C8.68512 19.7576 7.41184 20.1057 6.14222 19.972C6.00106 19.9578 5.8605 19.9377 5.72089 19.9117C6.15899 19.373 6.4582 18.7265 6.59022 18.0335C6.67022 17.6096 6.472 17.1978 6.17511 16.8963C4.82667 15.5273 4 13.6825 4 11.6521C4 7.42628 7.58222 4 12 4C16.4178 4 20 7.42628 20 11.6521Z"
                        stroke="#696F79"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Comment
                  </span>
                  <span
                    class="item"
                    @click.stop="openHighlight"
                  >
                    <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_9401_79502)">
                        <path
                          d="M11.5 20.125C16.2635 20.125 20.125 16.2635 20.125 11.5C20.125 6.73654 16.2635 2.875 11.5 2.875C6.73654 2.875 2.875 6.73654 2.875 11.5C2.875 16.2635 6.73654 20.125 11.5 20.125Z"
                          stroke="#696F79"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.34375 12.9375V8.625L13.6562 6.46875V12.9375"
                          stroke="#696F79"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.0938 19.3434V13.6563C15.0938 13.4656 15.018 13.2828 14.8832 13.148C14.7484 13.0132 14.5656 12.9375 14.375 12.9375H8.625C8.43438 12.9375 8.25156 13.0132 8.11677 13.148C7.98198 13.2828 7.90625 13.4656 7.90625 13.6563V19.3434"
                          stroke="#696F79"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_9401_79502">
                          <rect fill="white" height="23" width="23"/>
                        </clipPath>
                      </defs>
                    </svg>
                    Highlight
                  </span>
                </div>
                <div
                  v-show="highlightColorsEnabled"
                  class="tools colors"
                >
                  <button
                    v-for="(color, index) in colors"
                    :key="index"
                    v-click-outside="closeHighlight"
                    :class="{white: color.preview === '#fff'}"
                    :style="{backgroundColor: color.preview}"
                    class="color"
                    @click="setHighlightBackground(color.origin, item.id)"
                  />
                  <button class="close" @click="deleteHighlightBackground(item.id)">
                    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                        fill="#8692A6"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  v-if="showComments"
                  v-click-outside="closeComments"
                  class="tools comments"
                  style="height: 300px"
                >
                  <div v-if="loadFlag" class="loader-wrapper">
                    <loader :load-flag="loadFlag"/>
                  </div>
                  <div
                    v-else
                    ref="commentScroll"
                    class="scroll-area"
                  >
                    <div
                      v-for="(comment, index) in comments"
                      :key="index"
                      class="comment"
                    >
                      <span class="comment-date">{{ commentDate(comment) }}</span>
                      <div class="comment-avatar">
                        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" fill="#F8FCFC" r="12"/>
                          <path
                            d="M12.0001 4.11328C9.5477 4.11328 7.54297 6.11801 7.54297 8.57042C7.54297 11.0228 9.5477 13.0276 12.0001 13.0276C14.4525 13.0276 16.4573 11.0228 16.4573 8.57042C16.4573 6.11801 14.4525 4.11328 12.0001 4.11328Z"
                            fill="#E6E7E9"
                          />
                          <path
                            d="M20.534 16.6731C20.4037 16.3451 20.23 16.0389 20.0345 15.7546C19.0357 14.2675 17.4941 13.2834 15.757 13.0428C15.5399 13.0209 15.301 13.0646 15.1273 13.1959C14.2153 13.8738 13.1296 14.2237 12.0005 14.2237C10.8714 14.2237 9.78571 13.8738 8.87373 13.1959C8.7 13.0646 8.46115 12.9991 8.24403 13.0428C6.50694 13.2834 4.94357 14.2675 3.96646 15.7546C3.77104 16.0389 3.59732 16.3669 3.46706 16.6731C3.40194 16.8043 3.42363 16.9574 3.48876 17.0886C3.66248 17.3948 3.8796 17.701 4.07502 17.9634C4.379 18.3789 4.70472 18.7507 5.07386 19.1006C5.37784 19.4068 5.72525 19.6911 6.07271 19.9754C7.78806 21.2657 9.85087 21.9436 11.9788 21.9436C14.1067 21.9436 16.1696 21.2656 17.8849 19.9754C18.2323 19.713 18.5797 19.4068 18.8838 19.1006C19.2312 18.7507 19.5786 18.3789 19.8826 17.9634C20.0997 17.6791 20.2952 17.3948 20.4689 17.0886C20.5774 16.9574 20.5991 16.8043 20.534 16.6731Z"
                            fill="#E6E7E9"
                          />
                        </svg>
                      </div>
                      <div class="comment-content">
                        <p class="comment-email">{{ comment.email }}</p>
                        <p class="comment-text">{{ comment.text }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="new-comment">
                    <div class="new-comment-wrap">
                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" fill="#F8FCFC" r="12"/>
                        <path
                          d="M12.0001 4.11328C9.5477 4.11328 7.54297 6.11801 7.54297 8.57042C7.54297 11.0228 9.5477 13.0276 12.0001 13.0276C14.4525 13.0276 16.4573 11.0228 16.4573 8.57042C16.4573 6.11801 14.4525 4.11328 12.0001 4.11328Z"
                          fill="#E6E7E9"
                        />
                        <path
                          d="M20.5349 16.6731C20.4046 16.3451 20.2309 16.0389 20.0355 15.7546C19.0367 14.2675 17.4951 13.2834 15.758 13.0428C15.5408 13.0209 15.302 13.0646 15.1283 13.1959C14.2163 13.8738 13.1306 14.2237 12.0015 14.2237C10.8723 14.2237 9.78668 13.8738 8.8747 13.1959C8.70098 13.0646 8.46212 12.9991 8.24501 13.0428C6.50791 13.2834 4.94455 14.2675 3.96744 15.7546C3.77202 16.0389 3.59829 16.3669 3.46804 16.6731C3.40291 16.8043 3.42461 16.9574 3.48973 17.0886C3.66346 17.3948 3.88058 17.701 4.076 17.9634C4.37998 18.3789 4.70569 18.7507 5.07484 19.1006C5.37882 19.4068 5.72623 19.6911 6.07368 19.9754C7.78904 21.2657 9.85185 21.9436 11.9798 21.9436C14.1077 21.9436 16.1705 21.2656 17.8859 19.9754C18.2333 19.713 18.5807 19.4068 18.8847 19.1006C19.2321 18.7507 19.5796 18.3789 19.8836 17.9634C20.1007 17.6791 20.2962 17.3948 20.4698 17.0886C20.5784 16.9574 20.6001 16.8043 20.5349 16.6731Z"
                          fill="#E6E7E9"
                        />
                      </svg>
                      <input
                        v-model="$v.commentText.$model"
                        placeholder="Add a comment..."
                        type="text"
                        @keyup.enter="addComment"
                      >
                      <button
                        class="send-comment-btn"
                        :disabled="$v.commentText.$invalid"
                        @click="addComment"
                      >
                        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.10525 2.28867C2.98136 2.27111 2.85503 2.28483 2.7378 2.32858C2.62056 2.37233 2.51613 2.44471 2.43402 2.53915C2.35191 2.63358 2.29474 2.74705 2.26771 2.86923C2.24068 2.99141 2.24465 3.11842 2.27925 3.23867L3.69325 8.16367C3.78314 8.47669 3.97245 8.75196 4.2326 8.94788C4.49274 9.1438 4.80958 9.24973 5.13525 9.24967H11.2503C11.4492 9.24967 11.6399 9.32869 11.7806 9.46934C11.9212 9.60999 12.0003 9.80076 12.0003 9.99967C12.0003 10.1986 11.9212 10.3893 11.7806 10.53C11.6399 10.6707 11.4492 10.7497 11.2503 10.7497H5.13525C4.80958 10.7496 4.49274 10.8555 4.2326 11.0515C3.97245 11.2474 3.78314 11.5227 3.69325 11.8357L2.27925 16.7617C2.24465 16.8819 2.24068 17.0089 2.26771 17.1311C2.29474 17.2533 2.35191 17.3668 2.43402 17.4612C2.51613 17.5556 2.62056 17.628 2.7378 17.6718C2.85503 17.7155 2.98136 17.7292 3.10525 17.7117C8.80145 16.9026 14.1265 14.4116 18.3983 10.5577C18.4764 10.4873 18.5389 10.4014 18.5817 10.3053C18.6245 10.2093 18.6466 10.1053 18.6466 10.0002C18.6466 9.89503 18.6245 9.79107 18.5817 9.69502C18.5389 9.59898 18.4764 9.513 18.3983 9.44267C14.1264 5.58877 8.80143 3.09776 3.10525 2.28867Z"
                            fill="#082655"
                          />
                        </svg>
                      </button>
                    </div>

                    <div class="error-wrap" v-if="$v.commentText.$error">
                      <span class="error" v-if="!$v.commentText.required">Field is required</span>
                      <span class="error" v-if="!$v.commentText.minLength">Minimum length is {{
                          $v.commentText.$params.minLength.min
                        }}</span>
                      <span class="error" v-if="!$v.commentText.maxLength">Maximum length is {{
                          $v.commentText.$params.maxLength.max
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.isPinned" class="pinned">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.7068 6.49922L11.365 9.85235C11.65 10.6442 11.7681 11.9705 10.5406 13.6024C10.4547 13.7171 10.3451 13.812 10.2192 13.8805C10.0934 13.9489 9.95418 13.9895 9.8112 13.9992C9.78745 13.9992 9.76433 13.9992 9.74058 13.9992C9.60917 13.9992 9.47905 13.9734 9.35766 13.9231C9.23626 13.8728 9.12596 13.799 9.03308 13.7061L6.01683 10.6867L3.35245 13.353C3.25863 13.4468 3.13138 13.4995 2.9987 13.4995C2.86602 13.4995 2.73877 13.4468 2.64495 13.353C2.55113 13.2592 2.49842 13.1319 2.49842 12.9992C2.49842 12.8665 2.55113 12.7393 2.64495 12.6455L5.3112 9.9811L2.29245 6.96235C2.19323 6.863 2.11604 6.74387 2.06591 6.61271C2.01577 6.48155 1.99383 6.3413 2.00149 6.2011C2.00916 6.06089 2.04626 5.92387 2.11039 5.79896C2.17452 5.67405 2.26424 5.56404 2.3737 5.4761C3.96245 4.19422 5.48308 4.4461 6.1487 4.65485L9.4987 1.29297C9.59156 1.20009 9.70181 1.12641 9.82315 1.07614C9.94449 1.02587 10.0745 1 10.2059 1C10.3372 1 10.4673 1.02587 10.5886 1.07614C10.71 1.12641 10.8202 1.20009 10.9131 1.29297L14.7062 5.08547C14.8937 5.27291 14.9991 5.52712 14.9992 5.79222C14.9993 6.05732 14.8941 6.31162 14.7068 6.49922Z"
                    fill="#5692B7"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import moment from 'moment';
import parseDate from '@/mixins/parseDate';
import parseDateTime from '@/mixins/parseTime';
import CustomCheckbox from '@/components/inputs/CustomCheckbox';
import ImgOfMessengers from '@/components/сases/case-messages/ImgOfMessangers';
import Attachments from '@/components/attachments/Attachments';
import Loader from '@/components/loader/Loader';
import {required, minLength, maxLength} from "vuelidate/lib/validators";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import {mapActions, mapGetters} from "vuex";
import checkPermission from "@/utils/permission";
import debounce from "@/mixins/debounce";

export default {
  name: 'Message',
  emits: ['downloadFile', 'changeCheckbox', 'metaInfoPopup'],
  components: {
    AlertErrorMsg,
    CustomCheckbox,
    ImgOfMessengers,
    Attachments,
    Loader
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    messageSearch: {
      type: String,
    }
  },

  data() {
    return {
      isHovered: false,
      loadFlag: true,
      showTools: false,
      showComments: false,
      highlightColorsEnabled: false,
      editMode: false,
      showOriginalMessage: false,
      newMessage: '',
      errorMessage: '',
      highlightBackgroundColor: this.item.highlight ? this.item.highlight : null,
      commentText: '',
      selectedText: '',
      colors: [
        {origin: 'rgba(255,236,67,0.4)', preview: 'rgba(255,236,67)'},
        {origin: 'rgba(155,126,222,0.4)', preview: 'rgba(155,126,222)'},
        {origin: 'rgba(4,83,202,0.4)', preview: 'rgba(4,83,202)'},
        {origin: 'rgba(255,149,55,0.4)', preview: 'rgba(255,149,55)'},
        {origin: 'rgba(241,16,95,0.4)', preview: 'rgba(241,16,95)'},
        {origin: 'rgba(60,193,59,0.4)', preview: 'rgba(60,193,59)'}
      ],
      comments: [],
    };
  },

  validations() {
    return {
      commentText: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1000)
      },
    }
  },

  watch: {
    showComments(newVal) {
      if (!newVal) {
        this.commentText = '';
        this.$v.$reset();
      }
    }
  },

  computed: {
    ...mapGetters('caseMessages', {
      isEditMode: 'getEditModeState',
      editingMessage: 'getEditingMessage',
    }),
    highlightColor() {
      return this.item.highlight ? this.item.highlight : null;
    },
  },
  mixins: [parseDate, parseDateTime, debounce],
  methods: {
    checkPermission,
    ...mapActions('caseMessages', {
      getMessageById: 'getMessageById',
      setInitialState: 'setInitialState',
    }),

    openTools() {
      this.showTools = !this.showTools;
    },

    closeTools() {
      this.showTools = false;
    },

    changeCheckbox(item) {
      this.$emit('changeCheckbox', item);
    },

    getMetaInfo(messageId) {
      const params = {
        messageId,
        caseId: this.$route.params.slug
      };

      this.$load(async () => {
        const res = await this.$api.cases.getMetaInfo(params);
        this.$emit('metaInfoPopup', res.data.body);
      });
    },

    openComments() {
      this.getComments();
      this.showComments = true;
      this.closeTools();
    },

    closeComments() {
      this.showComments = false;
    },

    commentDate(comment) {
      return moment.utc(comment.createdAt).local().format('DD MMM, HH:mm');
    },

    addComment() {
      if (this.commentText) {
        this.$load(async () => {
          await this.$api.cases
            .createCommentForMessage(this.$route.params.slug, this.item.id, this.commentText)
            .then(() => {
              this.comments.push({
                email: this.$store.getters['profile/user'].email,
                createdAt: new Date(),
                id: this.comments.length + Math.random(),
                text: this.commentText,
              });

              this.item.commentsCount++;
              this.commentText = '';
              this.scrollCommentsToBottom();
              this.$v.$reset();
            }, (error) => {
              if (error.response) {
                this.errorMessage = error.response.data.errors.text[0]
              } else {
                if (error.data.errors.message[0]) {
                  this.errorMessage = error.data.errors.message[0]
                } else {
                  this.errorMessage = error.data.errors.text[0]
                }
              }
              this.$refs.alert.showAlert()
            });
        });
      }
    },

    openHighlight() {
      this.highlightColorsEnabled = true;
      this.closeTools();
    },

    closeHighlight() {
      this.highlightColorsEnabled = false;
    },

    setHighlightBackground(color) {
      this.$load(async () => {
        await this.$api.cases.createHighlightForMessage(this.$route.params.slug, this.item.id, color);
      });

      this.highlightBackgroundColor = color;
      this.closeHighlightColorsMenu();
    },

    deleteHighlightBackground() {
      this.$load(async () => {
        await this.$api.cases.deleteHighlightForMessage(this.$route.params.slug, this.item.id);
      });

      this.highlightBackgroundColor = null;
      this.closeHighlightColorsMenu();
    },

    closeHighlightColorsMenu() {
      this.highlightColorsEnabled = false;
    },

    scrollCommentsToBottom() {
      setTimeout(() => {
        if (this.$refs.commentScroll) {
          this.$refs.commentScroll.scrollTo(0, 9999);
        }
      }, 0);
    },

    getComments() {
      if (this.item.commentsCount < 1) {
        this.loadFlag = false;
        this.comments = [];
        return;
      }

      this.loadFlag = true;
      this.comments = [];

      this.$load(async () => {
        const res = await this.$api.cases.getCommentsForMessage(this.$route.params.slug, this.item.id);

        this.comments = res.data.body;
        this.loadFlag = false;
        this.scrollCommentsToBottom();
      });
    },
    startEditMessage() {
      if (this.isEditMode) {
        this.getMessageById(this.item.id);
      } else {
        this.setInitialState({
          caseId: this.$route.params.slug,
          messageId: this.item.id
        });
        if (this.$route.name !== 'Case messages mobile edit') {
          this.$router.push({name: 'Case messages mobile edit'});
        }
      }
    },
    changeFocusToTextarea() {
      this.$nextTick(() => {
        const textarea = this.$refs.editMessageTextarea;
        textarea.focus();
        textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      });
    },
    adjustTextareaHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.editMessageTextarea;
        textarea.style.width = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    },
    confirmMessageChange() {
      this.$load(async () => {
        await this.$api.cases
          .editMessage(this.$route.params.slug, this.item.id, this.newMessage)
          .then(() => {
            this.editMode = false;
            this.showOriginalMessage = false;
            this.item.editedMessage = this.newMessage;
          }, (error) => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          })
      });
    },
    cancelMessageChange() {
      this.editMode = false;
      this.newMessage = this.item.message;
    },
    changeMessageVersion() {
      this.showOriginalMessage = !this.showOriginalMessage;
    },
    messageToShow() {
      if (this.item.editedMessage && this.item.editedMessage !== this.item.message && !this.showOriginalMessage) {
        return this.highlightedMessage(this.item.editedMessage);
      }
      return this.highlightedMessage(this.item.message);
    },
    highlightedMessage(message) {
      if (!this.messageSearch) {
        return message;
      }

      const escapedMessage = this.escapeHtml(message);
      const escapedSearchText = this.escapeRegExp(this.messageSearch);
      const regex = new RegExp(`(${escapedSearchText})`, 'gi');

      return escapedMessage.replace(
        regex,
        '<span style="background-color: yellow;">$1</span>'
      );
    },
    escapeHtml(text) {
      return text.replace(/[&<>"']/g, (match) => {
        const escapeMap = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#039;',
        };
        return escapeMap[match];
      });
    },
    escapeRegExp(text) {
      return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    addNewLine(e) {
      e.preventDefault();
      this.newMessage += '\n';
      this.adjustTextareaHeight();
    }
  },
};
</script>

<style lang="scss" scoped>
.messages-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: all .2s;
  padding: 14px 20px;
  width: 100%;

  &:hover {
    background-color: var(--case-card-hover-bg);
  }

  &:hover .settings-wrap .button-comment {
    opacity: 1;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
  }

  .holder-contacts {
    display: flex;
    width: 100%;

    .contacts-imgs {
      display: flex;

      .img-item {
        overflow: hidden;
        margin-right: 10px;
        margin-left: 0;
        border: 1px solid var(--white-color);
        border-radius: 50%;
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .message-item-wrapper {
      display: flex;
      position: relative;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      max-width: 445px;

      .message-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 340px;

        .message-item {
          max-width: 340px;
          width: 100%;
          border-radius: 8px;
          padding: 5px 10px;
          cursor: pointer;

          .title-contact {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;

            button {
              display: inline-block;
              background: unset;
              line-height: initial;
              border: none;
            }

            .span-name {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 700;
              gap: 5px;

              button {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                width: 12px;
                height: 12px;
                padding: 0;
                background: unset;
                line-height: initial;

                svg {
                  min-width: 16px;
                  min-height: 16px;
                }
              }

              .span-chat {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: 10px;
                font-weight: 600;
                text-transform: uppercase;

                .chat-icon {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .btn-change-message-version {
              background: unset;
              line-height: initial;
              border: none;
              color: var(--light-gray);
              font-size: 10px;

              &.hidden {
                visibility: hidden;
              }

              button {
                display: flex;
                align-items: center;
                gap: 4px;
                color: var(--light-gray);
              }

              span {
                border-bottom: 1px solid var(--light-gray);
              }
            }
          }

          .desc-contact {
            width: 100%;

            p, textarea {
              width: 100%;
              margin: 5px 0 0;
              font-size: 12px;
              font-weight: 500;
              line-height: 160%;
              color: var(--secondary-text-color);
              white-space: pre-wrap;
            }

            .relative {
              position: relative;

              .blurred {
                position: absolute;
                z-index: 0;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: var(--black-color);
              }

              .background {
                position: absolute;
                z-index: 0;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
              }

              .underline {
                text-decoration: underline;
                text-underline-offset: 2px;
              }

              .edit-message-textarea {
                display: block;
                border: transparent;
                border-radius: 0 !important;
                height: auto;
                padding: 0 !important;
                width: 100%;
              }
            }
          }

          .tag-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 15px;

            .tag {
              font-weight: 500;
              font-size: 10px;
              line-height: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #FFFFFF;
              padding: 2px 4px;
              background-color: #F1105F;
              text-transform: capitalize;
              border-radius: 4px;
              margin-bottom: 4px;

              &:not(:last-child) {
                margin-right: 8px;
              }
            }
          }

          .span-date-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 5px;

            .span-date {
              display: inline-block;
              white-space: nowrap;
              font-size: 11px;
              font-weight: 500;
              color: var(--secondary-text-color);
              margin-right: 8px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .attachment-wrapper {
          display: flex;
          flex-direction: column;
        }
      }

      .message-item-info {
        display: flex;
        position: relative;
        justify-content: flex-start;
        align-items: center;

        .message-item-info--right {
          display: flex;
          flex-direction: column;
          align-items: center;

          .settings-wrap {
            display: flex;

            .button-comment {
              display: flex;
              align-items: center;
              border: none;
              background-color: transparent;
              opacity: 0;


              &:hover svg path {
                stroke: var(--accent-color);
              }

              &:hover .comments-counter {
                color: var(--accent-color);
              }

              svg path,
              .comments-counter {
                transition: all .2s;
              }

              .comments-counter {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #696F79;
              }
            }

            .tools {
              position: absolute;
              z-index: 10;
              top: 50%;
              right: 0;
              justify-content: center;
              align-items: center;
              transition: 0.2s all;
              border: 1px solid var(--case-btn-border-color);
              border-radius: 8px;
              padding: 8px;
              background-color: var(--white-color);
              font-size: 12px;

              .item {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-weight: 500;
                color: var(--secondary-text-color);
                gap: 6px;
                transition: .2s all;

                svg path {
                  transition: .2s all;
                }

                &:not(:last-child) {
                  margin-bottom: 12px;
                }

                &:hover {
                  color: var(--accent-color);
                }

                &:hover svg path {
                  stroke: var(--accent-color);
                }
              }
            }

            .colors {
              display: flex;
              align-items: center;
              transform: translate(-10%, -50%);
              gap: 16px;

              .close {
                border: none;
                width: fit-content;
                padding: 0;
                background-color: transparent;
              }

              .color {
                border: 1px solid transparent;
                border-radius: 50%;
                width: 16px;
                height: 16px;

                &.white {
                  border-color: var(--case-btn-border-color);
                }

                &:hover,
                &.active {
                  border-color: #373c44;
                }
              }
            }

            .comments {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 403px;

              .loader-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .scroll-area {
                width: 100%;
                max-height: 296px;
                overflow-y: auto;
                margin-bottom: 15px;

                /* width */
                &::-webkit-scrollbar {
                  width: 5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                  border-radius: 15px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: var(--accent-color);
                  border-radius: 15px;
                }

                .comment {
                  display: flex;
                  position: relative;
                  margin-bottom: 38px;

                  &:last-child {
                    margin-bottom: 20px;
                  }

                  &-date {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(-10%, 50%);
                    font-size: 10px;
                    color: var(--light-gray);
                  }

                  &-avatar {
                    margin-right: 8px;
                  }

                  &-content {
                    width: 90%;
                    word-break: break-word;
                  }

                  &-email {
                    overflow-x: hidden;
                    margin-bottom: 0;
                    max-width: 280px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 23px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  &-text {
                    overflow: hidden;
                    margin: 0;
                    font-size: 12px;
                    font-weight: 500;
                  }
                }
              }

              .new-comment {
                width: 100%;

                .new-comment-wrap {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  gap: 8px;

                  .send-comment-btn {
                    padding: 0;
                    border: none;
                    background-color: transparent;

                    &:disabled svg path {
                      fill: var(--background-button-disabled-color);
                    }

                    &:not(:disabled):hover svg path {
                      opacity: 0.5;
                    }

                    svg path {
                      transition: all .5s;
                    }
                  }
                }

                .error-wrap {
                  margin-top: 6px;
                  margin-left: 10px;

                  .error {
                    display: inline;
                  }
                }
              }

              &.top {
                top: unset;
                bottom: 100%;
              }

              &.bottom {
                top: 100%;
                bottom: unset;
              }

              input {
                border: none;
                width: 100%;
                height: 25px;
                padding: 0;
                font-size: 12px;
                line-height: 19px;
              }
            }

            .pinned {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }
        }
      }
    }
  }
}

.messages-list-item.active {
  background-color: var(--case-card-hover-bg);
}

button[disabled] {
  cursor: not-allowed;
}
</style>