import { authHeader } from "@/helpers";

export default function(instance) {
  return {
    getSettingExportCase(caseId, type, data) {
      return instance.post(`/caseExport/${caseId}/export/settings/${type}`, data)
    },
    getCountFindingForExport(caseId, type, data) {
      return instance.post(`/caseExport/${caseId}/export/settings/count/${type}`, data)
    },
    exportCaseWithFilters(caseId, format, data) {
      return fetch(`${process.env.VUE_APP_API}/api/v1/cases/${caseId}/v2/export?format=${format}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader(),
        },
        body: JSON.stringify(data),
      })
    },
    exportCaseItems(caseId, type, format, data) {
      return fetch(`${process.env.VUE_APP_API}/api/v1/cases/${caseId}/export/${type}?format=${format}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader(),
        },
        body: JSON.stringify(data),
      })
    },
  }
}