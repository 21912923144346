<template>
  <div class="messages-wrapper">
    <div class="content-wrapper">
      <CaseMessageDetailsNavigation/>
      <CustomSearch placeholder="Find..." @update:modelValue="messageSearch = $event"/>
      <div class="scrollable-container">
        <SetMessages
          v-if="messages.length > 0"
          :messages="messages"
          :messageSearch="messageSearch"
          @changeCheckbox="changeMessageCheckbox"
          editMode
        />
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
          <div slot="spinner">Loading...</div>
          <div slot="no-more">No more messages</div>
          <div slot="no-results">No results</div>
        </infinite-loading>
        <SelectedMessagesInfo
          :messages="messages"
          :selectedMessagesId="selectedMessagesId"
          @updatedActiveStatus="updatedActiveStatus"
          @exportMessages="exportMessages"
        />
      </div>
    </div>
    <MessageDetails/>
    <CommonDialog
      ref="createCaseDialog"
      :show-footer="false"
      :closable="false"
    >
      <h2 class="dialog-message-title">Exporting messages</h2>
      <p class="dialog-create-description" style="display:block; font-size: 14px;">Please wait a bit. The messages are
        being exported.</p>
      <div class="loader-p"></div>
    </CommonDialog>
    <SuccessPopup
      ref="successPopup"
      text="A URL will be sent to your email with a download link. Do not share this link."
    />
  </div>
</template>

<script>
import CaseMessageDetailsNavigation from "@/components/сases/case-messages/tags/CaseMessageDetailsNavigation.vue";
import SetMessages from "@/components/сases/case-messages/SetMessages.vue";
import {mapActions, mapGetters} from "vuex";
import CustomSearch from "@/components/inputs/CustomSearch.vue";
import MessageDetails from "@/components/сases/case-messages/tags/MessageDetails.vue";
import InfiniteLoading from 'vue-infinite-loading';
import SelectedMessagesInfo from "@/components/selected-messages-info/SelectedMessagesInfo.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import SuccessPopup from "@/components/popups/SuccessPopup.vue";

export default {
  name: 'CaseMessagesPinned',
  components: {
    SuccessPopup, CommonDialog,
    SelectedMessagesInfo,
    MessageDetails,
    CustomSearch,
    SetMessages,
    CaseMessageDetailsNavigation,
    InfiniteLoading,
  },
  data() {
    return {
      infiniteId: +new Date(),
      messageSearch: null,
      selectedMessagesId: [],
      dialog: {
        status: "",
        text: "Starting..."
      },
      progressPercentage: 0,
      isCheckedAllMessages: false,
    }
  },
  watch: {
    messageSearch(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setKeySearch(newVal);
        this.infiniteId += 1;
      }
    },
    messages: {
      deep: true,
      handler() {
        const validIds = this.messages.map(m => m.id);
        this.selectedMessagesId = this.selectedMessagesId.filter(id => validIds.includes(id));
        if (this.isCheckedAllMessages) {
          this.messages.map(message => message.isActive = true)
        } else {
          this.messages.map(message => {
            const messageId = this.selectedMessagesId.find(id => id === message.id);
            message.isActive = !!messageId;
          })
        }
      }
    },
  },
  computed: {
    ...mapGetters('caseMessages', {
      messages: "getMessages",
      hasMoreMessages: "getHasMoreMessages",
    })
  },
  methods: {
    ...mapActions('caseMessages', {
      getListPinnedMessages: 'getListPinnedMessages',
      setKeySearch: "setKeySearch",
      setCurrentRoute: "setCurrentRoute",
      resetTabState: "resetTabState",
      setMessages: "setMessages",
    }),
    infiniteHandler($state) {
      this.getListPinnedMessages();
      setTimeout(() => {
        if (this.hasMoreMessages) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 1000);
    },
    changeMessageCheckbox(item) {
      if (item.isActive) {
        this.selectedMessagesId.push(item.id);
      } else {
        this.selectedMessagesId = this.selectedMessagesId.filter(id => id !== item.id);
      }
    },
    updatedActiveStatus($event) {
      this.isCheckedAllMessages = $event.isCheckedAllMessages;
      this.selectedMessagesId = $event.selectedMessagesId;
      this.setMessages($event.messages)
    },
    exportMessages(format, config, data) {
      if (format === 'rsmf' || format === 'csv') {
        this.exportV1(format, data);
      } else if (format === 'pdf' || format === 'xml') {
        this.exportV2(format, config, data);
      }
    },
    exportV1(format, data) {
      this.$load(async () => {
        this.$refs.createCaseDialog.openPopups();
        await this.$api.cases.exportMessagesV1(this.$route.params.slug, format, {messageIds: data}).then(async (response) => {
          // eslint-disable-next-line no-undef
          const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
          await this.readStreamData(reader);
          if (!this.dialog.status.includes("error")) {
            this.dialog.text = "Starting..."
            this.dialog.status = ""
            this.$refs.successPopup.openPopup();
          }
        }, (error) => {
          if (error.response) {
            this.$emit('show-error', error.response.data.errors.message[0]);
          } else {
            this.$emit('show-error', error.data.errors.message[0]);
          }
        })
      })
    },
    exportV2(format, config, data) {
      this.$load(async () => {
        this.$refs.createCaseDialog.openPopups();
        await this.$api.cases.exportMessagesV2(this.$route.params.slug, format, {
          config: config,
          messages: {messageIds: data},
        })
          .then(async (response) => {
            // eslint-disable-next-line no-undef
            const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
            await this.readStreamData(reader);
            if (!this.dialog.status.includes("error")) {
              this.dialog.text = "Starting..."
              this.dialog.status = ""
              this.$refs.successPopup.openPopup();
            }
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          })
      })
    },
    async readStreamData(reader) {
      this.progressPercentage = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const {value, done} = await reader.read();
        if (done) {
          this.$refs.createCaseDialog.closePopups();
          break;
        }
        const splitChunks = value.toString().split("\n");
        for (let i = 0; i < splitChunks.length; i++) {
          if (splitChunks[i].trim()) {
            if (splitChunks[i].includes("event:")) {
              const dataStatus = splitChunks[i].split("event:").find(chunk => chunk.trim() !== '');
              if (dataStatus && typeof dataStatus !== "undefined") {
                this.dialog.status = dataStatus;
              }
            }
            if (splitChunks[i].includes("data:")) {
              const dataText = splitChunks[i].split("data:").find(chunk => chunk.trim() !== '');
              if (dataText && typeof dataText !== "undefined") {
                this.dialog.text = dataText;
              }
            }
          }
        }
        this.progressPercentage += 33;
      }
    },
  },
  created() {
    this.resetTabState()
    const path = this.$route.path;
    this.setCurrentRoute(path.substring(path.lastIndexOf("/") + 1));
  },
};
</script>

<style scoped lang="scss">
.messages-wrapper {
  display: flex;
  margin: 25px 60px 0 40px;
  max-width: 90vw;

  .content-wrapper {
    position: relative;
    padding: 50px 0 0;
    min-width: 600px;
    width: 100%;

    .scrollable-container {
      height: 90vh;
      overflow-y: auto;
      scrollbar-color: var(--background-color) transparent;
      scrollbar-width: thin;
    }
  }


  .loader-p {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 20px 40px 20px 0;
  }
}
</style>