<template>
  <div class="custom-checkbox-group" :class="customClass">
    <div class="checkbox-wrap">
      <input
        type="checkbox"
        :id="id"
        class="input-checkbox"
        :checked="inputElement ? inputElement.isActive: false"
        v-on:input="checked(inputElement)"
        :disabled="checkPermission || disableCheckbox"
      >
      <label :for="id" :id="labelId" :ref="`$checkbox_${labelId}`" class="label-checkbox">
        <span>{{ label || '' }}</span>
      </label>
    </div>
    <tooltip v-if="checkPermission" :target-ref="$refs[`checkbox_${labelId}`]" side="bottom">
      <span>To access to change permissions for users in the system and in the case - you must have the permission 'Manage individual rights</span>
    </tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip.vue";

export default {
  name: "CustomCheckbox",
  components: {Tooltip},
  props: {
    inputElement: {},
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: [String, Number],
      default: 'check_id'
    },
    label: {
      type: String,
    },
    labelId: {
      type: String,
      default: 'label'
    },
    checkPermission: {
      type: Boolean,
    },
    disableCheckbox: {
      type: Boolean,
      required: false,
      default: () => false
    },
    whiteTheme: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    checked(checkbox) {
      if (checkbox) {
        this.inputElement.isActive = !this.inputElement.isActive
        this.$emit('checked', checkbox)
      } else {
        this.$emit('checked')
      }
    }
  },
  mounted() {
    if (this.whiteTheme) {
      document.documentElement.className = "white";
    }
  },
  beforeMount() {
    document.documentElement.className = "";
  }
}
</script>

<style>

.input-checkbox {
  display: none;
}

#permissions_checkbox_input {
  display: none;
}

.custom-checkbox-group {
  position: relative;
}

.custom-checkbox-group .label-checkbox {
  cursor: pointer;
  //position: relative;
  width: 24px;
  height: 24px;
  background-color: var(--checkbox-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: var(--checkbox-border);
  border-radius: 1px;
  margin-bottom: 6px;
}

.label-checkbox:after {
  content: '';
  background: var(--checkbox-bg-checked);
  width: 16px;
  height: 16px;
  border-radius: 1px;
  opacity: 0;
  transition: all .3s ease;
}

.input-checkbox:checked + .label-checkbox:after {
  opacity: 1;
}

.checkbox-wrap span {
  position: absolute;
  top: 2px;
  left: 32px;
  white-space: nowrap;
  font-size: 14px;
}

.m-0 .checkbox-wrap {
  margin: 0;
}

.active-header .checkbox-wrap {
  margin: 0;
}

.active-header .checkbox-wrap span {
  min-width: auto;
  white-space: nowrap;
  left: 30px;
}

.active-header.btn {
  justify-content: flex-start;
}

</style>
