<template>
  <div class="wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered :title="title"/>
      <main class="main">
        <section class="dashboard-section">
          <ManageKeys @title="title = $event ? ('Manage Custodians in ' + $event) : ''"/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import ManageKeys from "@/components/management/ManageKeys.vue";

export default {
  name: "ManagementPage",
  components: {
    HeaderRegistered,
    Sidebar,
    ManageKeys,
  },
  data() {
    return {
      title: 'Manage Custodians',
    }
  },
  mounted() {
    document.title = 'Manage Custodians'
  }
}
</script>

<style scoped>

</style>