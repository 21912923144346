<template>
  <div class="wrapper">
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered :title="caseName + '. No matching data'"/>
      <main class="main">
        <section class="dashboard-section">
          <CaseNoMatchTable />
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import Sidebar from "@/components/Sidebar";
import HeaderRegistered from "@/components/layout/HeaderRegistered";
import CaseNoMatchTable from "@/components/сases/case-data/CaseNoMatchTable.vue";

export default {
  name: "Cases",
  components: {
    CaseNoMatchTable,
    HeaderRegistered,
    Sidebar,
  },
  data() {
    return {
      caseName: '',
    }
  },
  mounted() {
    document.title = 'Case No Matching Data'
    this.caseName = localStorage.getItem('title');
  }
}
</script>

<style scoped>
</style>
