<template>
  <div class="table-row">
    <div class="card">

      <div v-if="!$route.path.includes('cases')" class="case" @click="goToCase">
        <div class="holder-info">
          <span>{{ item.caseName }}</span>
        </div>
      </div>

      <div v-else class="email">
        <div class="holder-info">
          <span>{{ item.email }}</span>
        </div>
      </div>

      <div class="device">
        <div class="holder-info">
          <span>{{ item.device }}</span>
        </div>
      </div>

      <div class="device-id">
        <div class="holder-info">
          <span>{{ item.deviceId }}</span>
        </div>
      </div>

      <div class="collection-date">
        <div class="holder-info">
          <span>{{ item.dateCollection }}</span>
        </div>
      </div>

      <div class="time">
        <div class="holder-info">
          <span>{{ item.timeCollection }}</span>
        </div>
      </div>

      <div class="sources">
        <div class="holder-info">
          <span>{{ capitalizeEveryWord(item.dataSources.replaceAll(',', ' ')).replaceAll(' ', ', ') }}</span>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import capitalizeEveryWord from "@/mixins/capitalizeEveryWord";

export default {
  name: "NoMatchItem",
  components: {},
  props: {
    item: {
      required: true,
    }
  },
  data() {
    return {
      popupAction: '',
      showTooltip: false,
    }
  },
  mixins: [capitalizeEveryWord],
  methods: {
    goToCase() {
      localStorage.setItem('title', this.item.caseName)
      this.$router.push({path: `/cases/${this.item.caseId}`}).catch(() => {
      })
    }
  },
}
</script>
<style scoped lang="scss">
.case,
.email {
  width: 25%;
}

.device {
  width: 10%;
}

.device-id {
  width: 10%;
}

.collection-date {
  width: 14%;
}

.time {
  width: 10%;
}

.sources {
  width: 30%;
}

.card {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 0 15px 15px;
  transition: all 0.5s;
  position: relative;
  border-bottom: 1px solid var(--case-btn-border-color);
  width: 100%;

  .holder-checkbox {
    width: 55px;
  }
}

.holder-info {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.holder-info span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.case .holder-info span {
  cursor: pointer;
  text-decoration: underline;
}
</style>