<template>
  <div
    class="table-row"
  >
    <div class="card">

      <div
        class="case"
        @click="goToCase"
      >
        <div class="holder-info">
          <span>{{ keyItem.caseName }}</span>
        </div>
      </div>

      <div class="keyid">
        <div class="holder-info">
          <span>{{ keyItem.keyId }}</span>
          <svg @click="copyKeyIdToBuffer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.1875 0.47583H4.1875C4.03832 0.47583 3.89524 0.535093 3.78975 0.640583C3.68426 0.746072 3.625 0.889146 3.625 1.03833V3.85083H0.8125C0.663316 3.85083 0.520242 3.91009 0.414753 4.01558C0.309263 4.12107 0.25 4.26415 0.25 4.41333V13.4133C0.25 13.5625 0.309263 13.7056 0.414753 13.8111C0.520242 13.9166 0.663316 13.9758 0.8125 13.9758H9.8125C9.96168 13.9758 10.1048 13.9166 10.2102 13.8111C10.3157 13.7056 10.375 13.5625 10.375 13.4133V10.6008H13.1875C13.3367 10.6008 13.4798 10.5416 13.5852 10.4361C13.6907 10.3306 13.75 10.1875 13.75 10.0383V1.03833C13.75 0.889146 13.6907 0.746072 13.5852 0.640583C13.4798 0.535093 13.3367 0.47583 13.1875 0.47583ZM9.25 12.8508H1.375V4.97583H9.25V12.8508ZM12.625 9.47583H10.375V4.41333C10.375 4.26415 10.3157 4.12107 10.2102 4.01558C10.1048 3.91009 9.96168 3.85083 9.8125 3.85083H4.75V1.60083H12.625V9.47583Z"
              fill="#696F79" />
          </svg>
          <transition name="fade">
            <span v-if="showTooltip" class="tooltip">Copied</span>
          </transition>
        </div>
      </div>

      <div class="registered">
        <div class="holder-info">
          <span>{{ parseDate(keyItem.createdAt) }}</span>
        </div>
      </div>

      <div class="usage">
        <div class="holder-info">
          <span>{{ parseDate(keyItem.usageDateFrom) + " - " + parseDate(keyItem.usageDateTo) }}</span>
        </div>
      </div>

      <div class="days-left">
        <div class="holder-info">
            <span :style="{ color: keyItem.dayLeft > 5 ? '#5692B7' : keyItem.dayLeft > 0 ? '#FF9537' : '#F1105F' }">
              {{ (keyItem.dayLeft ? keyItem.dayLeft : "0") + " days" }}
            </span>
        </div>
      </div>

      <div class="status">
        <div
          class="holder-info"
          :class="{
              active: keyItem.status.toLowerCase() === KEY_STATUS.active,
              pending: keyItem.status.toLowerCase() === KEY_STATUS.pending,
              expired: keyItem.status.toLowerCase() === KEY_STATUS.expired,
              deactivated: keyItem.status.toLowerCase() === KEY_STATUS.deactivated,
            }">
          <span>{{ keyItem.status.toLowerCase() }}</span>
        </div>
      </div>

      <div class="context-menu">
        <ContextMenu :item="keyItem" :options="menuOptions" @onSelected="onMenuSelection(keyItem, $event)" />
      </div>

    </div>
  </div>
</template>
<script>
import ContextMenu from "@/components/popups/ContextMenu.vue";
import parseDate from "@/mixins/parseDate";
import { COMMON_ERROR, KEY_ACTION, KEY_STATUS, KEYS_MENU } from "@/constants/const";
import genIcon from "@/assets/img/key-management/generate-icon.svg";
import deleteIcon from "@/assets/img/key-management/delete.svg";
import resendIcon from "@/assets/img/key-management/resend.svg";
import gotoProfile from "@/mixins/gotoProfile";

export default {
  name: "ClientKeyItem",
  components: {
    ContextMenu,
  },
  emits: ["openKeysPopup"],
  props: {
    keyItem: {
      required: true,
    }
  },
  data() {
    return {
      KEY_STATUS,
      popupAction: '',
      showTooltip: false,
    }
  },
  computed: {
    menuOptions() {
      const menuOptions = [];
      const keyStatus = this.keyItem.status.toLowerCase();

      if (keyStatus === KEY_STATUS.active) {
        menuOptions.push({name: KEYS_MENU.resendKey, img: resendIcon});
      } else if (keyStatus === KEY_STATUS.expired || keyStatus === KEY_STATUS.deactivated) {
        menuOptions.push({name: KEYS_MENU.genKey, img: genIcon});
      }

      if (keyStatus !== KEY_STATUS.deactivated) {
        menuOptions.push({name: KEYS_MENU.deactivateKey, img: deleteIcon});
      }

      return menuOptions;
    }
  },
  mixins: [parseDate, gotoProfile],
  methods: {
    onMenuSelection(key, optionName) {
      switch (optionName) {
        case KEYS_MENU.genKey:
          this.$emit("openKeysPopup", KEY_ACTION.generateKey, key);
          break;
        case KEYS_MENU.resendKey:
          this.$emit("openKeysPopup", KEY_ACTION.resendKey, key);
          break;
        case KEYS_MENU.deactivateKey:
          this.$emit("openKeysPopup", KEY_ACTION.deactivateKey, key);
          break;
        default: this.errorMessage = COMMON_ERROR.noSuchOption;
      }
    },
    copyKeyIdToBuffer() {
      navigator.clipboard.writeText(this.keyItem.keyId)
        .then(() => {
          this.showTooltip = true;

          setTimeout(() => {
            this.showTooltip = false;
          }, 2000);
        })
    },
    goToCase() {
      localStorage.setItem('title', this.keyItem.caseName)
      this.$router.push({path: `/cases/${this.keyItem.caseId}`}).catch(()=>{})
    }
  },
}
</script>
<style scoped lang="scss">
.case {
  width: 15%;
}

.keyid {
  width: 15%;
}

.registered {
  width: 15%;
}

.usage {
  width: 20%;
}

.days-left {
  width: 15%;
}

.status {
  width: 15%;
}

.context-menu {
  width: 3%;
}

.card {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 0 15px 15px;
  transition: all 0.5s;
  position: relative;
  border-bottom: 1px solid var(--case-btn-border-color);
  width: 100%;

  .holder-checkbox {
    width: 55px;
  }
}

.holder-info {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.holder-info svg {
  cursor: pointer;
}

.holder-info span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.case .holder-info span {
  cursor: pointer;
  text-decoration: underline;
}

.tooltip {
  position: absolute;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  z-index: 100;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  transform: translate(60%, -100%);
  transition: all .5s ease;
}

.active {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: #0826551A;
}

.active span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: var(--accent-color);
  margin: 0 auto;
}

.expired {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: var(--storage-warning);
}

.expired span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: var(--white-color);
  margin: 0 auto;
}

.pending {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: #FFC10780;
  border: 1px solid #FFC107;
}

.pending span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: #8B6900;
  margin: 0 auto;
}

.deactivated {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: rgba(8, 38, 85, 0.05);
}

.deactivated span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  --accent-color: rgba(8, 38, 85, 0.5);
  margin: 0 auto;
}
</style>