<template>
  <div class="holder-table">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="keys-search-panel">
      <CustomSearch
        :modelValue="keySearch"
        id="keys_search_input"
        @update:modelValue="keySearch = $event"
        @filter="getDataByFilter"
        :classComponent="'case'"
        placeholder="Search by email"
        style="max-width: 225px"
      />
      <date-picker
        v-model="dataRange"
        :id="`sort_by_date_keys`"
        range
        :format="'DD/MM/YYYY'"
        :range-separator="' - '"
        :editable="false"
        class="datepicker-input"
        :input-class="'datepicker-input'"
        placeholder="Date of collection"
      >
        <template #icon-calendar>
          <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z"
              fill="#082655"/>
            <path
              d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z"
              fill="white"/>
            <path
              d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z"
              fill="#082655"/>
            <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655"/>
            <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655"/>
            <path
              d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z"
              fill="#082655"/>
            <path
              d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z"
              fill="#082655"/>
            <path
              d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z"
              fill="white"/>
          </svg>
        </template>
        <template #icon-clear>
          <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
              fill="#082655"/>
          </svg>
        </template>
      </date-picker>
      <CustomMultiSelect
        :id="`assign_select_role_user`"
        :options="sourcesList"
        v-model="selectedSources"
        :multiple="true"
        :placeholder="'Choose sources'"
        @saved="getDataByFilter"
        ref="sourcesSelect"
        style="max-width: 200px"
        :custom-class="'selectedMessages'"
      />
      <button
        v-if="isAnyFilterApplied"
        class="reset-filters"
        @click="resetFilters"
      >
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z"
            fill="#FF9537"/>
        </svg>
        Reset Filters
      </button>
    </div>
    <TableHeader>

      <div class="email">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z"
              fill="#D7DCE6"/>
          </svg>
          <span class="header-table-text">Custodian email</span>
        </div>
      </div>

      <div class="device">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Device</span>
        </div>
      </div>

      <div class="device-id">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Device Id</span>
        </div>
      </div>

      <div class="collection-date">
        <div
          :id="`sort_keys_by_collection-date`"
          @click="sortedBy('collectionDate')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'collectionDate',
            'sorted-desc': this.order === ASC,
          }"
          style="cursor: pointer"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Date of collection</span>
          <arrow-down class="arrow"/>
        </div>
      </div>

      <div class="time">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Time of collection</span>
        </div>
      </div>

      <div class="sources">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Data sources</span>
        </div>
      </div>
    </TableHeader>

    <div v-if="dataMismatch.length > 0" class="table-body">
      <NoMatchItem
        v-for="(item, index) in dataMismatch"
        :key="index"
        :item="item"
      />
      <pagination
        v-if="dataMismatch.length && totalPages !== 1"
        v-model="page"
        :total-pages="totalPages"
        :link-gen="linkGen"
      />
    </div>

    <div class="not-found" v-else>
      <h3>No data found</h3>
    </div>
  </div>
</template>

<script>
import TableHeader from "@/components/table/TableHeader";
import {APP_LIST_MULTISELECT, ASC, DESC, DEVICE_TYPES_SELECT} from "@/constants/const";
import ArrowDown from "@/components/icons/ArrowDown.vue";
import NoMatchItem from "@/pages/client-profile/NoMatchItem.vue";
import Pagination from "@/components/common/Pagination.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import DatePicker from "vue2-datepicker";
import CustomMultiSelect from "@/components/inputs/CustomMultiSelect.vue";
import debounce from "@/mixins/debounce";
import CustomSearch from "@/components/inputs/CustomSearch.vue";
import linkGenerator from "@/constants/linkGen";

export default {
  name: "CaseNoMatchTable",
  components: {
    CustomSearch,
    CustomMultiSelect,
    AlertErrorMsg,
    Pagination,
    NoMatchItem,
    ArrowDown,
    TableHeader,
    DatePicker,
  },
  data() {
    return {
      sortBy: 'collectionDate',
      order: DESC,
      DESC,
      ASC,
      deviceTypes: DEVICE_TYPES_SELECT,
      sourcesList: APP_LIST_MULTISELECT,

      dataMismatch: [],

      totalPages: 0,
      page: 1,
      size: 10,

      keySearch: '',
      selectedSources: [],

      dataRange: '',
      dateFrom: '',
      dateTo: '',

      errorMessage: '',
      infiniteId: +new Date(),
    };
  },
  watch: {
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.getDataByFilter();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = +newVal;
          this.getMismatchData();
        } else {
          this.page = 1;
        }
      }
    },
    page(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
  },
  computed: {
    isAnyFilterApplied() {
      return this.dataRange
        || this.dateFrom
        || this.dateTo
        || this.selectedSources.length;
    },
  },
  mixins: [debounce],
  methods: {
    linkGen(pageNum) {
      return linkGenerator(pageNum, `/cases/${this.$route.params.slug}/matching`);
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === DESC ? ASC : DESC;
      this.sortBy = filterName
      this.getMismatchData();
    },
    getDataByFilter() {
      this.page = 1;
      this.dataMismatch = [];
      this.getMismatchData();
    },
    getMismatchData($state) {
      this.$load(async () => {
          const res = await this.$api.mismatch.getNoMatchData({
            page: this.page,
            size: this.size,
            keySearch: this.keySearch,
            dateFrom: this.dateFrom || '',
            dateTo: this.dateTo || '',
            caseId: this.$route.params.slug,
            deviceType: '',
            sources: this.selectedSources.length ? this.selectedSources.map(item => item.caseName).join(',') : '',
            sortOrder: this.order,
          });
          if (res.data.body.content.length > 0) {
            if ($state) {
              const newData = res.data.body.content;
              const existingDataIds = new Set(this.dataMismatch.map(data => data.id));
              const uniqueData = newData.filter(data => !existingDataIds.has(data.id));
              this.dataMismatch.push(...uniqueData);
              $state.loaded();
            } else {
              this.dataMismatch = res.data.body.content;
            }
            this.totalPages = res.data.body.totalPages;
          } else if ($state) {
            $state.complete();
          }
        }, (error => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert();
        })
      )
    },
    resetFilters() {
      this.dataRange = '';
      this.dateFrom = '';
      this.dateTo = '';
      this.sortBy = '';
      this.order = '';
      this.selectedSources = [];
      this.$refs.sourcesSelect.value = null;
      this.page = 1;
      this.getMismatchData();
    },
  },
  mounted() {
    this.getMismatchData();
  }
}
</script>

<style lang="scss" scoped>

.holder-table {
  margin: 15px 0 0;

  .keys-search-panel {
    display: flex;
    gap: 15px;
    height: fit-content;
    align-items: center;
    margin: 0 0 15px;

    .reset-filters {
      background-color: transparent;
      display: flex;
      align-items: center;
      border: none;
      margin-right: 23px;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      color: var(--secondary-black-color);

      &:disabled {
        cursor: not-allowed;

        path {
          fill: var(--case-btn-border-color);
        }
      }

      svg {
        margin-right: 10px;
      }

      &:not(:disabled):hover {
        text-decoration: underline;
      }
    }
  }
}

.scrollable-container {
  height: 50vh;
  overflow-y: auto;
  scrollbar-color: var(--background-color) transparent;
  scrollbar-width: thin;
}

.table-body {
  min-height: fit-content;
}

.table-header {
  max-height: 64px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 15px;
}

.table-header-box {
  cursor: default;
}

.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box .header-table-text {
  margin: 0 5px 0 0;
  width: fit-content;
}

.table-header-box:hover .header-table-text {
  text-decoration: none;
}

.email {
  width: 25%;
}

.device {
  width: 10%;
}

.device-id {
  width: 10%;
}

.collection-date {
  width: 14%;
}

.time {
  width: 10%;
}

.sources {
  width: 9%;
}

.arrow {
  cursor: pointer;
  transition: transform 0.3s;
}

.collection-date .table-header-box:hover svg {
  fill: var(--accent-color);
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: var(--light-gray);
}
</style>
