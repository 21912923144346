<template>
  <div class="notification-wrapper">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="holder-table">
        <table cellpadding="0" cellspacing="0" border="0">
          <TableHeader>
          <tr>
            <th class="w-300">
              <div
                  class="table-header-box"
                  @click="sortedBy('userName')"
                  :class="{
                    'sort-active': this.sortBy === 'userName',
                    'sorted-desc': this.order === 'desc',
                  }"
              >
                <CaseIcon />
                <span class="header-table-text">Contact</span>
                <arrowDown class="arrow" />
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.89502 0.861328C1.79045 0.861328 0.89502 1.75676 0.89502 2.86133V16C0.89502 17.1046 1.79045 18 2.89502 18H18.1976C19.3022 18 20.1976 17.1046 20.1976 16V2.86133C20.1976 1.75676 19.3022 0.861328 18.1976 0.861328H2.89502ZM11.7372 10.9515C10.994 10.8618 10.1832 10.817 9.30487 10.817C9.25983 10.817 9.21478 10.8226 9.16974 10.8338C9.12469 10.845 9.07965 10.8618 9.03461 10.8842C8.98956 10.9067 8.95015 10.9347 8.91637 10.9683C8.81502 11.0803 8.76434 11.2091 8.76434 11.3548V13.8418L3.13951 9.2038L8.76434 4.61619V7.03603C8.76434 7.18167 8.81783 7.3077 8.92481 7.41413C9.03179 7.52056 9.15848 7.57377 9.30487 7.57377C12.4129 7.57377 14.7045 8.4308 16.1797 10.1449C17.1481 11.2539 17.7393 12.6207 17.9533 14.2451C17.7393 13.9427 17.5141 13.6682 17.2776 13.4217C17.0411 13.1753 16.7483 12.9148 16.3993 12.6403C16.0502 12.3658 15.6532 12.125 15.2084 11.9177C14.7636 11.7105 14.2597 11.52 13.6966 11.3464C13.1336 11.1727 12.4805 11.0411 11.7372 10.9515Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Action</span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.1759 3.35802C15.6255 3.35802 16.0065 3.52263 16.3187 3.85185C16.631 4.18107 16.7871 4.58272 16.7871 5.05679V14.3012C16.7871 14.7753 16.631 15.177 16.3187 15.5062C16.0065 15.8354 15.6255 16 15.1759 16H2.39835C1.9487 16 1.56775 15.8354 1.25549 15.5062C0.943237 15.177 0.787109 14.7753 0.787109 14.3012V5.05679C0.787109 4.58272 0.943237 4.18107 1.25549 3.85185C1.56775 3.52263 1.9487 3.35802 2.39835 3.35802H5.58336V1.65926C5.58336 1.21152 5.73949 0.823045 6.05175 0.493827C6.364 0.164609 6.74496 0 7.1946 0H10.3796C10.8293 0 11.2102 0.164609 11.5225 0.493827C11.8347 0.823045 11.9909 1.21152 11.9909 1.65926V3.35802H15.1759ZM10.3796 3.35802V1.65926H7.1946V3.35802H10.3796Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Case</span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 7.5C9.70938 7.5 11.5 5.82129 11.5 3.75C11.5 1.67871 9.70938 0 7.5 0C5.29063 0 3.5 1.67871 3.5 3.75C3.5 5.82129 5.29063 7.5 7.5 7.5ZM10.3 8.4375H9.77812C9.08437 8.73633 8.3125 8.90625 7.5 8.90625C6.6875 8.90625 5.91875 8.73633 5.22188 8.4375H4.7C2.38125 8.4375 0.5 10.2012 0.5 12.375V13.5938C0.5 14.3701 1.17188 15 2 15H13C13.8281 15 14.5 14.3701 14.5 13.5938V12.375C14.5 10.2012 12.6187 8.4375 10.3 8.4375Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Actor</span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <RoleIcon />
                <span class="header-table-text">Actor’s Role</span>
              </div>
            </th>
            <th>
              <div
                  @click="sortedBy('createdAt')"
                  id="notification_permission_sort_by_date"
                  class="table-header-box"
                  :class="{
                    'sort-active': this.sortBy === 'createdAt',
                    'sorted-desc': this.order === 'desc',
                  }"
              >
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Date</span>
                <arrowDown class="arrow" />
              </div>
            </th>
          </tr>
          </TableHeader>
          <Loader v-if="loadFlag" :load-flag="loadFlag" />
          <template v-else>
            <NotificationsPermissionItem
                :notificationsPermissionList="notificationsPermissionList"
            />
            <p v-if="!notificationsPermissionList.length" class="no-found">No data</p>
          </template>
        </table>
      <pagination v-if="notificationsPermissionList.length && count !== 1"
                  v-model="currentPage"
                  :total-pages="count"
                  :link-gen="linkGen"
      ></pagination>
    </div>
  </div>
</template>

<script>
import linkGenerator from "../../constants/linkGen";
import AlertErrorMsg from "../profile/AlertErrorMsg";
import TableHeader from "../table/TableHeader";
import NotificationsPermissionItem from "./NotificationsPermissionItem";
import {mapActions} from "vuex";
import Loader from "../loader/Loader";
import ArrowDown from "../icons/ArrowDown";
import CaseIcon from "../icons/CaseIcon";
import RoleIcon from "../icons/RoleIcon";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "NotificationsPermission",
  components: {
    Pagination,
    Loader,
    RoleIcon,
    CaseIcon,
    ArrowDown,
    AlertErrorMsg,
    NotificationsPermissionItem,
    TableHeader,
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      sortBy: 'createdAt',
      order: 'desc',
      dateFrom: '',
      dateTo: '',
      notificationsPermissionList: [],
      notificationsPermissionNotViewList: [],
      loadFlag: false,
      errorMessage: '',
    }
  },
  props: {
    dataRange: {},
    searchText: {
      type: String,
      required: true,
    },
  },
  watch: {
    searchText() {
      this.getNotificationPermission()
    },
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : '';
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1])  : '';
      this.currentPage = 1;
      this.getNotificationPermission();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.currentPage = +newVal;
          this.getNotificationPermission();
        } else {
          this.currentPage = 1;
        }
      }
    },

    currentPage(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
  },
  methods: {
    ...mapActions({
      getNotificationCount: 'notifications/getNotificationCount',
      getNotificationAll: 'notifications/getNotificationAll'
    }),
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/notifications/permission')
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getNotificationPermission()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    notificationView(oldArr, arr) {
      oldArr.forEach(item => {
        if(!item.viewed) {
          arr.push(item.id)
        }
      })
    },
    getNotificationPermission() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.notifications.getNotificationPermission({
          sortBy: this.sortBy,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          order: this.order,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: this.currentPage,
          size: this.size,
        }).then((res) => {
          this.notificationsPermissionList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
          this.notificationsPermissionNotViewList = []
          this.notificationView(this.notificationsPermissionList, this.notificationsPermissionNotViewList)
          if(this.notificationsPermissionNotViewList.length) {
            this.changeStatusNotificationType()
          }
        }, (error) => {
          if(error) {
            if(error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            }else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          }
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    changeStatusNotificationType() {
      this.$load(async () => {
        await this.$api.notifications.changeStatusNotificationType({
          notificationIds : this.notificationsPermissionNotViewList.toString(),
        }).then(() => {
          this.getNotificationAll({}).then(() => {
            this.getNotificationCount({})
          })
        })
      })
    },
  },
  mounted() {
    this.currentPage = +this.$route.query.page || 1;
    this.getNotificationPermission()
  }
}
</script>

<style scoped>
.holder-table {
  position: relative;
}
</style>
