<template>
  <div class="tab-wrapper">
    <router-link
      :to="{ name: 'Case messages mobile list' }"
      tag="button"
      id="messages_mobile_tab"
      exact-path
      class="tab-btn"
    >
      <span class="tab-btn-text">Mobile chats</span>
    </router-link>
    <router-link
      :to="{ name: 'Case messages email list' }"
      tag="button"
      id="messages_email_tab"
      exact-path
      class="tab-btn"
    >
      <span class="tab-btn-text">Email</span>
    </router-link>
    <router-link
      :to="{ name: 'Case messages media list' }"
      tag="button"
      id="messages_media_tab"
      exact-path
      class="tab-btn"
    >
      <span class="tab-btn-text">Media</span>
    </router-link>
    <router-link
      :to="{ name: 'Case messages call logs list' }"
      tag="button"
      id="messages_logs_tab"
      exact-path
      class="tab-btn"
    >
      <span class="tab-btn-text">Call Logs</span>
    </router-link>
    <router-link
      :to="{ name: 'Case messages contacts list' }"
      tag="button"
      id="messages_contacts_tab"
      exact-path
      class="tab-btn"
    >
      <span class="tab-btn-text">Contacts</span>
    </router-link>
    <router-link
      :to="{ name: 'Case messages apps list' }"
      tag="button"
      id="messages_apps_tab"
      exact-path
      class="tab-btn"
    >
      <span class="tab-btn-text">Apps</span>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'CaseMessagesNavigation',
}
</script>
<style scoped lang="scss">
.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 310px;
  width: auto;
  min-height: 35px;
  margin-right: 20px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);

  .tab-btn {
    margin: -1px 0 -1px 0;
    width: 50%;
    max-height: 35px;
    padding: 10px 30px;
    border: 1px solid transparent;
    outline: none;
    background-color: transparent;
    border-radius: 79px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    transition: 0.3s all;
  }

  .tab-btn.active {
    border-color: var(--accent-color);
  }

  .tab-btn-text {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: var(--light-gray);
    transition: 0.3s all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tab-btn.active .tab-btn-text,
  .tab-btn:hover .tab-btn-text {
    color: var(--accent-color);
  }
}
</style>