<template>
  <div class="wrapper user-profile-public-page__wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="profile-section">
          <div v-if="client" class="data-bar">
            <div class="data-bar-name">
              <div class="data-bar-name-img">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.5 15C21.5 16.0878 21.1774 17.1512 20.5731 18.0556C19.9687 18.9601 19.1098 19.6651 18.1048 20.0813C17.0998 20.4976 15.9939 20.6065 14.927 20.3943C13.8601 20.1821 12.8801 19.6583 12.1109 18.8891C11.3417 18.1199 10.8179 17.1399 10.6057 16.073C10.3935 15.0061 10.5024 13.9002 10.9187 12.8952C11.335 11.8902 12.0399 11.0313 12.9444 10.4269C13.8488 9.82257 14.9122 9.5 16 9.5C17.4582 9.50165 18.8562 10.0816 19.8873 11.1127C20.9184 12.1438 21.4984 13.5418 21.5 15ZM29 16C29 18.5712 28.2376 21.0846 26.8091 23.2224C25.3807 25.3603 23.3503 27.0265 20.9749 28.0104C18.5995 28.9944 15.9856 29.2518 13.4638 28.7502C10.9421 28.2486 8.6257 27.0105 6.80762 25.1924C4.98953 23.3743 3.75141 21.0579 3.2498 18.5362C2.74819 16.0144 3.00563 13.4006 3.98957 11.0251C4.97351 8.64968 6.63975 6.61935 8.77759 5.1909C10.9154 3.76244 13.4288 3 16 3C19.4467 3.00364 22.7512 4.37445 25.1884 6.81163C27.6256 9.24882 28.9964 12.5533 29 16ZM27 16C26.9984 14.5194 26.6982 13.0544 26.1174 11.6924C25.5366 10.3305 24.6871 9.09974 23.6198 8.07367C22.5524 7.04759 21.289 6.24732 19.9053 5.7207C18.5215 5.19408 17.0457 4.95194 15.5663 5.00875C9.67876 5.23625 4.98376 10.14 5.00001 16.0312C5.00565 18.7132 5.99478 21.2998 7.78001 23.3013C8.50703 22.2468 9.43056 21.3423 10.5 20.6375C10.5912 20.5773 10.6996 20.5486 10.8086 20.5558C10.9177 20.563 11.0213 20.6058 11.1038 20.6775C12.4627 21.8529 14.1995 22.4998 15.9963 22.4998C17.793 22.4998 19.5298 21.8529 20.8888 20.6775C20.9712 20.6058 21.0749 20.563 21.1839 20.5558C21.2929 20.5486 21.4013 20.5773 21.4925 20.6375C22.5633 21.342 23.4881 22.2464 24.2163 23.3013C26.0103 21.2925 27.0013 18.6932 27 16Z"
                    fill="black"/>
                </svg>
              </div>
              <div class="data-bar-name-text">
                <span>{{ client.email }}</span>
              </div>
            </div>
            <div class="data-bar-info">
              <button
                :disabled="!checkPermission(['manage_contacts'])"
                :id="`deactivate_client_${client.publicId.substring(0, 20)}`"
                class="btn btn-deactivate"
                data-title="Deactivate"
                @click.stop="confirmDeactivateUser"
              >
                <span class="btn-span">Deactivate</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                    fill="#D7DCE6"/>
                </svg>
              </button>
              <tooltip v-if="!checkPermission(['manage_contacts'])"
                       :target-ref="$refs[`disabledDeactivateClient_${client.publicId.substring(0, 20)}`]">
                <span>To be able to deactivate users from a system - you need to have the 'Manage contact's' permission in system</span>
              </tooltip>
            </div>
          </div>
          <div class="info-table">
            <div class="profile-cases">
              <h6>Member of case</h6>
              <div class="keys-search-panel">
                <CustomSelect
                  v-model="selectedCaseId"
                  placeholder="Case name"
                  :options="casesList"
                  @input="getKeysByFilter"
                  :type-select="'filterUser'"
                  :customClass="'custom-select-sessions'"
                  ref="caseSelect"
                />
                <date-picker
                  v-model="regDataRange"
                  :id="`sort_keys_by_reg_date`"
                  range
                  :format="'DD/MM/YYYY'"
                  :range-separator="' - '"
                  :editable="false"
                  class="datepicker-input"
                  :input-class="'datepicker-input'"
                  placeholder="Registration date"
                >
                  <template #icon-calendar>
                    <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z"
                        fill="#082655"/>
                      <path
                        d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z"
                        fill="white"/>
                      <path
                        d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z"
                        fill="#082655"/>
                      <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655"/>
                      <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655"/>
                      <path
                        d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z"
                        fill="#082655"/>
                      <path
                        d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z"
                        fill="#082655"/>
                      <path
                        d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z"
                        fill="white"/>
                    </svg>
                  </template>
                  <template #icon-clear>
                    <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
                        fill="#082655"/>
                    </svg>
                  </template>
                </date-picker>
                <date-picker
                  v-model="dataRange"
                  :id="`sort_by_date_keys`"
                  range
                  :format="'DD/MM/YYYY'"
                  :range-separator="' - '"
                  :editable="false"
                  class="datepicker-input"
                  :input-class="'datepicker-input'"
                  placeholder="Usage period"
                >
                  <template #icon-calendar>
                    <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z"
                        fill="#082655"/>
                      <path
                        d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z"
                        fill="white"/>
                      <path
                        d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z"
                        fill="#082655"/>
                      <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655"/>
                      <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655"/>
                      <path
                        d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z"
                        fill="#082655"/>
                      <path
                        d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z"
                        fill="#082655"/>
                      <path
                        d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z"
                        fill="white"/>
                    </svg>
                  </template>
                  <template #icon-clear>
                    <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
                        fill="#082655"/>
                    </svg>
                  </template>
                </date-picker>
                <CustomSelect
                  v-model="selectedStatus"
                  placeholder="Key Status"
                  :options="statusList"
                  @input="getKeysByFilter"
                  :customClass="'custom-select-sessions'"
                  ref="statusSelect"
                />
                <button
                  v-if="isAnyFilterApplied"
                  class="reset-filters"
                  @click="resetFilters"
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z"
                      fill="#FF9537"/>
                  </svg>
                  Reset Filters
                </button>
              </div>
              <ClientKeysList :keys-data="clientKeys" @sortBy="getKeysWithSortBy"/>
            </div>
            <div v-if="client" class="profile-no-data">
              <h6>No Matching Data</h6>
              <ClientNoMatching :client-email="client.email" :cases-list="casesList" />
            </div>
            <div class="profile-sessions">
              <h6>Collections</h6>
              <ClientCollectionsList :cases-list="casesList" />
            </div>
          </div>
        </section>
      </main>
    </div>
    <InfoDialog
      ref="infoDialog"
      :title="infoDialogData.dialogTitle"
      :text="infoDialogData.dialogText"
      :confirmBtnText="infoDialogData.confirmBtnText"
      :cancelBtnText="infoDialogData.cancelBtnText"
      @onConfirm="deactivateUser"/>
    <CommonDialog
      ref="commonDialogDeleteUser"
      @closed="$router.push({ path: '/management' })"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">Contact was deactivated</p>
    </CommonDialog>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import HeaderRegistered from "@/components/layout/HeaderRegistered";
import Tooltip from "@/components/common/Tooltip.vue";
import checkPermission from "@/utils/permission";
import createDeactivateDialog from "@/constants/infoDialogData";
import InfoDialog from "@/components/popups/InfoDialog.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import ClientKeysList from "@/pages/client-profile/ClientKeysList.vue";
import ClientCollectionsList from "@/pages/client-profile/ClientCollectionsList.vue";
import {mapActions} from "vuex";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import {KEY_STATUS_SELECT} from "@/constants/const";
import DatePicker from "vue2-datepicker";
import ClientNoMatching from "@/pages/client-profile/ClientNoMatchTable.vue";

export default {
  name: "ClientProfile",
  components: {
    ClientNoMatching,
    CustomSelect,
    ClientCollectionsList,
    ClientKeysList,
    CommonDialog,
    AlertErrorMsg,
    InfoDialog,
    Tooltip,
    Sidebar,
    HeaderRegistered,
    DatePicker,
  },
  data() {
    return {
      clientId: this.$route.params.slug,
      client: null,
      returnPath: true,
      infoDialogData: {},
      errorMessage: '',

      page: 1,
      size: 10,

      clientKeys: [],
      casesFromApi: [],
      selectedCaseId: null,
      selectedStatus: null,
      statusList: KEY_STATUS_SELECT,

      dataRange: '',
      dateFrom: '',
      dateTo: '',

      regDataRange: '',
      regDateFrom: '',
      regDateTo: '',

      sortBy: '',
      orderBy: '',
    }
  },
  watch: {
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.getKeysByFilter();
    },
    regDataRange(value) {
      this.regDateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.regDateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.getKeysByFilter();
    },
  },
  computed: {
    casesList() {
      return this.casesFromApi.map((aCase) => {
        return {name: aCase.caseName, id: aCase.id};
      });
    },
    isAnyFilterApplied() {
      return this.dataRange
        || this.dateFrom
        || this.dateTo
        || this.selectedStatus
        || this.selectedCaseId;
    },
  },
  methods: {
    ...mapActions('sessions', ['setEmail']),
    checkPermission,
    getKeysByFilter() {
      this.page = 1;
      this.clientKeys = [];
      this.getKeys();
    },
    getClient() {
      this.$load(async () => {
          const res = await this.$api.profile.getProfileClient({clientId: this.clientId});
          this.client = res.data.body;
          await this.setEmail(this.client.email)
          this.getKeys();
        }, (error => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        })
      )
    },
    getKeysWithSortBy(sortByObj) {
      this.sortBy = sortByObj.sortBy;
      this.orderBy = sortByObj.order;
      this.getKeys();
    },
    getKeys() {
      this.$load(async () => {
          const res = await this.$api.management.getKeys(
            {
              page: this.page,
              size: this.size,
              keySearch: this.client.email,
              caseId: this.selectedCaseId ? this.selectedCaseId : '',
              status: this.selectedStatus,
              dateFrom: this.dateFrom,
              dateTo: this.dateTo,
              order: this.orderBy,
              sortBy: this.sortBy,
              registerDateRange: this.regDateFrom && this.regDateTo ? (this.regDateFrom + " " + this.regDateTo) : '',
            });
          this.clientKeys = res.data.body.content;
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert();
        }
      );
    },
    getCases() {
      this.loadFlag = true;
      this.$load(async () => {
        await this.$api.cases.getCases({
          caseName: '',
          page: 1,
          size: 100,
        }).then((res) => {
            this.casesFromApi = res.data.body.content
            this.loadFlag = false;
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert();
          })
        )
      })
    },
    confirmDeactivateUser() {
      this.infoDialogData = createDeactivateDialog('custodian');
      this.$refs.infoDialog.openPopup();
    },
    deactivateUser() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.deactivateUser(this.client.publicId).then(() => {
          this.$refs.infoDialog.closePopup();
          this.openDeactivatePopupUser()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    openDeactivatePopupUser() {
      this.$refs.commonDialogDeleteUser.openPopups();
    },
    resetFilters() {
      this.page = 1;
      this.dataRange = '';
      this.dateFrom = '';
      this.dateTo = '';
      this.sortBy = '';
      this.orderBy = '';
      this.selectedStatus = '';
      this.selectedCaseId = null;
      this.$refs.statusSelect.selected = null;
      this.$refs.caseSelect.value = null;
      this.getKeys();
    },
  },
  mounted() {
    document.title = 'Custodian Profile'
    this.getClient();
    this.getCases();
  }
}
</script>

<style scoped lang="scss">
.profile-section {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  padding: 32px 55px 27px 30px;

  .data-bar {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;

    .data-bar-name,
    .data-bar-info {
      display: flex;
      align-items: center;
      gap: 15px;

      .data-bar-name-img {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: initial;
        border-radius: 50%;
        overflow: hidden;

        img {
          object-fit: cover;
        }
      }

      .data-bar-name-text span {
        font-size: 26px;
        font-weight: 700;
        line-height: 39px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: var(--accent-color);
      }
    }
  }
}

h6 {
  margin-bottom: 23px;
}

.info-table {
  display: flex;
  flex-direction: column;

  .profile-cases {
    .keys-search-panel {
      display: flex;
      gap: 15px;
      height: fit-content;
      align-items: center;
      margin: 0 0 15px;

      .reset-filters {
        background-color: transparent;
        display: flex;
        align-items: center;
        border: none;
        margin-right: 23px;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        color: var(--secondary-black-color);

        &:disabled {
          cursor: not-allowed;

          path {
            fill: var(--case-btn-border-color);
          }
        }

        svg {
          margin-right: 10px;
        }

        &:not(:disabled):hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.profile-cases,
.profile-sessions {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.holder-profile-cases {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.content-wrapper {
  margin: 0 0 100px 0;
}
</style>