<template>
  <div class="holder-table">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <TableHeader v-if="isCaseChosen && sessionsData.length > 0">
      <div class="email">
        <div class="table-header-box">
          <EmailIcon />
          <span class="header-table-text">Email</span>
        </div>
      </div>

      <div class="device">
        <div
          class="table-header-box"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.4737 3.2H6.73684C6.5135 3.2 6.29931 3.28429 6.14138 3.43431C5.98346 3.58434 5.89474 3.78783 5.89474 4V14.4H0.842105C0.618765 14.4 0.404572 14.3157 0.246647 14.1657C0.0887215 14.0157 0 13.8122 0 13.6V0.8C0 0.587827 0.0887215 0.384344 0.246647 0.234315C0.404572 0.0842854 0.618765 0 0.842105 0H12.6316C12.8549 0 13.0691 0.0842854 13.227 0.234315C13.385 0.384344 13.4737 0.587827 13.4737 0.8V3.2ZM8.42105 4.8H15.1579C15.3812 4.8 15.5954 4.88429 15.7534 5.03431C15.9113 5.18434 16 5.38783 16 5.6V15.2C16 15.4122 15.9113 15.6157 15.7534 15.7657C15.5954 15.9157 15.3812 16 15.1579 16H8.42105C8.19771 16 7.98352 15.9157 7.82559 15.7657C7.66767 15.6157 7.57895 15.4122 7.57895 15.2V5.6C7.57895 5.38783 7.66767 5.18434 7.82559 5.03431C7.98352 4.88429 8.19771 4.8 8.42105 4.8Z"
              fill="#D7DCE6"/>
          </svg>
          <span class="header-table-text">Device</span>
        </div>
      </div>

      <div class="session">
        <div
          class="table-header-box"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z" fill="#D7DCE6"/>
          </svg>
          <span class="header-table-text">Session</span>
        </div>
      </div>

      <div class="started">
        <div class="table-header-box"
             id="sort_users_sessions_by_start_date"
             @click="sortedBy('started')"
             :class="{
               'sort-active': this.sortBy === 'started',
               'sorted-desc': this.sortOrder === 'desc',
             }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z" fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Started</span>
          <arrowDown class="arrow" />
        </div>
      </div>
      <div class="finished">
        <div class="table-header-box"
             id="sort_users_sessions_by_finish_date"
             @click="sortedBy('finished')"
             :class="{
               'sort-active': this.sortBy === 'finished',
               'sorted-desc': this.sortOrder === 'desc',
             }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z" fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Finished</span>
          <arrowDown class="arrow" />
        </div>
      </div>
    </TableHeader>

    <div v-if="sessionsData.length > 0" class="table-body">
      <div
        class="table-row"
        v-for="(session, sessionIndex) in updatedSessions"
        :key="sessionIndex"
      >
        <CustodianSessionItem
          :session="session"
          @activeSession="onSessionClick(session, sessionIndex)"
        />

        <transition name="slide">
          <div v-if="session.isActive && session.screenshots[0]" class="session-details-wrapper">
            <div class="session-timeline-wrapper">
              <input
                type="range"
                v-model="session.screenPage"
                min="0"
                :max="getDefaultDevice(session.screenshots[0]).length - 5"
                step="any">
              <div class="events">
                <span v-for="(item, index) in getDefaultDevice(session.screenshots[0])" :key="index" :data-event="index">
                  {{ reformatTime(item.createdAt) }}
                </span>
              </div>
            </div>
            <div class="session-screens-wrapper">
              <div class="back-button" @click="prevPage(session)" :class="{ 'disabled': Math.round(session.screenPage) === 0 }">
                <arrowDown class="arrow" />
              </div>
              <div class="holders-wrapper">
                <div v-if="session.screenshots[0].device" class="device-session-screens-holder">
                  <div v-for="(item, index) in paginatedEvents(session.screenshots[0].device, session.screenPage)" :key="index" class="screen-wrapper">
                    <div class="screen-header">
                      <div class="screen-header-text">{{ reformatTime(item.createdAt) }}</div>
                      <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="openImgPopup(session, session.screenshots[0].device, item)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 9C30 9 24.375 0 15 0C5.625 0 0 9 0 9C0 9 5.625 18 15 18C24.375 18 30 9 30 9ZM2.19937 9C3.10598 10.2025 4.14871 11.3225 5.31187 12.3431C7.725 14.4524 11.025 16.3636 15 16.3636C18.975 16.3636 22.2731 14.4524 24.69 12.3431C25.8532 11.3225 26.8959 10.2025 27.8025 9C26.8959 7.79747 25.8532 6.67749 24.69 5.65691C22.2731 3.54764 18.9731 1.63636 15 1.63636C11.025 1.63636 7.72688 3.54764 5.31 5.65691C4.14683 6.67749 3.10409 7.79747 2.1975 9H2.19937Z" fill="#8692A6"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9998 4.90931C13.7566 4.90931 12.5643 5.34032 11.6852 6.10751C10.8061 6.87471 10.3123 7.91525 10.3123 9.00022C10.3123 10.0852 10.8061 11.1257 11.6852 11.8929C12.5643 12.6601 13.7566 13.0911 14.9998 13.0911C16.243 13.0911 17.4352 12.6601 18.3143 11.8929C19.1934 11.1257 19.6873 10.0852 19.6873 9.00022C19.6873 7.91525 19.1934 6.87471 18.3143 6.10751C17.4352 5.34032 16.243 4.90931 14.9998 4.90931ZM8.43726 9.00022C8.43726 7.48126 9.12866 6.0245 10.3594 4.95043C11.5901 3.87636 13.2593 3.27295 14.9998 3.27295C16.7402 3.27295 18.4094 3.87636 19.6401 4.95043C20.8709 6.0245 21.5623 7.48126 21.5623 9.00022C21.5623 10.5192 20.8709 11.9759 19.6401 13.05C18.4094 14.1241 16.7402 14.7275 14.9998 14.7275C13.2593 14.7275 11.5901 14.1241 10.3594 13.05C9.12866 11.9759 8.43726 10.5192 8.43726 9.00022Z" fill="#8692A6"/>
                      </svg>
                    </div>
                    <div class="screen-holder">
                      <div class="screen-cover" @click="openImgPopup(session, session.screenshots[0].device, item)">
                        <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M50 15C50 15 40.625 0 25 0C9.375 0 0 15 0 15C0 15 9.375 30 25 30C40.625 30 50 15 50 15ZM3.66562 15C5.17663 17.0042 6.91452 18.8708 8.85312 20.5718C12.875 24.0873 18.375 27.2727 25 27.2727C31.625 27.2727 37.1219 24.0873 41.15 20.5718C43.0886 18.8708 44.8265 17.0042 46.3375 15C44.8265 12.9958 43.0886 11.1291 41.15 9.42818C37.1219 5.91273 31.6219 2.72727 25 2.72727C18.375 2.72727 12.8781 5.91273 8.85 9.42818C6.91138 11.1291 5.17348 12.9958 3.6625 15H3.66562Z" fill="#EBEFF7"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9998 8.18235C22.9278 8.18235 20.9406 8.90069 19.4755 10.1793C18.0104 11.458 17.1873 13.1922 17.1873 15.0005C17.1873 16.8088 18.0104 18.5431 19.4755 19.8217C20.9406 21.1004 22.9278 21.8187 24.9998 21.8187C27.0718 21.8187 29.0589 21.1004 30.524 19.8217C31.9892 18.5431 32.8123 16.8088 32.8123 15.0005C32.8123 13.1922 31.9892 11.458 30.524 10.1793C29.0589 8.90069 27.0718 8.18235 24.9998 8.18235ZM14.0623 15.0005C14.0623 12.4689 15.2146 10.041 17.2658 8.25088C19.317 6.46076 22.099 5.45508 24.9998 5.45508C27.9006 5.45508 30.6826 6.46076 32.7337 8.25088C34.7849 10.041 35.9373 12.4689 35.9373 15.0005C35.9373 17.5321 34.7849 19.9601 32.7337 21.7502C30.6826 23.5403 27.9006 24.546 24.9998 24.546C22.099 24.546 19.317 23.5403 17.2658 21.7502C15.2146 19.9601 14.0623 17.5321 14.0623 15.0005Z" fill="#EBEFF7"/>
                        </svg>
                      </div>
                      <img :src="item.linkPreview" alt="screen" >
                    </div>
                  </div>
                </div>
                <div v-if="session.screenshots[0].desktop" class="desktop-session-screens-holder">
                  <div v-for="(item, index) in paginatedEvents(session.screenshots[0].desktop, session.screenPage)" :key="index" class="screen-wrapper">
                    <div class="screen-header">
                      <div class="screen-header-text">{{ reformatTime(item.createdAt) }}</div>
                      <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="openImgPopup(session, session.screenshots[0].desktop, item)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 9C30 9 24.375 0 15 0C5.625 0 0 9 0 9C0 9 5.625 18 15 18C24.375 18 30 9 30 9ZM2.19937 9C3.10598 10.2025 4.14871 11.3225 5.31187 12.3431C7.725 14.4524 11.025 16.3636 15 16.3636C18.975 16.3636 22.2731 14.4524 24.69 12.3431C25.8532 11.3225 26.8959 10.2025 27.8025 9C26.8959 7.79747 25.8532 6.67749 24.69 5.65691C22.2731 3.54764 18.9731 1.63636 15 1.63636C11.025 1.63636 7.72688 3.54764 5.31 5.65691C4.14683 6.67749 3.10409 7.79747 2.1975 9H2.19937Z" fill="#8692A6"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9998 4.90931C13.7566 4.90931 12.5643 5.34032 11.6852 6.10751C10.8061 6.87471 10.3123 7.91525 10.3123 9.00022C10.3123 10.0852 10.8061 11.1257 11.6852 11.8929C12.5643 12.6601 13.7566 13.0911 14.9998 13.0911C16.243 13.0911 17.4352 12.6601 18.3143 11.8929C19.1934 11.1257 19.6873 10.0852 19.6873 9.00022C19.6873 7.91525 19.1934 6.87471 18.3143 6.10751C17.4352 5.34032 16.243 4.90931 14.9998 4.90931ZM8.43726 9.00022C8.43726 7.48126 9.12866 6.0245 10.3594 4.95043C11.5901 3.87636 13.2593 3.27295 14.9998 3.27295C16.7402 3.27295 18.4094 3.87636 19.6401 4.95043C20.8709 6.0245 21.5623 7.48126 21.5623 9.00022C21.5623 10.5192 20.8709 11.9759 19.6401 13.05C18.4094 14.1241 16.7402 14.7275 14.9998 14.7275C13.2593 14.7275 11.5901 14.1241 10.3594 13.05C9.12866 11.9759 8.43726 10.5192 8.43726 9.00022Z" fill="#8692A6"/>
                      </svg>
                    </div>
                    <div class="screen-holder">
                      <div class="screen-cover" @click="openImgPopup(session, session.screenshots[0].desktop, item)">
                        <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M50 15C50 15 40.625 0 25 0C9.375 0 0 15 0 15C0 15 9.375 30 25 30C40.625 30 50 15 50 15ZM3.66562 15C5.17663 17.0042 6.91452 18.8708 8.85312 20.5718C12.875 24.0873 18.375 27.2727 25 27.2727C31.625 27.2727 37.1219 24.0873 41.15 20.5718C43.0886 18.8708 44.8265 17.0042 46.3375 15C44.8265 12.9958 43.0886 11.1291 41.15 9.42818C37.1219 5.91273 31.6219 2.72727 25 2.72727C18.375 2.72727 12.8781 5.91273 8.85 9.42818C6.91138 11.1291 5.17348 12.9958 3.6625 15H3.66562Z" fill="#EBEFF7"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9998 8.18235C22.9278 8.18235 20.9406 8.90069 19.4755 10.1793C18.0104 11.458 17.1873 13.1922 17.1873 15.0005C17.1873 16.8088 18.0104 18.5431 19.4755 19.8217C20.9406 21.1004 22.9278 21.8187 24.9998 21.8187C27.0718 21.8187 29.0589 21.1004 30.524 19.8217C31.9892 18.5431 32.8123 16.8088 32.8123 15.0005C32.8123 13.1922 31.9892 11.458 30.524 10.1793C29.0589 8.90069 27.0718 8.18235 24.9998 8.18235ZM14.0623 15.0005C14.0623 12.4689 15.2146 10.041 17.2658 8.25088C19.317 6.46076 22.099 5.45508 24.9998 5.45508C27.9006 5.45508 30.6826 6.46076 32.7337 8.25088C34.7849 10.041 35.9373 12.4689 35.9373 15.0005C35.9373 17.5321 34.7849 19.9601 32.7337 21.7502C30.6826 23.5403 27.9006 24.546 24.9998 24.546C22.099 24.546 19.317 23.5403 17.2658 21.7502C15.2146 19.9601 14.0623 17.5321 14.0623 15.0005Z" fill="#EBEFF7"/>
                        </svg>
                      </div>
                      <img :src="item.linkPreview" alt="screen" >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="forward-button"
                @click="nextPage(session)"
                :class="{
                  'disabled': Math.round(session.screenPage) >= getDefaultDevice(session.screenshots[0]).length - 5
                }"
              >
                <arrowDown class="arrow" />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="search-cases" v-else-if="isCaseChosen && sessionsData.length === 0">
      <h3 class="no-found">No sessions found</h3>
    </div>
    <div class="search-cases" v-else>
      <h3>Start by choosing a case</h3>
    </div>
    <screenshot-popup ref="imagePopup">
      <div class="popup-wrapper" v-if="currentSession.currentScreenshot">
        <span>{{ reformatTime(currentSession.currentScreenshot.createdAt) }}</span>
        <div class="screen-section">
          <div class="back-button" @click="popupPrevPage()" :class="{ 'disabled': currentSession.screenPage === 0 }">
            <arrowDown class="arrow" />
          </div>
          <img :src="currentSession.currentScreenshot.link" alt="screenshot">
          <div class="forward-button" @click="popupNextPage()" :class="{ 'disabled': currentSession.screenPage >= currentSession.screenshots.length - 1 }">
            <arrowDown class="arrow" />
          </div>
        </div>
        <div class="popup-session-info">
          <div class="email">
            <span>Email</span>
            <div>{{ currentSession.email }}</div>
          </div>
          <div class="device">
            <span>Device</span>
            <div>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#F8FAFC"/>
                <path d="M19.3654 8H12.6331C11.9893 8 11.4691 8.52214 11.4691 9.16598V22.8355C11.4691 23.4774 11.9893 24 12.6331 24H19.3654C20.0082 24 20.5303 23.4779 20.5303 22.8355V9.16598C20.5298 8.52214 20.0082 8 19.3654 8ZM19.6572 20.9636H12.3422V9.96686H19.6572V20.9636ZM17.5601 9.18315H14.4389V8.8428H17.5606L17.5601 9.18315ZM18.8958 9.02762C18.8958 9.19224 18.7614 9.32555 18.5973 9.32555C18.4327 9.32555 18.2989 9.19173 18.2989 9.02762C18.2989 8.8635 18.4327 8.72968 18.5973 8.72968C18.7619 8.72918 18.8958 8.863 18.8958 9.02762ZM17.4202 22.7578H14.5777V21.8832H17.4202V22.7578Z" fill="#082655"/>
              </svg>
              {{ currentSession.device }}
            </div>
          </div>
          <div class="session">
            <span>Session</span>
            <div>#{{ currentSession.sessionId }}</div>
          </div>
        </div>
      </div>
    </screenshot-popup>
  </div>
</template>

<script>
import TableHeader from "../table/TableHeader";
import AlertErrorMsg from "../profile/AlertErrorMsg";
import ArrowDown from "../icons/ArrowDown";
import EmailIcon from "../icons/EmailIcon";
import ScreenshotPopup from "@/components/popups/ScreenshotPopup.vue";
import reformatDate from "@/mixins/reformatDate";
import reformatDateToTime from "@/mixins/reformatDateToTime";
import CustodianSessionItem from "@/components/support/CustodianSessionItem.vue";

export default {
  name: "CustodianSessionsList",
  components: {
    CustodianSessionItem,
    ScreenshotPopup,
    EmailIcon,
    ArrowDown,
    TableHeader,
    AlertErrorMsg,
  },
  emits: ["sorting"],
  props: {
    sessionsData: {
      type: Array,
      required: true,
    },
    isCaseChosen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      sortBy: 'email',
      sortOrder: 'asc',
      loadFlag: false,
      errorMessage: '',
      updatedSessions: [],
      currentSession: {
        currentScreenshot: null,
        email: "",
        device: "",
        sessionId: "",
        screenPage: 0,
      },
    }
  },
  watch: {
    sessionsData: {
      immediate: true,
      deep: true,
      handler(newSessionsData) {
        if (newSessionsData) {
          this.updatedSessions = newSessionsData.map(session => ({
            ...session,
            isActive: false,
            screenPage: 0
          }));
        }
      }
    }
  },
  mixins: [reformatDate, reformatDateToTime],
  methods: {
    openImgPopup(session, sessionScreenshots, screenshot) {
      this.currentSession = {
        ...session,
        screenshots: sessionScreenshots,
        screenPage: sessionScreenshots.indexOf(screenshot),
        currentScreenshot: screenshot,
      };
      this.$refs.imagePopup.openPopups();
    },
    paginatedEvents(sessionScreenshots, sessionScreenPage) {
      if (sessionScreenshots.length <= 5) {
        return sessionScreenshots;
      }
      return sessionScreenshots.slice(Math.round(sessionScreenPage), Math.round(sessionScreenPage) + 5);
    },
    sortedBy(filterName) {
      this.sortOrder = filterName === this.sortBy && this.sortOrder === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.$emit("sorting", { sortOrder: this.sortOrder, sortBy: this.sortBy });
    },
    nextPage(session) {
      if (session.screenPage >= this.getDefaultDevice(session.screenshots[0]).length - 6
        && session.screenPage <= this.getDefaultDevice(session.screenshots[0]).length - 5) {
        session.screenPage = this.getDefaultDevice(session.screenshots[0]).length - 5;
      } else {
        session.screenPage++;
      }
    },
    prevPage(session) {
      if (session.screenPage >= 0 && session.screenPage < 1) {
        session.screenPage = 0;
      } else {
        session.screenPage--;
      }
    },
    popupNextPage() {
      if (this.currentSession.screenPage < this.currentSession.screenshots.length - 1) {
        this.currentSession.screenPage++;
        this.currentSession.currentScreenshot = this.currentSession.screenshots[this.currentSession.screenPage];
      }
    },
    popupPrevPage() {
      if (this.currentSession.screenPage > 0) {
        this.currentSession.screenPage--;
        this.currentSession.currentScreenshot = this.currentSession.screenshots[this.currentSession.screenPage];
      }
    },
    onSessionClick(currentSession, index) {
      const updatedSession = {
        ...currentSession,
        screenshots: this.getSessionScreenshots(currentSession.caseId, currentSession.sessionId),
        isActive: this.updatedSessions[index] ? !this.updatedSessions[index].isActive : true
      };
      this.$set(this.updatedSessions, index, updatedSession);
    },
    getSessionScreenshots(caseId, sessionId) {
      const screenshots = [];
      this.$load(async () => {
        try {
          const res = await this.$api.screenshots.getScreenshots(caseId, sessionId);
          screenshots.push(res.data.body);
        } catch (error) {
          this.errorMessage = error.response ? error.response.data.errors.message[0] : error.data.errors.message[0];
          return [];
        }
      });
      return screenshots;
    },
    getDefaultDevice(sessionScreenshots) {
      if (sessionScreenshots.device && sessionScreenshots.desktop) {
        if (sessionScreenshots.device.length > sessionScreenshots.desktop.length) {
          return sessionScreenshots.device;
        } else {
          return sessionScreenshots.desktop;
        }
      }
      return sessionScreenshots.device
        ? sessionScreenshots.device
        : sessionScreenshots.desktop
        ? sessionScreenshots.desktop
        : [];
    },
  },
}
</script>

<style lang="scss" scoped>

.holder-table {
  margin: 15px 0 0;
}

.table-header {
  max-height: 64px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 22px;
}

.table-header-box svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-header-box:hover svg:first-child path {
  fill: var(--accent-color);
}

.email {
  width: 18%;
}

.device {
  width: 25%;
}

.session {
  width: 15%;
}

.started {
  width: 18%;
}

.finished {
  width: 20%;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0;
  display: block;
  width: 100%;
}
.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.arrow {
  transition: transform 0.3s;
}

.session-details-wrapper {
  height: fit-content;
}

.session-timeline-wrapper {
  height: 45px;
  margin: 32px 30px 15px 45px;
}

input[type="range"] {
  width: 100%;
  appearance: none;
  border: none;
  height: 25px;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: var(--case-btn-border-color);
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--case-card-hover-bg);
  border-radius: 50%;
  cursor: pointer;
  margin: -6px 0 0;
  position: relative;
  z-index: 2;
  border: 3px solid var(--light-gray);
}

.events {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.events span {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--secondary-black-color);
}

.events span::before {
  content: "";
  width: 3px;
  height: 13px;
  background-color: var(--light-gray);
  position: absolute;
  transform: translate(0, -175%);
}

.session-screens-wrapper {
  display: flex;
  height: fit-content;
  margin-bottom: 15px;
}

.holders-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.back-button,
.forward-button {
  width: 37px;
  height: 81px;
  padding: 30px 8px;
  border-radius: 8px;
  border: 1px solid #EBEFF6;
  cursor: pointer;
}

.back-button .arrow {
  width: 21px;
  height: 21px;
  transform: rotate(90deg);
}

.forward-button .arrow {
  width: 21px;
  height: 21px;
  transform: rotate(-90deg);
}

.device-session-screens-holder {
  display: flex;
  height: 420px;
  border-radius: 8px;
  border: 1px solid #EBEFF6;
  margin: 0 8px;
}

.desktop-session-screens-holder {
  display: flex;
  height: 180px;
  border-radius: 8px;
  border: 1px solid #EBEFF6;
  margin: 0 8px;
}

.screen-wrapper {
  display: flex;
  flex-direction: column;
  width: 20%;
  gap: 16px;
  padding: 24px 40px;
  border-right: 1px solid #EBEFF6;
}

.screen-wrapper:last-child {
  border-right: none;
}

.screen-header {
  display: flex;
  justify-content: space-between;
}

.screen-header-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: var(--secondary-black-color);
}

.screen-header svg {
  cursor: pointer;
}

.screen-holder {
  border-radius: 8px;
  cursor: pointer;
  width: 178px;
  height: auto;
  overflow: hidden;
}

.screen-holder img {
  max-width: 100%;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.device-session-screens-holder .screen-holder {
  height: 330px;
}

.desktop-session-screens-holder .screen-holder {
  height: 90px;
}

.screen-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  width: inherit;
  height: inherit;
  transition: all 0.7s ease;
  border-radius: 8px;
  opacity: 0;
}

.screen-cover:hover {
  opacity: 100;
  background: #00000080 50%;
}

.disabled {
  opacity: 0.2;
}

.popup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1100px;
  height: 900px;
  margin: 20px 0 0;
  gap: 15px;
}

.popup-wrapper span {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
  color: var(--secondary-black-color);
}

.screen-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 45px;
  width: 1000px;
  height: 600px;
}

.screen-section img {
  max-width: 800px;
}

.popup-session-info {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  width: 100%;
  max-height: 70px;
  height: 100%;
  padding: 15px 0 0;
  border-top: 1px solid var(--case-btn-border-color);
}

.popup-session-info .email,
.popup-session-info .device,
.popup-session-info .session {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
}

.popup-session-info .email span,
.popup-session-info .device span,
.popup-session-info .session span {
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: var(--secondary-black-color);
}

.popup-session-info .email div,
.popup-session-info .device div,
.popup-session-info .session div {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: var(--secondary-text-color);
  gap: 8px;
}

.search-cases {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.search-cases h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: var(--light-gray);
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter, .slide-leave-to {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

</style>
