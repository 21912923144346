<template>
  <div class="holder-table">
    <TableHeader v-if="keysData.length">

      <div class="case">
        <div
          :id="`sort_keys_by_case_id`"
          @click="sortedBy('caseId')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'caseId',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z"
              fill="#D7DCE6" />
          </svg>
          <span class="header-table-text">Case</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div class="keyid">
        <div
          :id="`sort_keys_by_keyid`"
          @click="sortedBy('keyId')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'keyId',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Key id</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div class="registered">
        <div
          :id="`sort_keys_by_register_date`"
          @click="sortedBy('registerDate')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'registerDate',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Registration date</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div class="usage">
        <div
          :id="`sort_keys_by_usage_period`"
          @click="sortedBy('usagePeriod')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'usagePeriod',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Usage period</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div class="days-left">
        <div
          :id="`sort_keys_by_days_left`"
          @click="sortedBy('daysLeft')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'daysLeft',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Days left</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div class="status">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 16C6.55208 16 5.21354 15.6432 3.98438 14.9297C2.75521 14.2161 1.78385 13.2448 1.07031 12.0156C0.356771 10.7865 0 9.44792 0 8C0 6.55208 0.356771 5.21354 1.07031 3.98438C1.78385 2.75521 2.75521 1.78385 3.98438 1.07031C5.21354 0.356771 6.55208 0 8 0C9.44792 0 10.7865 0.356771 12.0156 1.07031C13.2448 1.78385 14.2161 2.75521 14.9297 3.98438C15.6432 5.21354 16 6.55208 16 8C16 9.44792 15.6432 10.7865 14.9297 12.0156C14.2161 13.2448 13.2448 14.2161 12.0156 14.9297C10.7865 15.6432 9.44792 16 8 16ZM1 8C1 8.94792 1.17969 9.84896 1.53906 10.7031C1.89844 11.5573 2.40104 12.3073 3.04688 12.9531L8 8V1C7.05208 1 6.14583 1.1849 5.28125 1.55469C4.41667 1.92448 3.67188 2.42188 3.04688 3.04688C2.42188 3.67188 1.92448 4.41667 1.55469 5.28125C1.1849 6.14583 1 7.05208 1 8Z"
              fill="#D7DCE6" />
          </svg>
          <span class="header-table-text">Status</span>
        </div>
      </div>
    </TableHeader>

    <div v-if="keysData.length > 0" class="table-body">
      <ClientKeyItem
        v-for="(key, index) in keysData"
        :key="index"
        :keyItem="key"
        @openKeysPopup="openKeyPopup"
      />
    </div>

    <div class="search-cases" v-else>
      <h3>No keys found</h3>
    </div>
    <key-management-popup
      ref="keyManagementPopup"
      :keysData="[keyItem]"
      :action="popupAction"
      @keyAction="performKeyAction"
      @closed="keyItem = null"
    />
    <CommonDialog ref="successPopup">
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655" />
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description">The key was sent to custodian email</p>
    </CommonDialog>
  </div>
</template>

<script>
import TableHeader from "@/components/table/TableHeader";
import ArrowDown from "@/components/icons/ArrowDown.vue";
import {ASC, DESC, KEY_ACTION} from "@/constants/const";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import ClientKeyItem from "@/pages/client-profile/ClientKeyItem.vue";
import KeyManagementPopup from "@/components/popups/KeyManagementPopup.vue";

export default {
  name: "ClientKeysList",
  components: {
    KeyManagementPopup,
    ClientKeyItem,
    CommonDialog,
    ArrowDown,
    TableHeader,
  },
  emits: ['sortBy'],
  props: {
    keysData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      KEY_ACTION,
      DESC,
      ASC,
      popupAction: KEY_ACTION.resendKey,
      sortBy: '',
      order: DESC,
      keyItem: null,
    };
  },
  methods: {
    performKeyAction(key, action) {
      if (action === KEY_ACTION.resendKey) {
        this.sendKey(key);
      } else if (action === KEY_ACTION.deactivateKey) {
        this.deactivateKey(key);
      }
    },
    openKeyPopup(action, key) {
      this.popupAction = action;
      this.keyItem = key;
      if (action === KEY_ACTION.generateKey) {
        this.generateNewKey(key.id);
      }
      this.$refs.keyManagementPopup.openPopups();
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === DESC ? ASC : DESC;
      this.sortBy = filterName
      this.$emit('sortBy', {sortBy: this.sortBy, order: this.order});
    },
    sendKey(keysIds) {
      this.$load(async () => {
        await this.$api.management.sendKey({keysIds: keysIds})
        this.$refs.successPopup.openPopups();
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        }
      );
    },
    generateNewKey(keyId) {
      this.$load(async () => {
        const res = await this.$api.management.regenerateKey({keysIds: [keyId]})
        this.keyItem.apiKey = res.data.body[0].key;
        this.$emit('sortBy');
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
      });
    },
    deactivateKey(keys) {
      this.$load(async () => {
        await this.$api.management.deactivateKey({keysIds: keys});
        this.$refs.successPopup.openPopups();
        this.$emit('sortBy');
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>

.holder-table {
  margin: 15px 0 0;
}

.table-body {
  min-height: fit-content;
}

.table-header {
  max-height: 64px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 15px;
}

.table-header-box {
  cursor: default;
}

.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box .header-table-text {
  margin: 0 5px 0 0;
  width: fit-content;
}

.table-header-box:hover .header-table-text {
  text-decoration: none;
}

.case {
  width: 15%;
}

.keyid {
  width: 15%;
}

.registered {
  width: 15%;
}

.usage {
  width: 20%;
}

.days-left {
  width: 15%;
}

.status {
  width: 15%;
}

.context-menu {
  width: 3%;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0;
  display: block;
  width: 100%;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.arrow {
  transition: transform 0.3s;
}

.search-cases {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-cases h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: var(--light-gray);
}

.checkbox-wrap .checkbox-unselect {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: initial;
  cursor: pointer;
  padding: 2px 0 0;
}

.holder-btn {
  display: flex;
  align-items: center;
  gap: 15px;

  .btn {
    display: flex;
    align-items: center;
    gap: 5px;

    .btn-span {
      margin-right: 12px;
      font-size: 14px;
      text-transform: none;
      color: var(--secondary-text-color);
    }
  }

  .btn-deactivate {
    &:hover svg path {
      fill: var(--error-color);
    }
  }

  .btn-generate,
  .btn-resend {
    &:hover svg path {
      fill: var(--accent-color);
    }
  }
}
</style>
