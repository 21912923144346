var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"holder-table"},[_c('AlertErrorMsg',{ref:"alert",staticClass:"page-alert-error",attrs:{"title":_vm.errorMessage}}),_c('TableHeader',[_c('div',{staticClass:"full-name"},[_c('div',{staticClass:"table-header-box",class:{
               'sort-active': this.sortBy === 'name',
               'sorted-desc': this.sortOrder === _vm.ASC
             },attrs:{"id":"sort_contacts_by_name"},on:{"click":function($event){return _vm.sortedBy('name')}}},[_c('UserIcon'),_c('span',{staticClass:"header-table-text"},[_vm._v("Full Name")]),_c('arrowDown',{staticClass:"arrow"})],1)]),_c('div',{staticClass:"phone"},[_c('div',{staticClass:"table-header-box",class:{
               'sort-active': this.sortBy === 'email',
               'sorted-desc': this.sortOrder === _vm.ASC
             },attrs:{"id":"sort_contacts_by_email"},on:{"click":function($event){return _vm.sortedBy('email')}}},[_c('EmailIcon'),_c('span',{staticClass:"header-table-text"},[_vm._v("Email")]),_c('arrowDown',{staticClass:"arrow"})],1)]),_c('div',{staticStyle:{"width":"5%"}})]),_c('div',{staticClass:"table-body"},_vm._l((_vm.localContactsList),function(item,index){return _c('div',{key:index,staticClass:"table-row"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.onContactClick(item, index)}}},[_c('div',{staticClass:"full-name"},[_c('div',{staticClass:"holder-info"},[_c('span',[_vm._v(_vm._s(item.fullName))])])]),_c('div',{staticClass:"phone"},[_c('div',{staticClass:"holder-info"},[_c('span',[_vm._v(_vm._s(item.email))])])]),_c('div',{staticClass:"card-arrow"},[_c('div',{staticClass:"holder-info"},[_c('arrowDown',{staticClass:"arrow",class:{ 'active-arrow': item.isActive }})],1)])]),_c('transition',{on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},[(item.isActive)?_c('div',{staticClass:"contact-details-wrapper"},[_c('div',{staticClass:"phones"},[_c('span',[_vm._v("Phone numbers")]),_vm._l((item.phones),function(phone,index){return _c('div',{key:index},[_vm._v(_vm._s(phone ? phone : "No phone number"))])})],2),_c('div',{staticClass:"field1"},[_c('span',[_vm._v("field 1")]),_c('span',[_vm._v(_vm._s(item.field1 ? item.field1 : '-'))])]),_c('div',{staticClass:"field2"},[_c('span',[_vm._v("field 2")]),_c('span',[_vm._v(_vm._s(item.field2 ? item.field2 : '-'))])]),_c('div',{staticClass:"field3"},[_c('span',[_vm._v("field 3")]),_c('span',[_vm._v(_vm._s(item.field3 ? item.field3 : '-'))])])]):_vm._e()])],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }