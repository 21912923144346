export const KEYS_MENU = {
    genKey: 'Generate Key',
    viewProfile: 'View Profile',
    deactivateKey: 'Deactivate Key',
    resendKey: 'Resend Key',
}

export const ASC = 'asc';
export const DESC = 'desc';

export const KEY_STATUS = {
    active: 'active',
    expired: 'expired',
    pending: 'pending',
    deactivated: 'deactivated',
}

export const KEY_STATUS_SELECT = [
    {name: 'Active', id: 1,},
    {name: 'Pending', id: 2,},
    {name: 'Expired', id: 3,},
    {name: 'Deactivated', id: 4,},
];

export const KEY_ACTION = {
    generateKey: 'Generate Key',
    resendKey: 'Resend Key',
    deactivateKey: 'Deactivate Key',
}

export const COMMON_ERROR = {
    noSuchOption: 'No such option',
}

export const CASE_CONFIG_ENUM = {
    screenshots: "CASE_SCREENSHOT_ENABLED",
    speech: "CASE_SPEECH_TO_TEXT_ENABLED",
    prefiltering: "CASE_CUSTODIAN_PREFILTERING",
    dateFrom: "CASE_DATE_FROM",
    dateTo: "CASE_DATE_TO",
    appList: "CASE_APP_LIST",
}

export const DEVICE_TYPES_SELECT = [
    {name: 'iOs', id: 1,},
    {name: 'Android', id: 2,},
]

export const APP_LIST_MULTISELECT = [
    {caseName: "SMS"},
    {caseName: "WhatsApp"},
    {caseName: "Viber"},
    {caseName: "WeChat"},
    {caseName: "AppList"},
    {caseName: "Contacts"},
    {caseName: "CallLogs"},
    {caseName: "iMessage"}
]

export const CASE_EXPORT_TABS = {
    messages: 'messages',
    email: 'emails',
    media: 'media',
    callLogs: 'calls',
    contacts: 'contacts',
    apps: 'apps',
}

export const NOTIFICATION_TABS = {
    files: {
        name: 'Files',
        path: '/notifications/files',
        placeholder: 'Search by file name'
    },
    aCase: {
        name: 'Case',
        path: '/notifications/cases',
        placeholder: 'Search by case name'
    },
    custodians: {
        name: 'Custodians',
        path: '/notifications/custodians',
        placeholder: 'Search by email'
    },
    team: {
        name: 'Team',
        path: '/notifications/team',
        placeholder: 'Search by contact name'
    },
    permission: {
        name: 'Permission',
        path: '/notifications/permission',
        placeholder: 'Search by contact name'
    },
    billing: {
        name: 'Billing',
        path: '/notifications/billing',
        placeholder: 'Search by name'
    },
    dataSources: {
        name: 'Data sources',
        path: '/notifications/sources',
        placeholder: 'Search by contact email'
    }
}

export const CASE_EXPORT_FORMATS = {
    pdf: 'pdf',
    xml: 'xml',
    csv: 'csv',
    rsmf: 'rsmf',
}

export const ROLES = [
    {name:'Reviewer'},
    {name:'Review admin'},
    {name:'Case administrator'},
    {name:'System administrator'},
];

export const DEFAULT_CLIENT_UUID = "00000000-0000-0000-0000-000000000000";