export default function(instance) {
    return {
        getContactClients({sortBy, order, page, size, contactName}) {
            return instance.get(`/contact/clients?sortBy=${sortBy}&order=${order}&page=${page}&size=${size}&keySearch=${contactName}`,)
        },
        getContactEmployees({sortBy, order, page, size, contactName, caseId, role}) {
            return instance.get(`/contact/employees?sortBy=${sortBy}&order=${order}&page=${page}&size=${size}&keySearch=${contactName}&caseId=${caseId}&role=${role}`,)
        },
        deactivateUser(userPublicIds) {
            return instance.post(`/contact/deactivate?userPublicIds=${userPublicIds}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        activateUser(userPublicIds) {
            return instance.post(`/contact/activate?userPublicIds=${userPublicIds}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
    }
}