export default function(instance) {
  return {
    getCaseConfig(caseId) {
      return instance.get(`/caseConfig/case/${caseId}/config`)
    },
    getClientsConfig(caseId, keySearch, sortBy, sortOrder) {
      return instance.get(`/caseConfig/case/${caseId}/config/clients?keySearch=${keySearch}&sortBy=${sortBy}&order=${sortOrder}`)
    },
    updateCaseConfig(caseId, data) {
      return instance.post(`/caseConfig/case/${caseId}/config`, data)
    },
    deleteCaseConfig(caseId, data) {
      return instance.post(`/caseConfig/case/${caseId}/delete`, data)
    },
  }
}