<template>
  <div class="holder-profile-form">
    <span class="otp-subtitle">New email address:</span>
    <input
      id="case-name"
      type="text"
      class="email-input"
      placeholder="Enter new email"
      v-model.trim="email"
      style="width: 400px"
      @input="errorMessage = ''"
    >
    <div v-if="errorMessage" class="phone-error" style="color:red;">{{ errorMessage }}</div>
    <button
      id="button_next"
      class="btn dialog-btn"
      @click="openConfirmationPopup">
      <span class="dialog-btn-text">Next</span>
    </button>
    <CommonDialog
      ref="confirmationPopup"
      :show-footer="false"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Change Email address</h2>
      <p class="dialog-message-description" style="display:block;">We have sent the secret code to email address:</p>
      <p class="dialog-message-phone" style="display:block;">{{ email }}</p>
      <p class="dialog-message-description" style="display:block; margin-bottom: 32px">to make sure it’s you. Check your email inbox</p>
      <ChangePhoneOtpCode
        ref="otpInput"
        :otpVerify="otpVerify"
        @update:otpValue="submit"
        :remainingTime="remainingTime"
        @resendCode="resendCode"
      />
    </CommonDialog>
    <CommonDialog
      ref="commonDialogFormSuccess"
      :show-footer="false"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
            fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">Email was changed successfully.<br>
        You will be automatically logged-out in 10 seconds for security reasons.</p>
    </CommonDialog>
  </div>
</template>

<script>
import CommonDialog from "../popups/CommonDialog";
import ChangePhoneOtpCode from "@/components/profile/ProfileChangeOtpCode.vue";
import { email, required } from "vuelidate/lib/validators";
import LocalStorageService from "@/services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

export default {
  components: {
    ChangePhoneOtpCode,
    CommonDialog,
  },
  data() {
    return {
      email: '',
      errorMessage: '',
      otpVerify: null,
      remainingTime: 0,
      timerInterval: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    }
  },
  methods: {
    resendCode() {
      this.$load(async () => {
        await this.$api.profile.updateEmailStart({
          email: this.email
        }).then(() => {
          this.startTimer();
        })
      })
    },
    openConfirmationPopup() {
      if (this.remainingTime <= 0) {
        this.$v.$touch()
        this.$load(async () => {
          await this.$api.profile.updateEmailStart({
            email: this.email
          }).then(() => {
            this.$refs.confirmationPopup.openPopups();
            this.startTimer();
          })
        }, (error) => {
          if (error.data.errors.code[0] === 'PIN_CODE_ERROR') {
            this.$refs.confirmationPopup.openPopups();
          } else if (error.data.errors.email) {
            this.errorMessage = error.data.errors.email[0]
          } else if (error.data.errors.message) {
            this.errorMessage = error.data.errors.message[0]
          }
        });
      } else {
        this.$refs.confirmationPopup.openPopups();
      }
    },
    openDialogFormSuccess() {
      this.$refs.commonDialogFormSuccess.openPopups();
    },
    submit(value) {
      this.$load(async () => {
          await this.$api.profile.updateEmailSecondStep({
            code: value,
            email: this.email
          }).then(() => {
            this.$refs.confirmationPopup.closePopups();
            this.openDialogFormSuccess()
            localStorageService.clearAllToLocalStorageData()
            this.$store.commit('authentication/logout')
            setTimeout(() => {
              this.$router.push({name: 'LoginInAccount'})
            }, 10000)
          })
        }, () => {
          this.otpVerify = false;
        }
      )
    },
    startTimer() {
      this.remainingTime = 30;

      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }

      this.timerInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          this.otpVerify = null;
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
  },
}
</script>

<style scoped>
.holder-profile-form {
  max-width: 400px;
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary-text-color);
  padding-top: 30px;
}

.holder-profile-form .otp-subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: var(--secondary-black-color);
}

.email-input {
  max-width: 400px;
  width: 100%;
  height: 48px;
  border-radius: 25px;
  border: 1px solid var(--case-btn-border-color);
  padding: 23px 30px;
}

.btn {
  margin-top: 40px;
  max-width: 400px;
  width: 100%;
  max-height: 50px;
  height: 100%;
  padding: 15px;
  border-radius: 25px;
}

.btn span {
  color: var(--white-color);
}

.btn:hover span {
  color: var(--accent-color);
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 16px 0;
  display: block;
  width: 100%;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 26px;
  text-align: center;
  color: #696F79 !important;
  display: block;
  width: 100%;
}

.dialog-message-phone {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  color: var(--secondary-black-color);
}
</style>