<template>
  <form action="" @submit.prevent="submit">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="form-header">
      <div class="form-group" :class="{ 'form-group--error': $v.formData.caseName.$error }">
        <label for="case-name">Case Name</label>
        <div class="holder-input">
          <input
              id="case-name"
              type="text"
              class="form-control"
              :class="false ? 'is-invalid' : ''"
              placeholder="Enter Case Name"
              v-model.trim="$v.formData.caseName.$model"
          >
        </div>
        <div class="error" v-if="!$v.formData.caseName.required">Field is required</div>
      </div>
      <div class="form-group">
        <label for="case-name">Region</label>
        <div class="holder-input">
          <CustomSelect
              :default="defaultValue"
              :typeSelect="'filterMessengers'"
              v-model="selectCaseRegion"
              :custom-class="'select-databases top'"
              :options="caseRegion" />
        </div>
      </div>
    </div>
    <div class="form-content">
      <h6>Send invites</h6>
      <div class="holder-table">
          <table v-if="formData.caseInvites.length > 0" cellpadding="0" cellspacing="0" border="0">
            <TableHeader>
              <tr>
              <th>
                <div class="table-header-box">
                  <UserIcon />
                  <span class="header-table-text">Email</span>
                </div>
              </th>
              <th>
                <div class="table-header-box">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.48024 0H0.520239C0.245378 0 0.0224609 0.222917 0.0224609 0.497778V15.5022C0.0224609 15.7771 0.245378 16 0.520239 16H9.48024C9.7551 16 9.97802 15.7771 9.97802 15.5022V0.497778C9.97802 0.222917 9.7551 0 9.48024 0ZM3.16552 0.462222H6.83496C7.18774 0.462222 7.47496 0.724583 7.47496 1.04889C7.47496 1.37319 7.18774 1.63556 6.83496 1.63556H3.16552C2.81274 1.63556 2.52552 1.37319 2.52552 1.04889C2.52552 0.724583 2.81274 0.462222 3.16552 0.462222ZM1.58691 2.80889C1.58691 2.6125 1.74607 2.45333 1.94246 2.45333H8.02246C8.21885 2.45333 8.37802 2.6125 8.37802 2.80889V9.49333C8.37802 9.68972 8.21885 9.84889 8.02246 9.84889H1.94246C1.74607 9.84889 1.58691 9.68972 1.58691 9.49333V2.80889ZM1.90691 11.52C1.78913 11.52 1.69357 11.4244 1.69357 11.3067C1.69357 11.1889 1.78913 11.0933 1.90691 11.0933H8.05802C8.1758 11.0933 8.27135 11.1889 8.27135 11.3067C8.27135 11.4244 8.1758 11.52 8.05802 11.52H1.90691ZM7.73802 12.7644C7.73802 12.8822 7.64246 12.9778 7.52468 12.9778H2.44024C2.32246 12.9778 2.22691 12.8822 2.22691 12.7644C2.22691 12.6467 2.32246 12.5511 2.44024 12.5511H7.52468C7.64246 12.5511 7.73802 12.6467 7.73802 12.7644ZM8.59135 14.4H1.37357C1.25579 14.4 1.16024 14.3044 1.16024 14.1867C1.16024 14.0689 1.25579 13.9733 1.37357 13.9733H8.59135C8.70913 13.9733 8.80468 14.0689 8.80468 14.1867C8.80468 14.3044 8.70913 14.4 8.59135 14.4Z"
                        fill="#D7DCE6"/>
                  </svg>
                  <span class="header-table-text">Role</span>
                </div>
              </th>
              <th>
                <div class="table-header-box">
                  <MessageIcon />
                  <span class="header-table-text">Message</span>
                </div>
                <em>optional</em>
              </th>
            </tr>
            </TableHeader>
            <tbody>
            <tr v-for="(v, index) in $v.formData.caseInvites.$each.$iter"
                :key="index">
              <td>
                <div class="table-body-box" :class="{ 'form-group--error': v.email.$error }">
                  <input
                      class="text"
                      type="text"
                      :id="`input_create_case_${index}`"
                      v-model="v.email.$model"
                      placeholder="Enter contact e-mail"
                  />
                  <div class="error" v-if="!v.email.required">Field is required</div>
                  <div class="error" v-if="!v.email.email">This must be an email</div>
                </div>
              </td>
              <td>
                <div class="table-body-box" :class="{ 'form-group--error': v.role.$error }">
                  <CustomSelect
                      :id="`create_cases_` + index"
                      :options="mockRole"
                      v-model="v.role.$model"
                      :multiple="multipleRole"
                      placeholder="Choose Role"
                      :customClass="customClass"
                      @input="v.role.$model = $event"
                  />
                  <div class="error" v-if="!v.role.required">Field is required</div>
                </div>
              </td>
              <td>
                <div class="holder-btns">
                  <div class="table-body-box">
                    <input
                        type="text"
                        class="text"
                        :id="`create_case_input_individual_message_to_client_${index}`"
                        v-model="v.message.$model"
                        placeholder="Type an individual message to your team member"
                    >
                  </div>
                  <div
                      class="close"
                      @click="deleteInvite(index)"
                  >
                    <div class="delete">
                      <CloseIcon />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        <div class="add-invite">
          <button
              id="create_new_case"
              class="invite-btn" @click.prevent="addNewInvite">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15.5" cy="15.5" r="15.5" fill="#082655"/>
              <path
                  d="M14.5341 21.7074H16.6023V16.6477H21.6619V14.5795H16.6023V9.51989H14.5341V14.5795H9.47443V16.6477H14.5341V21.7074Z"
                  fill="white"/>
            </svg>
          </button>
        </div>
        <div class="holder-send">
          <button
              id="save_create_case"
              :disabled="isSending"
              type="submit" class="btn">Create Case</button>
        </div>
      </div>
    </div>
    <CommonDialog
      ref="createCaseDialog"
      :show-footer="false"
      :closable="false"
    >
      <h2 class="dialog-message-title">Creating Case</h2>
      <p class="dialog-create-description" style="display:block; font-size: 14px;">Please wait a bit. Your Case is being created</p>
      <div class="dialog-info-wrapper">
        <p class="dialog-info-text">{{capitalizeFirstLetter(dialog.text)}}</p>
        <div class="progress-bar">
          <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
        </div>
      </div>
    </CommonDialog>
    <CommonDialog
        ref="commonDialogFormSuccess"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z" fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">{{dialogCaseName + " was successfully created"}}</p>
    </CommonDialog>
  </form>

</template>

<script>
import '@/assets/css/table.css';
import {required, email, minLength} from 'vuelidate/lib/validators'
import TableHeader from "../table/TableHeader";
import CustomSelect from "../inputs/CustomSelect";
import CommonDialog from "../popups/CommonDialog";
import AlertErrorMsg from "../profile/AlertErrorMsg";
import UserIcon from "../icons/UserIcon";
import CloseIcon from "../icons/CloseIcon";
import MessageIcon from "../icons/MessageIcon";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";
import getRoleName from "@/mixins/getRoleName";
import {ROLES} from "@/constants/const";

export default {
  name: "CreateCaseItem",
  components: {
    MessageIcon,
    CloseIcon,
    UserIcon,
    TableHeader,
    CustomSelect,
    CommonDialog,
    AlertErrorMsg,
  },
  data() {
    return {
      isSending: false,
      multipleRole: false,
      caseRegion: [],
      selectCaseRegion: null,
      mockRole: ROLES,
      customClass: "role-optional",
      formData: {
        caseName: "",
        caseInvites: [],
      },
      dialogCaseName: "",
      mockCases: [],
      submitStatus: null,
      validForm: false,
      errorMessage: '',
      dialog: {
        status: "",
        text: "Starting..."
      },
      progressPercentage: 0,
    }
  },

  validations: {
    formData: {
      caseName: {
        required,
        minLength: minLength(3)
      },
      caseInvites: {
        $each: {
          email: {
            required,
            email
          },
          message: {
            // required,
          },
          caseIds: {
            // required,
          },
          role: {
            required,
          },
        },
      },
    },
  },
  computed: {
    defaultValue() {
      return this.caseRegion.length ? this.caseRegion[0].name : ''
    }
  },
  mixins: [capitalizeFirstLetter, getRoleName],
  methods: {
    openDialogFormSuccess() {
      this.dialogCaseName = this.formData.caseName;
      this.$refs.commonDialogFormSuccess.openPopups();
    },
    resetForm() {
      this.formData.caseInvites = []
      this.formData.caseName = ""
      this.progressPercentage = 0;
      this.dialog.text = "Starting..."
      this.dialog.status = ""
      this.selectCaseRegion = this.caseRegion[0].name
      this.$emit('clear', null)
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addNewInvite() {
      this.formData.caseInvites.push({
        email: '',
        message: '',
        role: '',
        caseIds: ''
      })
    },
    deleteInvite(idx) {
      if (idx) {
        this.formData.caseInvites.splice(idx, 1)
      }
    },
    submit() {
      this.isSending = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        this.isSending = false
      } else {
        this.submitStatus = 'PENDING'
        let invitesArr = JSON.parse(JSON.stringify(this.formData.caseInvites))
        invitesArr.forEach((item) => {
          item.message.length ? item.message : item.message = null
          item.role = this.getRoleNameBE(item.role);
        });
        this.$load(async () => {
          this.$refs.createCaseDialog.openPopups();
          await this.$api.cases.createCase({
            caseInvites: invitesArr || null,
            caseRegion: this.selectCaseRegion.toUpperCase(),
            caseName: this.formData.caseName
          }).then(async (response) => {
            // eslint-disable-next-line no-undef
            const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
            await this.readStreamData(reader);
            if (!this.dialog.status.includes("error")) {
              this.openDialogFormSuccess()
              this.resetForm()
              this.isSending = false
            }
          }, (error) => {
            let errors;
            if (error.response) {
              errors = Object.values(error.response.data.errors);
            } else if (error.data.errors.message) {
              errors = error.data.errors.message;
            } else {
              errors = error.data.errors;
            }
            for (const error of errors) {
              if (error[0] !== 'Bad Request') this.errorMessage = error[0];
            }
           if (this.errorMessage) this.$refs.alert.showAlert();
           this.isSending = false;
          })
        })
      }
    },
    async readStreamData(reader) {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          this.$refs.createCaseDialog.closePopups();
          break;
        }
        const splitChunks = value.toString().split("\n");
        for (let i = 0; i < splitChunks.length; i++) {
          if (splitChunks[i].trim()) {
            if (splitChunks[i].includes("event:")) {
              const dataStatus = splitChunks[i].split("event:").find(chunk => chunk.trim() !== '');
              if (dataStatus && typeof dataStatus !== "undefined") {
                this.dialog.status = dataStatus;
              }
            }
            if (splitChunks[i].includes("data:")) {
              const dataText = splitChunks[i].split("data:").find(chunk => chunk.trim() !== '');
              if (dataText && typeof dataText !== "undefined") {
                this.dialog.text = dataText;
              }
            }
          }
        }
        this.progressPercentage += 17;
      }
    },
    getRegionsCase() {
      this.$load(async () => {
        await this.$api.cases.getRegionsToCase("case").then((res) => {
          const regionArray = res.data.body.regions

          this.caseRegion = regionArray.map((region, i) => {
            return {
              name: region.toLowerCase()[0].toUpperCase() + region.toLowerCase().slice(1),
              id: i,
            }
          })
          this.selectCaseRegion = this.caseRegion[0].name
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    }
  },
  mounted() {
    this.getRegionsCase();
  }
}
</script>

<style scoped>
.holder-input {
  max-width: 400px;
}

.add-invite {
  position: relative;
  margin-top: 20px;
  width: 100%;
  border-bottom: 1px solid #D7DCE6;
}

.add-invite svg {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: 1px solid var(--accent-color);
  border-radius: 50%;
}

.add-invite svg circle {
  fill: var(--accent-color);
  transition: 0.5s all;
}

.add-invite svg path {
  fill: var(--white-color);
  transition: 0.5s all;
}

.add-invite svg:hover circle {
  fill: var(--white-color);
}

.add-invite svg:hover path {
  fill: var(--accent-color);
}

.holder-send {
  margin-top: 60px;
}

.invite-btn {
  position: absolute;
  border: none;
  background-color: white;
}

.table-body.form-group--error .error {
  display: block;
}

.table-header .case, .table-header .message {
  flex-direction: column;
}

.table-body.border .table-row:last-child {
  border-bottom: none;
}

.table-body-box {
  position: relative;
  width: 100%;
  padding-right: 20px;
}

.delete {
  border: none;
  background-color: var(--white-color);
}

.table-body-box.form-group--error .error {
  position: absolute;
  bottom: -20px;
  padding-left: 15px;
}

.holder-search input {
  width: 100%;
  height: auto;
}

.text {
  width: 100%;
  border-radius: 79px;
  border: 1px solid var(--input-placeholder-value-color);
  padding: 12px 15px !important;
  font-size: 12px;
}

.text:hover {
  filter: drop-shadow(0px 0px 5px rgba(200, 205, 212, 0.5));
}

.dashboard-search {
  position: relative;
  width: 100%;
}

.text::placeholder {
  color: var(--input-placeholder-value-color);
  font-size: 14px;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0 0;
  display: block;
  width: 100%;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.dialog-create-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: var(--light-gray);
  margin: 10px 0 0;
}

.dialog-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 406px;
  margin: 25px 20px 50px;
  border-radius: 6px;
  background-color: var(--hover-accent-color);
}

.dialog-info-text {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: var(--accent-color);
  margin: 20px 15px;
}

.form-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-header .form-group {
  margin-right: 35px;
}

.custom-select .selected.open {
  border-radius: 79px !important;
}

.progress-bar {
  width: 345px;
  background-color: #EBEFF6;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 30px 30px;
}

.progress {
  height: 5px;
  background-color: var(--accent-color);
  width: 0;
  transition: width 0.3s;
}

.table-header-box {
  cursor: default;
}

.table-header-box:hover .header-table-text {
  text-decoration: none;
}
</style>
