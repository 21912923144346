<template>
  <div class="wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="dashboard-section">
          <div class="holder-contacts">
            <div class="contacts-bar">
              <div id="disabled-invite-user" ref="disabledInviteUser">
                <button
                    v-if="$route.path !== `/contacts/custodians`"
                    :disabled="!checkPermission(['invite_users'])"
                    id="goto_invite_new"
                    class="btn invite-user-btn"
                    @click="$router.push({path: `/invite-new-contact/invite-team`}).catch(()=>{})"
                >
                  Invite new
                </button>
                <tooltip v-if="!checkPermission(['invite_users'])" :target-ref="$refs.disabledInviteUser">
                  <span>To be able to invite new users to the system, you must have the "Invite User's" permission.</span>
                </tooltip>
              </div>
              <div class="contacts-bar-sort">
<!--                <div class="holder-tab-sorts">-->
<!--                  <div class="tab-wrapper">-->
<!--                    <router-link-->
<!--                        :to="{ path: '/contacts/users' }"-->
<!--                        tag="button"-->
<!--                        id="contacts_users_tab"-->
<!--                        exact-path-->
<!--                        class="tab-btn"-->
<!--                        :class="{'active': subIsActive('/contacts/users')}"-->
<!--                    >-->
<!--                      <span class="tab-btn-text">Users</span>-->
<!--                    </router-link>-->
<!--                    <router-link-->
<!--                        :to="{ path: '/contacts/custodians' }"-->
<!--                        tag="button"-->
<!--                        id="contacts_clients_tab"-->
<!--                        exact-path-->
<!--                        class="tab-btn"-->
<!--                    >-->
<!--                      <span class="tab-btn-text">Custodians</span>-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="holder-search">
                  <CustomSearch
                      id="search_contacts_button"
                      :modelValue="searchText"
                      @update:modelValue="searchText = $event"
                      placeholder="Search by name"
                  />
                  <CustomSelect
                    v-model="selectedRole"
                    placeholder="User role"
                    :options="ROLES"
                    :customClass="'custom-select-roles'"
                    ref="roleSelect"
                  />
                  <CustomSelect
                    v-model="selectedCaseId"
                    placeholder="Case name"
                    :options="casesList"
                    :type-select="'filterUser'"
                    :customClass="'custom-select-cases'"
                    ref="caseSelect"
                  />
                  <button
                    v-if="isAnyFilterApplied"
                    class="reset-filters"
                    @click="resetFilters"
                  >
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z"
                        fill="#FF9537" />
                    </svg>
                    Reset Filters
                  </button>
                </div>
                <div class="filter-item">

                  <ul class="switch-view">
                    <li>
                      <button
                          id="contacts_change_view_list"
                          class="btn btn-view"
                          :class="{ active: contactsView === 'list' }"
                          @click="switchView('list')"
                      >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M14.0476 8.0059H0.59524C0.266488 8.0059 0 7.73941 0 7.41066C0 7.08191 0.266488 6.81543 0.59524 6.81543H14.0476C14.3764 6.81543 14.6429 7.08191 14.6429 7.41066C14.6429 7.73941 14.3764 8.0059 14.0476 8.0059Z"
                              fill="#D7DCE6"/>
                          <path
                              d="M14.0477 1.19048H0.595301C0.266549 1.19048 6.10352e-05 0.923995 6.10352e-05 0.595242C6.10352e-05 0.266489 0.266549 0 0.595301 0H14.0477C14.3765 0 14.6429 0.266489 14.6429 0.595242C14.6429 0.923995 14.3765 1.19048 14.0477 1.19048Z"
                              fill="#D7DCE6"/>
                          <path
                              d="M14.0476 14.8218H0.59524C0.266488 14.8218 0 14.5553 0 14.2266C0 13.8978 0.266488 13.6313 0.59524 13.6313H14.0476C14.3764 13.6313 14.6429 13.8978 14.6429 14.2266C14.6429 14.5553 14.3764 14.8218 14.0476 14.8218Z"
                              fill="#D7DCE6"/>
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                          id="contacts_change_view_grid"
                          :class="{ active: contactsView === 'grid' }"
                          @click="switchView('grid')"
                          class="btn btn-view">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M3.00007 14.3996C4.32555 14.3996 5.40007 13.3251 5.40007 11.9996C5.40007 10.6741 4.32555 9.59961 3.00007 9.59961C1.67458 9.59961 0.600067 10.6741 0.600067 11.9996C0.600067 13.3251 1.67458 14.3996 3.00007 14.3996Z"
                              fill="#082655"/>
                          <path
                              d="M3 15C1.34314 15 0 13.6569 0 12C0 10.3431 1.34314 9 3 9C4.65686 9 6 10.3431 6 12C6 13.6569 4.65686 15 3 15ZM3 10.2245C2.01943 10.2245 1.22449 11.0194 1.22449 12C1.22449 12.9806 2.01943 13.7755 3 13.7755C3.98057 13.7755 4.77551 12.9806 4.77551 12C4.77557 11.0194 3.98063 10.2245 3 10.2245Z"
                              fill="#082655"/>
                          <path
                              d="M3.00007 5.39961C4.32555 5.39961 5.40007 4.32509 5.40007 2.99961C5.40007 1.67413 4.32555 0.599609 3.00007 0.599609C1.67458 0.599609 0.600067 1.67413 0.600067 2.99961C0.600067 4.32509 1.67458 5.39961 3.00007 5.39961Z"
                              fill="#082655"/>
                          <path
                              d="M3 6C1.34314 6 0 4.65686 0 3C0 1.34314 1.34314 0 3 0C4.65686 0 6 1.34314 6 3C6 4.65686 4.65686 6 3 6ZM3 1.22449C2.01943 1.22449 1.22449 2.01943 1.22449 3C1.22449 3.98057 2.01943 4.77551 3 4.77551C3.98057 4.77551 4.77557 3.98057 4.77557 3C4.77557 2.01943 3.98063 1.22449 3 1.22449Z"
                              fill="#082655"/>
                          <path
                              d="M12.0001 5.39961C13.3256 5.39961 14.4001 4.32509 14.4001 2.99961C14.4001 1.67413 13.3256 0.599609 12.0001 0.599609C10.6746 0.599609 9.60007 1.67413 9.60007 2.99961C9.60007 4.32509 10.6746 5.39961 12.0001 5.39961Z"
                              fill="#082655"/>
                          <path
                              d="M12 6C10.3431 6 9 4.65685 9 3C9 1.34314 10.3431 0 12 0C13.6569 0 15 1.34314 15 3C15 4.65685 13.6569 6 12 6ZM12 1.22449C11.0194 1.22449 10.2245 2.01943 10.2245 3C10.2245 3.98057 11.0194 4.77551 12 4.77551C12.9806 4.77551 13.7755 3.98057 13.7755 3C13.7756 2.01943 12.9806 1.22449 12 1.22449Z"
                              fill="#082655"/>
                          <path
                              d="M12.0001 14.3332C13.2887 14.3332 14.3334 13.2885 14.3334 11.9998C14.3334 10.7112 13.2887 9.6665 12.0001 9.6665C10.7114 9.6665 9.66672 10.7112 9.66672 11.9998C9.66672 13.2885 10.7114 14.3332 12.0001 14.3332Z"
                              fill="#082655"/>
                          <path
                              d="M12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15ZM12 10.2245C11.0194 10.2245 10.2245 11.0194 10.2245 12C10.2245 12.9806 11.0194 13.7755 12 13.7755C12.9806 13.7755 13.7755 12.9806 13.7755 12C13.7756 11.0194 12.9806 10.2245 12 10.2245Z"
                              fill="#082655"/>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <router-view
                :searchText="searchText"
                :searchByCase="selectedCaseId"
                :searchByRole="selectedRole"
                :contactsView="contactsView"
            />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import checkPermission from "../utils/permission";
import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import CustomSearch from "../components/inputs/CustomSearch";
import Tooltip from "@/components/common/Tooltip.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import {ROLES} from "@/constants/const";

export default {
  name: "Contacts",
  components: {
    AlertErrorMsg,
    CustomSelect,
    Tooltip,
    HeaderRegistered,
    Sidebar,
    CustomSearch,
  },
  data() {
    return {
      ROLES,
      routerFlag: this.$route.params,
      contactsView: 'list',
      searchText: "",
      errorMessage: '',
      searchPlaceholder: '',
      casesFromApi: [],
      selectedCaseId: null,
      selectedRole: null,
    }
  },
  watch: {
    '$route.path'(path) {
      this.searchText = '';
      this.setSearchInput(path);
    }
  },
  computed: {
    casesList() {
      return this.casesFromApi.map((aCase) => {
        return {name: aCase.caseName, id: aCase.id};
      });
    },
    isAnyFilterApplied() {
      return this.searchText || this.selectedCaseId || this.selectedRole;
    }
  },
  methods: {
    checkPermission,
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
    changeCheckbox(item, usersArrName) {
      this.data[usersArrName].forEach((el, index) => {
        if(item.id === el.id) {
          this.data[usersArrName][index] = item
        }
      })
      if(item.id === 'checkAll'){
        this.data[usersArrName].forEach((el) => {
          el.isActive = item.isActive
        })
      }
    },
    switchView(view) {
      this.contactsView = view;
    },
    setSearchInput(path) {
      if (path === '/contacts/custodians') {
        this.searchPlaceholder = 'Search by email';
      } else if (path === '/contacts/users') {
        this.searchPlaceholder = 'Search by name';
      }
    },
    getCases() {
      this.loadFlag = true;
      this.$load(async () => {
        await this.$api.cases.getCases({
          caseName: '',
          page: 1,
          size: 100,
        }).then((res) => {
            this.casesFromApi = res.data.body.content
            this.loadFlag = false;
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert();
          })
        )
      })
    },
    resetFilters() {
      this.searchText = '';
      this.selectedRole = '';
      this.$refs.roleSelect.clearSelection();
      this.$refs.caseSelect.clearSelection();
      this.selectedCaseId = null;
    },
  },
  mounted() {
    document.title = `Team`;
    this.getCases();

    this.setSearchInput(this.$route.path);
  }
}
</script>

<style scoped lang="scss">

.dashboard-section {
  padding: 0 55px 27px 35px;
}

.contacts-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.holder-contacts {
  width: 100%;
  margin-top: 17px;
}

.contacts-bar-sort {
  display: flex;
  margin-left: auto;
}

.holder-search {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 22px;
}

.reset-filters {
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  margin-right: 23px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);

  &:disabled {
    cursor: not-allowed;

    path {
      fill: var(--case-btn-border-color);
    }
  }

  svg {
    margin-right: 10px;
  }

  &:not(:disabled):hover {
    text-decoration: underline;
  }
}

.holder-tab-sorts {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tab-wrapper label {
  cursor: pointer;
}

.tab-wrapper input {
  visibility: hidden;
}

/* tabs */
.holder-tab-sorts {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 310px;
  width: 100%;
  min-height: 35px;
  margin-right: 20px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px 0 -1px 0;
  width: 50%;
  max-height: 35px;
  padding: 10px 30px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s all;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text {
  color: var(--accent-color);
}

.filter-item {
  display: flex;
}

.btn-filter {
  display: flex;
  min-width: 140px;
  margin-right: 18px;
  justify-content: space-between;
  background-color: var(--white-color);
  color: var(--secondary-text-color);
  border: 1px solid var(--input-placeholder-value-color);
}

.btn-filter svg path {
  fill: var(--accent-color);
}

.switch-view {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
}

.btn-view {
  min-width: 36px;
  width: 35px;
  height: 35px;
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 8px;
}

.btn-view.active {
  border: 1px solid var(--input-placeholder-value-color);
}

.btn-view.active svg path {
  fill: var(--accent-color);
}

.switch-view li:first-child {
  margin-right: 10px;
}

.btn-view svg path {
  fill: var(--input-placeholder-value-color);
}

.invite-user-btn {
  font-size: 12px;
  line-height: 19px;
  padding: 7px 30px;
  min-width: 120px;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
}

.invite-user-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

</style>
