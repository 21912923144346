import { CASE_CONFIG_ENUM } from "@/constants/const";

let caseCustodianSettings = {
  methods: {
    preparePrefilteringConfig(clientsArray, val) {
      if (val) {
        clientsArray.configs = [
          {
            configName: CASE_CONFIG_ENUM.prefiltering,
            value: val,
          },
          {
            configName: CASE_CONFIG_ENUM.dateFrom,
            value: '',
          },
          {
            configName: CASE_CONFIG_ENUM.dateTo,
            value: '',
          },
          {
            configName: CASE_CONFIG_ENUM.appList,
            value: '',
          }
        ]
      } else {
        clientsArray.configs = [
          CASE_CONFIG_ENUM.prefiltering,
          CASE_CONFIG_ENUM.dateFrom,
          CASE_CONFIG_ENUM.dateTo,
          CASE_CONFIG_ENUM.appList,
        ]
      }
    },
    prepareDateConfig(clientsArray, dateFrom, dateTo) {
      clientsArray.configs = [
        {
          configName: CASE_CONFIG_ENUM.dateFrom,
          value: dateFrom,
        },
        {
          configName: CASE_CONFIG_ENUM.dateTo,
          value: dateTo,
        }
      ]
    },
    prepareScreenshotsConfig(clientsArray, val) {
      // if (val) {
        clientsArray.configs = [
          {
            configName: CASE_CONFIG_ENUM.screenshots,
            value: val,
          },
        ]
      // } else {
      //   clientsArray.configs = [CASE_CONFIG_ENUM.screenshots]
      // }
    }
  }
}

export default caseCustodianSettings;
