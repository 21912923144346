export default {
  namespaced: true,
  state: {
    caseName: null,
    caseId: null,
    email: null,
    sessionId: null,
  },
  getters: {
    getCaseName(state) {
      return state.caseName;
    },
    getCaseId(state) {
      return state.caseId;
    },
    getEmail(state) {
      return state.email;
    },
    getSessionId(state) {
      return state.sessionId;
    },
  },
  mutations: {
    SET_CASE_NAME(state, payload) {
      state.caseName = payload
    },
    SET_CASE_ID(state, payload) {
      state.caseId = payload
    },
    SET_EMAIL(state, payload) {
      state.email = payload
    },
    SET_SESSION_ID(state, payload) {
      state.sessionId = payload
    },
    CLEAR_ALL(state) {
      state.caseName = null
      state.caseId = null
      state.email = null
      state.sessionId = null
    }
  },
  actions: {
    setSessionData({ commit }, payload) {
      commit('SET_CASE_NAME', payload.caseName)
      commit('SET_CASE_ID', payload.caseId)
      commit('SET_SESSION_ID', payload.sessionId)
    },
    setEmail({ commit }, payload) {
      commit('SET_EMAIL', payload)
    },
    clearAll({ commit }) {
      commit('CLEAR_ALL')
    }
  }
}