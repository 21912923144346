import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router'
import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { intervals } from './setIntervals';
import profile from './modules/profile';
import login from './modules/login';
import error from './modules/error';
import notifications from './modules/notifications';
import { caseMessages } from "@/store/caseMessages";
import sessions from "@/store/sessions";
import createPersistedState from 'vuex-persistedstate';

Vue.use(VueRouter)
    .use(Vuex);


export default new Vuex.Store({
  modules: {
    alert,
    authentication,
    users,
    intervals,
    login,
    profile,
    notifications,
    error,
    caseMessages,
    sessions,
  },
  plugins: [createPersistedState({
    paths:[
      'caseMessages',
      'profile.user.permissions',
      'profile.permission.permissions',
      'sessions'
    ]
  })],
});

