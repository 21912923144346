<template>
  <div v-show="selectedMessagesIdCopy.length" class="selected-messages-info">
    <div class="selected-messages-info-wrapper">
      <div class="select-all-messages-wrapper" :style="{ maxWidth: checkAll ? '140px' : '120px' }">
        <div class="custom-checkbox-group btn-select-all">
          <div class="checkbox-wrap">
            <input
              :id="`check_all_messages`"
              class="input-checkbox"
              type="checkbox"
              v-model="checkAll"
            >
            <label :for="`check_all_messages`" class="label-checkbox">
              <span>{{ checkAll ? 'Unselect' : 'Select' }} All ({{ selectedMessagesIdCopy.length }})</span>
            </label>
          </div>
        </div>
      </div>
      <div v-if="!$route.path.includes('media')" @click="openExportPopup" class="btn-export">
        <span>Export</span>
      </div>
      <CustomSelect
        v-else
        placeholder="Export"
        :options="[{ name:'pdf', id: 1}, { name: 'xml', id: 2}]"
        @input="exportMedia"
        v-model="selectedFormat"
        style="width: 150px;"
      />
    </div>
    <ExportPopup
      ref="exportPopup"
      @export="exportMessages"
      :isFiltersApplied="false"
      :isMessagesExport="true"
    />
  </div>
</template>
<script>
import ExportPopup from "@/components/popups/ExportPopup.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";

export default {
  name: 'SelectedMessagesInfo',
  components: {
    CustomSelect,
    ExportPopup
  },
  props: {
    messages: {
      type: Array,
    },
    selectedMessagesId: {
      type: Array,
    },
  },
  data: () => ({
    messagesCopy: [],
    selectedMessagesIdCopy: [],
    selectedFormat: null,
  }),
  watch: {
    messages(val) {
      this.messagesCopy = JSON.parse(JSON.stringify(val));
    },
    selectedMessagesId(val) {
      this.selectedMessagesIdCopy = val;
    },
  },
  computed: {
    checkAll: {
      get() {
        return this.messages.every(message => message.isActive === true);
      },
      set(value) {
        this.selectedMessagesIdCopy = [];
        this.isCheckedAllMessages = value;

        if (value) {
          this.messagesCopy = this.messages.map(message => {
            this.selectedMessagesIdCopy.push(message.id);

            return {...message, isActive: value};
          });
        } else {
          this.messagesCopy.forEach(message => message.isActive = value);
        }

        const params = {
          messages: this.messagesCopy,
          isCheckedAllMessages: this.isCheckedAllMessages,
          selectedMessagesId: this.selectedMessagesIdCopy
        }

        this.$emit('updatedActiveStatus', params)
      }
    },
  },
  methods: {
    openExportPopup() {
      this.$refs.exportPopup.openPopups();
    },
    uncheckAll() {
      this.messagesCopy.forEach(message => message.isActive = false);

      const params = {
        messages: this.messagesCopy,
        isCheckedAllMessages: false,
        selectedMessagesId: []
      }

      this.$emit('updatedActiveStatus', params)
    },
    exportMessages(format, config) {
      this.$emit('exportMessages', format, config, this.selectedMessagesIdCopy)
      this.uncheckAll()
    },
    exportMedia(selectedFormat) {
      this.$emit('selectedFormat', this.selectedFormat || selectedFormat);
      this.$emit('exportMedia', this.selectedMessagesIdCopy);
      this.uncheckAll();
    }
  },
};
</script>
<style scoped lang="scss">
.selected-messages-info {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px 16px;
  background-color: var(--accent-color);

  .selected-messages-info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px 0 0;

    .select-all-messages-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .btn-select-all {
        cursor: pointer;
        background-color: transparent;

        .label-checkbox {
          background-color: var(--white-color);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .3s ease;
          border: 1px solid var(--accent-color);
          border-radius: 1px;
          margin: 0;

          &:after {
            background: var(--accent-color);
          }

          span {
            padding: 2px 0 0;
            color: var(--white-color);
          }
        }
      }
    }

    .btn-export {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 79px;
      outline: none;
      min-width: 140px;
      padding: 8px 26px;
      background-color: #fff;
      cursor: pointer;

      span {
        color: var(--accent-color);
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }
    }
  }
}
</style>
