<template>
  <div class="reset-password-wrapper">
    <Header/>
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <section class="section-reset-password">
     <div class="reset-password-content">
       <h1 class="reset-password-title">Enter your Email address</h1>
       <form
           class="reset-password-form"
           @submit.prevent="submit"
       >
         <div class="form-group"
              :class="{ 'form-group--error': $v.formData.email.$error }"
         >
           <label for="email">Email address*</label>
           <input
               type="email"
               placeholder="Enter email address"
               class="input"
               :class="false ? 'is-invalid' : ''"
               id="email"
               name="email"
               v-model="$v.formData.email.$model"
           >
           <div class="error" v-if="!$v.formData.email.required">Field is required</div>
           <div class="error" v-if="!$v.formData.email.email">This must be an email</div>
         </div>
         <div class="group submit-btn">
           <button
               id="reset_password_button"
               class="btn reset-password-btn"
           >
             Reset password
           </button>
         </div>
       </form>
     </div>
    </section>
  </div>
</template>

<script>
import Header from "../../components/layout/Header";
import {email, required} from "vuelidate/lib/validators";
import AlertErrorMsg from "../../components/profile/AlertErrorMsg";

export default {
  name: "ResetPasswordForm",
  data() {
    return {
      formData: {
        email: "",
      },
      errorMessage: '',
    }
  },
  components: {
    Header,
    AlertErrorMsg,
  },
  validations: {
    formData: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.$load(async () => {
          await this.$api.resetPassword.passwordRecovery(this.formData).then(() => {
            localStorage.setItem('email-reset-password', this.formData.email)
            this.$router.push({name: 'ResetPassword'})
          })
        }, (error) => {
          if (error) {
            this.errorMessage = error.data.errors.message[0]
            this.$refs.alert.showAlert()
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.section-reset-password {
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}

.reset-password-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  color: var(--secondary-black-color);
}

.reset-password-form {
  margin: 40px 0 0 0;
  width: 100%;
  max-width: 400px;
}

.email {
  margin-bottom: 0;
}

.submit-btn {
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
}

.reset-password-btn {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 25px;
}
</style>