export default function(instance) {
    return {
        sendInviteUser(data) {
            return instance.post(`/invite/user`, data)
        },
        sendInviteClient(data) {
            return instance.post(`/cases/client/invite`, data)
        },
        getInviteUser(data) {
            return instance.get(`/invite/user`, data)
        },
        getInvite({type, sortBy, order, page, size, searchKey}) {
            return instance.get(`/invite/?type=${type}&sortBy=${sortBy}&order=${order}&page=${page}&size=${size}&searchKey=${searchKey}`,)
        },
        getInvitePending({type, sortBy, order, status, page, size, searchKey, requestDate, acceptDate}) {
            return instance.get(`/invite/?type=${type}&sortBy=${sortBy}&order=${order}&status=${status}&page=${page}&size=${size}&searchKey=${searchKey}&requestDate=${requestDate}&acceptDate=${acceptDate}`,)
        },
        deleteInviteUser(inviteId) {
            return instance.delete(`/invite/user?inviteIds=${inviteId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        getInviteUserResend(inviteId) {
            return instance.post(`/invite/user/resend?inviteId=${inviteId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
    }
}
