export default function (instance) {
    return {
        getNoMatchData(params) {
            return instance.get(`/caseMatchingData/data/nomatching`
                + `?keySearch=${encodeURIComponent(params.keySearch)}`
                + `&caseId=${params.caseId}`
                + `&deviceType=${params.deviceType}`
                + `&sources=${params.sources}`
                + `&dateFrom=${params.dateFrom}`
                + `&dateTo=${params.dateTo}`
                + `&page=${params.page}`
                + `&size=${params.size}`
                + `&sortOrder=${params.sortOrder}`);
        },
    }
}