import { render, staticRenderFns } from "./NotificationsSources.vue?vue&type=template&id=1ad69fa4&scoped=true"
import script from "./NotificationsSources.vue?vue&type=script&lang=js"
export * from "./NotificationsSources.vue?vue&type=script&lang=js"
import style0 from "./NotificationsSources.vue?vue&type=style&index=0&id=1ad69fa4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad69fa4",
  null
  
)

export default component.exports