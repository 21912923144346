<template>
  <div v-if="custodian" class="table-row">
    <div class="card">

      <div class="custom-checkbox">
        <CustomCheckbox
          :id="`custodian_${custodian.client.publicId.substring(0, 20)}`"
          :input-element="custodian"
        />
      </div>

      <div class="email">
        <div class="holder-info">
          <span>{{ custodian.client.email }}</span>
        </div>
      </div>

      <div class="screenshots">
        <div class="holder-info">
          <div class="toggle-save">
            <div class="toggleWrapper">
              <input
                type="checkbox"
                class="dn"
                :id="'s_screenshots_' + custodian.client.publicId.substring(0, 20)"
                v-model="custodian.configs.screenshotsConfig.value"
              />
              <label :for="'s_screenshots_' + custodian.client.publicId.substring(0, 20)" class="toggle">
                <span class="toggle__handler"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="filtering">
        <div class="holder-info">
          <div class="toggle-save">
            <div class="toggleWrapper">
              <input
                type="checkbox"
                class="dn"
                :id="'s_filtering_' + custodian.client.publicId.substring(0, 20)"
                v-model="custodian.configs.prefilteringConfig.value"
              />
              <label :for="'s_filtering_' + custodian.client.publicId.substring(0, 20)" class="toggle">
                <span class="toggle__handler"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="date">
        <div class="holder-info" :class="{ disabled: !custodian.configs.prefilteringConfig.value }">
          <date-picker
            v-model="dataRange"
            :id="`date_range_` + custodian.client.publicId.substring(0, 20)"
            range
            :format="'DD/MM/YYYY'"
            :range-separator="' - '"
            :editable="false"
            :clearable="false"
            class="datepicker-input"
            :input-class="'datepicker-input'"
            placeholder="Select Date range"
            :disabled-date="disableFutureDates"
          >
            <template #input v-if="formattedDateRange">
              <div class="date-chosen">{{ formattedDateRange }}</div>
            </template>
            <template #icon-calendar>
              <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z"
                  fill="#082655" />
                <path
                  d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z"
                  fill="white" />
                <path
                  d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z"
                  fill="#082655" />
                <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655" />
                <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655" />
                <path
                  d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z"
                  fill="#082655" />
                <path
                  d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z"
                  fill="#082655" />
                <path
                  d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z"
                  fill="white" />
              </svg>
            </template>
            <template #icon-clear>
              <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
                  fill="#082655" />
              </svg>
            </template>
          </date-picker>
        </div>
      </div>

      <div class="sources">
        <div class="holder-info" :class="{ disabled: !custodian.configs.prefilteringConfig.value }">
          <CustomMultiSelect
            :id="`select_apps_` + custodian.client.publicId.substring(0, 20)"
            :options="appsOptions"
            v-model="appsChosen"
            :multiple="true"
            placeholder="Choose apps"
            :preselected="preselectedApps"
            isSavable
            @saved="updateAppsList"
            ref="appsSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import CustomMultiSelect from "@/components/inputs/CustomMultiSelect.vue";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";
import parseDate from "@/mixins/parseDate";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import debounce from "@/mixins/debounce";
import caseCustodianSettings from "@/mixins/caseSettings/caseCustodianSettings";
import {APP_LIST_MULTISELECT, CASE_CONFIG_ENUM} from "@/constants/const";

export default {
  name: "CustodianSettingsItem",
  components: {
    CustomCheckbox,
    CustomMultiSelect,
    DatePicker,
  },
  emits: ['updateConfig', 'deleteConfig'],
  props: {
    custodian: {
      required: true,
    },
  },
  data() {
    return {
      selectedDate: [],
      appsChosen: [],
      preselectedApps: [],
      dataRange: null,
      appsOptions: APP_LIST_MULTISELECT,
      initialConfig: null,
      clientConfig: {
        clientIds: [this.custodian.client.publicId],
        configs: [],
        email: this.custodian.client.email
      },
    }
  },
  watch: {
    dataRange(value) {
      if (value) {
        const dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : null;
        const dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : null;

        if (dateFrom && dateTo) {
          this.selectedDate = [dateFrom, dateTo];

          this.prepareDateConfig(this.clientConfig, dateFrom, dateTo);
          this.$emit("updateConfig", this.clientConfig);
        }
      }
    },
    'custodian.configs.prefilteringConfig.value'(value) {
      this.preparePrefilteringConfig(this.clientConfig, value);
      this.configAction(value);
    },
    'custodian.configs.screenshotsConfig.value'(val) {

      this.prepareScreenshotsConfig(this.clientConfig, val);
      this.$emit('updateConfig', this.clientConfig)
    },
  },
  computed: {
    formattedDateRange() {
      if (this.selectedDate.length > 0) {
        const adjustedDateFrom = !isNaN(this.selectedDate[0]) ? new Date(this.selectedDate[0]) : null;
        const adjustedDateTo = !isNaN(this.selectedDate[1]) ? new Date(this.selectedDate[1]) : null;

        return `${this.parseDate(adjustedDateFrom)} - ${this.parseDate(adjustedDateTo)}`;
      } else {
        return null;
      }
    }
  },
  mixins: [
    capitalizeFirstLetter,
    parseDate,
    debounce,
    caseCustodianSettings,
  ],
  methods: {
    configAction(val) {
      if (val) {
        this.$emit('updateConfig', this.clientConfig)
      }
      if (val === false) {
        this.$emit('deleteConfig', this.clientConfig)
      }
    },
    disableFutureDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    updateAppsList() {
      if (this.appsChosen.length > 0) {
        this.clientConfig.configs = [
          {
            configName: CASE_CONFIG_ENUM.appList,
            value: this.appsChosen.map(item => item.caseName.replaceAll(' ', '').toLowerCase()).join(','),
          },
        ]
        this.$emit('updateConfig', this.clientConfig);
      }
    }
  },
  created() {
    this.initialConfig = JSON.parse(JSON.stringify(this.custodian));
    const config = this.custodian.configs;

    if (!config.dateFromConfig.isDefault && config.dateFromConfig.value) {
      const adjustedDateTo = !isNaN(config.dateToConfig.value)
        ? Number(config.dateToConfig.value)
        : config.dateToConfig.value.toLowerCase() === 'now'
          ? Date.parse(new Date().toDateString())
          : config.dateToConfig.value;
      this.selectedDate = [Number(config.dateFromConfig.value), adjustedDateTo];
    }

    const preselectedAppsArray = !config.appConfig.isDefault && config.appConfig.value
      ? config.appConfig.value.split(',')
      : [];

    this.preselectedApps = preselectedAppsArray.length > 0
      ? this.appsOptions.filter(item => preselectedAppsArray.includes(item.caseName.toLowerCase()))
      : [];
  }
}
</script>
<style scoped lang="scss">

.custom-checkbox {
  padding: 0 0 0 20px;
  width: 5%;
}

.email {
  width: 25%;
}

.screenshots {
  width: 10%;
}

.filtering {
  width: 10%;
}

.date {
  width: 22%;
}

.sources {
  width: 21%;
}

.contacts {
  width: 10%;
  padding: 0 5px;
}

.calls {
  width: 8%;
}

.card {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  transition: all 0.5s;
  position: relative;
  border-bottom: 1px solid var(--case-btn-border-color);
  width: 100%;
}

.holder-info {
  display: flex;
  align-items: center;
  margin: 0 0 0 15px;
  color: var(--secondary-text-color);

  .toggle-save {
    display: flex;
    width: 30%;
    justify-content: space-between;

    .toggleWrapper {
      input {
        position: absolute;
        left: -99em;
      }
    }

    .toggle {
      cursor: pointer;
      display: inline-block;
      position: relative;
      width: 38px;
      height: 18px;
      border-radius: 90px;
      background-color: var(--white-color);
      border: 1px solid var(--light-gray);
      transition: all 400ms;
    }

    .toggle__handler {
      display: inline-block;
      position: relative;
      z-index: 1;
      top: -.5px;
      left: -8px;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      transition: all 400ms;
      background-color: var(--light-gray);
      transform: rotate(-50deg);
    }

    input:checked {
      + .toggle {
        background-color: var(--accent-color);
        border: 1px solid var(--accent-color);

        .toggle__handler {
          background-color: var(--white-color);
          transform: translate3d(16px, 0, 0) rotate(0);
        }
      }
    }
  }

  .datepicker-input {
    max-width: 200px;
    width: 100%;
  }
}

.date .holder-info,
.sources .holder-info {
  margin: 0;
}

.contacts .holder-info,
.calls .holder-info {
  margin: 0 0 0 5px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
  transition: all .5s;
}

.visible {
  display: block;
}

.err-brd {
  border: 1px solid red;
  border-radius: 25px;
}

ul {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    list-style: disc;
  }
}

.date-chosen {
  width: 100%;
  max-width: 150px;
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: var(--secondary-text-color);
}

.datepicker-input >:first-child >:first-child {
  padding: 8.5px 27px 8.5px 13px;
}
</style>