<template>
  <div
    class="table-row"
  >
    <div class="card">

      <div class="holder-checkbox">
        <CustomCheckbox
          :id="keyItem.id"
          :labelText="'text'"
          :inputElement="keyItem"
        />
      </div>

      <div class="email">
        <div class="holder-info">
          <span>{{ keyItem.email }}</span>
        </div>
      </div>

      <div
        v-if="!$route.params.slug"
        class="case"
        @click="goToCase"
      >
        <div class="holder-info">
          <span>{{ keyItem.caseName }}</span>
        </div>
      </div>

      <div class="keyid">
        <div class="holder-info">
          <span>{{ keyItem.keyId }}</span>
          <svg @click="copyKeyIdToBuffer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.1875 0.47583H4.1875C4.03832 0.47583 3.89524 0.535093 3.78975 0.640583C3.68426 0.746072 3.625 0.889146 3.625 1.03833V3.85083H0.8125C0.663316 3.85083 0.520242 3.91009 0.414753 4.01558C0.309263 4.12107 0.25 4.26415 0.25 4.41333V13.4133C0.25 13.5625 0.309263 13.7056 0.414753 13.8111C0.520242 13.9166 0.663316 13.9758 0.8125 13.9758H9.8125C9.96168 13.9758 10.1048 13.9166 10.2102 13.8111C10.3157 13.7056 10.375 13.5625 10.375 13.4133V10.6008H13.1875C13.3367 10.6008 13.4798 10.5416 13.5852 10.4361C13.6907 10.3306 13.75 10.1875 13.75 10.0383V1.03833C13.75 0.889146 13.6907 0.746072 13.5852 0.640583C13.4798 0.535093 13.3367 0.47583 13.1875 0.47583ZM9.25 12.8508H1.375V4.97583H9.25V12.8508ZM12.625 9.47583H10.375V4.41333C10.375 4.26415 10.3157 4.12107 10.2102 4.01558C10.1048 3.91009 9.96168 3.85083 9.8125 3.85083H4.75V1.60083H12.625V9.47583Z"
              fill="#696F79" />
          </svg>
          <transition name="fade">
            <span v-if="showTooltip" class="tooltip">Copied</span>
          </transition>
        </div>
      </div>

      <div class="registered">
        <div class="holder-info">
          <span>{{ parseDate(keyItem.createdAt) }}</span>
        </div>
      </div>

      <div class="usage">
        <div class="holder-info">
          <span>{{ parseDate(keyItem.usageDateFrom) + " - " + parseDate(keyItem.usageDateTo) }}</span>
        </div>
      </div>

      <div class="days-left">
        <div class="holder-info">
            <span :style="{ color: keyItem.dayLeft > 5 ? '#5692B7' : keyItem.dayLeft > 0 ? '#FF9537' : '#F1105F' }">
              {{ (keyItem.dayLeft ? keyItem.dayLeft : "0") + " days" }}
            </span>
        </div>
      </div>

      <div class="status" @mouseenter="showNoDataTooltip = true" @mouseleave="showNoDataTooltip = false">
        <div
          class="holder-info"
          :class="{
              active: keyItem.status.toLowerCase() === KEY_STATUS.active,
              pending: keyItem.status.toLowerCase() === KEY_STATUS.pending,
              expired: keyItem.status.toLowerCase() === KEY_STATUS.expired,
              deactivated: keyItem.status.toLowerCase() === KEY_STATUS.deactivated,
            }">
          <span>{{ keyItem.status.toLowerCase() }}</span>
          <div v-if="keyItem.isHasNoMatchData" class="no-data">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1.5H4C3.73478 1.5 3.48043 1.60536 3.29289 1.79289C3.10536 1.98043 3 2.23478 3 2.5V13.5C3 13.7652 3.10536 14.0196 3.29289 14.2071C3.48043 14.3946 3.73478 14.5 4 14.5H13C13.2652 14.5 13.5196 14.3946 13.7071 14.2071C13.8946 14.0196 14 13.7652 14 13.5V2.5C14 2.23478 13.8946 1.98043 13.7071 1.79289C13.5196 1.60536 13.2652 1.5 13 1.5ZM8.5 10C8.64834 10 8.79334 10.044 8.91668 10.1264C9.04001 10.2088 9.13614 10.3259 9.19291 10.463C9.24967 10.6 9.26453 10.7508 9.23559 10.8963C9.20665 11.0418 9.13522 11.1754 9.03033 11.2803C8.92544 11.3852 8.7918 11.4566 8.64632 11.4856C8.50083 11.5145 8.35003 11.4997 8.21299 11.4429C8.07594 11.3861 7.95881 11.29 7.8764 11.1667C7.79399 11.0433 7.75 10.8983 7.75 10.75C7.75 10.5511 7.82902 10.3603 7.96967 10.2197C8.11032 10.079 8.30109 10 8.5 10ZM8 8.5V5C8 4.86739 8.05268 4.74021 8.14645 4.64645C8.24021 4.55268 8.36739 4.5 8.5 4.5C8.63261 4.5 8.75979 4.55268 8.85355 4.64645C8.94732 4.74021 9 4.86739 9 5V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9C8.36739 9 8.24021 8.94732 8.14645 8.85355C8.05268 8.75979 8 8.63261 8 8.5Z" fill="#F1105F"/>
            </svg>
            <transition name="fade">
              <span v-if="showNoDataTooltip" class="tooltip">
                {{ capitalizeFirstLetter(keyItem.status) }} - No Matching Data Source
              </span>
            </transition>
          </div>
        </div>
      </div>

      <div class="context-menu">
        <ContextMenu :item="keyItem" :options="menuOptions" @onSelected="onMenuSelection(keyItem, $event)" />
      </div>

    </div>
  </div>
</template>
<script>
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import ContextMenu from "@/components/popups/ContextMenu.vue";
import parseDate from "@/mixins/parseDate";
import { COMMON_ERROR, KEY_ACTION, KEY_STATUS, KEYS_MENU } from "@/constants/const";
import genIcon from "@/assets/img/key-management/generate-icon.svg";
import profileIcon from "@/assets/img/key-management/profile.svg";
import deleteIcon from "@/assets/img/key-management/delete.svg";
import resendIcon from "@/assets/img/key-management/resend.svg";
import gotoProfile from "@/mixins/gotoProfile";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";

export default {
  name: "KeyItem",
  components: {
    CustomCheckbox,
    ContextMenu,
  },
  emits: ["openKeysPopup"],
  props: {
    keyItem: {
      required: true,
    }
  },
  data() {
    return {
      KEY_STATUS,
      showTooltip: false,
      showNoDataTooltip: false,
    }
  },
  computed: {
    menuOptions() {
      const menuOptions = [];
      const keyStatus = this.keyItem.status.toLowerCase();

      if (keyStatus === KEY_STATUS.active || keyStatus === KEY_STATUS.pending) {
        menuOptions.push({name: KEYS_MENU.resendKey, img: resendIcon});
      } else if (keyStatus === KEY_STATUS.expired || keyStatus === KEY_STATUS.deactivated) {
        menuOptions.push({name: KEYS_MENU.genKey, img: genIcon});
      }

      menuOptions.push({name: KEYS_MENU.viewProfile, img: profileIcon});

      if (keyStatus !== KEY_STATUS.deactivated) {
        menuOptions.push({name: KEYS_MENU.deactivateKey, img: deleteIcon});
      }

      return menuOptions;
    }
  },
  mixins: [parseDate, gotoProfile, capitalizeFirstLetter],
  methods: {
    onMenuSelection(key, optionName) {
      switch (optionName) {
        case KEYS_MENU.genKey:
          this.$emit("openKeysPopup", KEY_ACTION.generateKey, key);
          break;
        case KEYS_MENU.resendKey:
          this.$emit("openKeysPopup", KEY_ACTION.resendKey, key);
          break;
        case KEYS_MENU.viewProfile:
          this.gotoClientProfile(key.publicUserId);
          break;
        case KEYS_MENU.deactivateKey:
          this.$emit("openKeysPopup", KEY_ACTION.deactivateKey, key);
          break;
        default: this.errorMessage = COMMON_ERROR.noSuchOption;
      }
    },
    copyKeyIdToBuffer() {
      navigator.clipboard.writeText(this.keyItem.keyId)
        .then(() => {
          this.showTooltip = true;

          setTimeout(() => {
            this.showTooltip = false;
          }, 2000);
        })
    },
    goToCase() {
      localStorage.setItem('title', this.keyItem.caseName)
      this.$router.push({path: `/cases/${this.keyItem.caseId}`}).catch(()=>{})
    }
  },
}
</script>
<style scoped lang="scss">
.email {
  width: 20%;
}

.case {
  width: 8%;

  .holder-info {

    span {
      text-decoration: underline;
    }
  }
}

.device {
  width: 8%;
}

.keyid {
  width: 15%;
}

.registered {
  width: 13%;
}

.usage {
  width: 17%;
}

.days-left {
  width: 10%;
}

.status {
  width: 10%;

  .holder-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: var(--white-color);
      }

      .tooltip {
        all: unset;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.75);
        color: #fff;
        z-index: 100;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 12px;
        transform: translate(-30%, -130%);
        white-space: nowrap;

        &:before {
          left: 100px;
        }
      }
    }
  }
}

.context-menu {
  width: 3%;
}

.card {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 0 15px 15px;
  transition: all 0.5s;
  position: relative;
  border-bottom: 1px solid var(--case-btn-border-color);
  width: 100%;

  .holder-checkbox {
    width: 55px;
  }
}

.holder-info {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

  svg {
    cursor: pointer;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}

.tooltip {
  position: absolute;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  z-index: 100;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  transform: translate(60%, -100%);
  transition: all .5s ease;
}

.active {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: #0826551A;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    color: var(--accent-color);
  }
}

.expired {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: var(--storage-warning);

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    color: var(--white-color);
  }
}

.pending {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: #FFC10780;
  border: 1px solid #FFC107;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    color: #8B6900;
  }
}

.deactivated {
  width: 80px;
  height: 25px;
  border-radius: 20px;
  background: rgba(8, 38, 85, 0.05);

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    --accent-color: rgba(8, 38, 85, 0.5);
  }
}
</style>