<template>
  <div class="holder-table">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <TableHeader
      style="position: sticky; top: -1px; z-index: 2"
      :class="checkAllKeys || checkSomeKeys.length >= 2 ? 'active ' : ''"
    >

      <th class="holder-checkbox">
        <div class="custom-checkbox-group">
          <div class="checkbox-wrap">
            <input
              :id="`checkAll_keys`"
              class="input-checkbox"
              type="checkbox"
              v-model="checkAllKeys">
            <label :for="`checkAll_keys`" class="label-checkbox">
              <span>{{ ' ' }}</span>
            </label>
            <span
              v-if="checkAllKeys || checkSomeKeys.length >= 2"
              class="checkbox-unselect"
              @click="unselectAll"
            >
              Unselect All
            </span>
          </div>
        </div>
      </th>

      <div v-show="!checkAllKeys && checkSomeKeys.length <= 1" class="email">
        <div
          :id="`sort_keys_by_email`"
          @click="sortedBy('email')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'email',
            'sorted-desc': this.order === DESC,
          }"
        >
          <EmailIcon />
          <span class="header-table-text">Custodian Email</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div v-show="!checkAllKeys && checkSomeKeys.length <= 1 && !$route.params.slug" class="case">
        <div
          :id="`sort_keys_by_case_id`"
          @click="sortedBy('caseId')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'caseId',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z"
              fill="#D7DCE6" />
          </svg>
          <span class="header-table-text">Case</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div v-show="!checkAllKeys && checkSomeKeys.length <= 1" class="keyid">
        <div
          :id="`sort_keys_by_keyid`"
          @click="sortedBy('keyId')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'keyId',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Key id</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div v-show="!checkAllKeys && checkSomeKeys.length <= 1" class="registered">
        <div
          :id="`sort_keys_by_register_date`"
          @click="sortedBy('registerDate')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'registerDate',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Registration date</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div v-show="!checkAllKeys && checkSomeKeys.length <= 1" class="usage">
        <div
          :id="`sort_keys_by_usage_period`"
          @click="sortedBy('usagePeriod')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'usagePeriod',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Usage period</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div v-show="!checkAllKeys && checkSomeKeys.length <= 1" class="days-left">
        <div
          :id="`sort_keys_by_days_left`"
          @click="sortedBy('daysLeft')"
          class="table-header-box"
          :class="{
            'sort-active': this.sortBy === 'daysLeft',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Days left</span>
          <arrowDown class="arrow" />
        </div>
      </div>

      <div v-show="!checkAllKeys && checkSomeKeys.length <= 1" class="status">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 16C6.55208 16 5.21354 15.6432 3.98438 14.9297C2.75521 14.2161 1.78385 13.2448 1.07031 12.0156C0.356771 10.7865 0 9.44792 0 8C0 6.55208 0.356771 5.21354 1.07031 3.98438C1.78385 2.75521 2.75521 1.78385 3.98438 1.07031C5.21354 0.356771 6.55208 0 8 0C9.44792 0 10.7865 0.356771 12.0156 1.07031C13.2448 1.78385 14.2161 2.75521 14.9297 3.98438C15.6432 5.21354 16 6.55208 16 8C16 9.44792 15.6432 10.7865 14.9297 12.0156C14.2161 13.2448 13.2448 14.2161 12.0156 14.9297C10.7865 15.6432 9.44792 16 8 16ZM1 8C1 8.94792 1.17969 9.84896 1.53906 10.7031C1.89844 11.5573 2.40104 12.3073 3.04688 12.9531L8 8V1C7.05208 1 6.14583 1.1849 5.28125 1.55469C4.41667 1.92448 3.67188 2.42188 3.04688 3.04688C2.42188 3.67188 1.92448 4.41667 1.55469 5.28125C1.1849 6.14583 1 7.05208 1 8Z"
              fill="#D7DCE6" />
          </svg>
          <span class="header-table-text">Status</span>
        </div>
      </div>

      <div
        v-show="checkAllKeys || checkSomeKeys.length >= 2"
        class="holder-btn"
        id="disabled-deactivate-all"
      >
        <div id="generate-keys-btn" ref="generateKeysBtn">
          <button
            id="generate_keys"
            class="btn btn-deactivate btn-generate"
            @click.stop="openKeysPopup(KEY_ACTION.generateKey)"
            :disabled="expiredKeys.length < 1 && deactivatedKeys.length < 1"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.5 5.25013V9.75013C22.5 9.94905 22.421 10.1398 22.2803 10.2805C22.1397 10.4211 21.9489 10.5001 21.75 10.5001H17.25C17.0511 10.5001 16.8603 10.4211 16.7197 10.2805C16.579 10.1398 16.5 9.94905 16.5 9.75013C16.5 9.55122 16.579 9.36046 16.7197 9.2198C16.8603 9.07915 17.0511 9.00013 17.25 9.00013H19.8188L17.3259 6.71638L17.3025 6.69388C16.2601 5.65184 14.9337 4.94006 13.4891 4.64746C12.0445 4.35485 10.5457 4.4944 9.17991 5.04866C7.81414 5.60293 6.64197 6.54734 5.80982 7.76391C4.97768 8.98048 4.52245 10.4153 4.501 11.8891C4.47956 13.3629 4.89286 14.8103 5.68926 16.0506C6.48566 17.2908 7.62986 18.269 8.97892 18.8627C10.328 19.4565 11.8221 19.6396 13.2746 19.3891C14.7271 19.1387 16.0736 18.4658 17.1459 17.4545C17.2905 17.3178 17.4835 17.244 17.6825 17.2496C17.8814 17.2551 18.07 17.3395 18.2067 17.484C18.3435 17.6286 18.4172 17.8216 18.4117 18.0205C18.4061 18.2195 18.3218 18.4081 18.1772 18.5448C16.5098 20.1261 14.298 21.0053 12 21.0001H11.8763C10.4023 20.9799 8.95579 20.598 7.66398 19.8879C6.37216 19.1777 5.27455 18.1612 4.46762 16.9275C3.6607 15.6938 3.16915 14.2808 3.0362 12.8127C2.90324 11.3446 3.13294 9.86628 3.70511 8.50772C4.27728 7.14916 5.1744 5.95194 6.31762 5.02128C7.46085 4.09063 8.81517 3.45504 10.2616 3.17038C11.7079 2.88571 13.2021 2.96069 14.6127 3.38871C16.0234 3.81674 17.3073 4.58471 18.3516 5.62513L21 8.04388V5.25013C21 5.05122 21.079 4.86046 21.2197 4.7198C21.3603 4.57915 21.5511 4.50013 21.75 4.50013C21.9489 4.50013 22.1397 4.57915 22.2803 4.7198C22.421 4.86046 22.5 5.05122 22.5 5.25013Z"
                fill="#696F79" />
            </svg>
            <span class="btn-span">Generate Keys</span>
          </button>
        </div>
        <div ref="resendKeysBtn">
          <button
            id="resend_keys"
            class="btn btn-deactivate btn-resend"
            @click.stop="openKeysPopup(KEY_ACTION.resendKey)"
            :disabled="activeKeys.length < 1 && pendingKeys.length < 1"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.3113 2.68851C21.1226 2.50001 20.8872 2.3652 20.6291 2.29797C20.3711 2.23074 20.0998 2.23351 19.8431 2.30601H19.8291L1.8347 7.76601C1.54257 7.8502 1.28292 8.02117 1.09016 8.25627C0.897393 8.49136 0.780616 8.77948 0.755302 9.08245C0.729987 9.38541 0.79733 9.68891 0.948405 9.95274C1.09948 10.2166 1.32716 10.4283 1.60126 10.5598L9.56252 14.4373L13.4344 22.3938C13.5548 22.6508 13.7463 22.868 13.9862 23.0196C14.2261 23.1713 14.5043 23.2512 14.7881 23.2498C14.8313 23.2498 14.8744 23.2479 14.9175 23.2441C15.2202 23.2196 15.5082 23.1031 15.7428 22.9102C15.9774 22.7174 16.1474 22.4573 16.23 22.1651L21.6863 4.1707C21.6863 4.16601 21.6863 4.16132 21.6863 4.15664C21.7597 3.90066 21.7637 3.62976 21.6978 3.37174C21.6319 3.11373 21.4985 2.87791 21.3113 2.68851ZM14.7966 21.7357L14.7919 21.7488V21.7423L11.0363 14.0266L15.5363 9.52664C15.671 9.38484 15.745 9.19603 15.7425 9.00045C15.74 8.80488 15.6612 8.61803 15.5229 8.47973C15.3846 8.34143 15.1977 8.26262 15.0021 8.26012C14.8066 8.25761 14.6177 8.33161 14.476 8.46632L9.97595 12.9663L2.25751 9.2107H2.25095H2.26408L20.25 3.74976L14.7966 21.7357Z"
                fill="#696F79" />
            </svg>
            <span class="btn-span">Resend Keys</span>
          </button>
        </div>
        <div ref="deactivateKeysBtn">
          <button
            id="deactivate_keys"
            class="btn btn-deactivate"
            @click.stop="openKeysPopup(KEY_ACTION.deactivateKey)"
            :disabled="deactivatedKeys.length === this.keysData.length"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z"
                fill="#F1105F" />
            </svg>
            <span class="btn-span">Deactivate Keys</span>
          </button>
        </div>
        <tooltip v-if="$refs.generateKeysBtn" :target-ref="$refs.generateKeysBtn">
          <span>Available only for Expired or Deactivated Key status</span>
        </tooltip>
        <tooltip v-if="$refs.resendKeysBtn" :target-ref="$refs.resendKeysBtn">
          <span>Available only for Active or Pending Key status</span>
        </tooltip>
      </div>
    </TableHeader>

    <div v-if="keysData.length > 0" class="table-body">
      <KeyItem
        v-for="(key, index) in keysData"
        :key="index"
        :keyItem="key"
        @openKeysPopup="openKeysPopup"
      />
    </div>
    <key-management-popup
      ref="keyManagementPopup"
      :keysData="keyItem ? [keyItem] : actionKeys"
      :action="popupAction"
      @keyAction="performKeyAction"
      @closed="keyItem = null"
    />
    <CommonDialog ref="successPopup">
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655" />
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description">{{ successPopupText }}</p>
    </CommonDialog>
  </div>
</template>

<script>
import TableHeader from "@/components/table/TableHeader";
import EmailIcon from "@/components/icons/EmailIcon";
import KeyManagementPopup from "@/components/popups/KeyManagementPopup.vue";
import ArrowDown from "@/components/icons/ArrowDown.vue";
import {ASC, DESC, KEY_ACTION, KEY_STATUS} from "@/constants/const";
import checkAll from "@/mixins/checkAll";
import KeyItem from "@/components/management/KeyItem.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import Tooltip from "@/components/common/Tooltip.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import debounce from "@/mixins/debounce";

export default {
  name: "ManageKeysList",
  components: {
    AlertErrorMsg,
    Tooltip,
    CommonDialog,
    KeyItem,
    ArrowDown,
    KeyManagementPopup,
    EmailIcon,
    TableHeader,
  },
  emits: ['sortBy'],
  props: {
    keysData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      KEY_ACTION,
      DESC,
      ASC,
      popupAction: KEY_ACTION.resendKey,
      sortBy: '',
      order: DESC,
      keyItem: null,
      actionKeys: [],
      errorMessage: '',
    };
  },
  computed: {
    expiredKeys() {
      return this.keysData.filter(key => key.status.toLowerCase() === KEY_STATUS.expired);
    },
    deactivatedKeys() {
      return this.keysData.filter(key => key.status.toLowerCase() === KEY_STATUS.deactivated);
    },
    activeKeys() {
      return this.keysData.filter(key => key.status.toLowerCase() === KEY_STATUS.active);
    },
    pendingKeys() {
      return this.keysData.filter(key => key.status.toLowerCase() === KEY_STATUS.pending);
    },
    successPopupText() {
      if (this.popupAction === KEY_ACTION.generateKey || this.popupAction === KEY_ACTION.resendKey) {
        return `The key${ this.actionKeys.length > 1 ? 's were' : ' was' } sent to
        custodian${ this.actionKeys.length > 1 ? 's' : '' } by email`;
      } else if (this.popupAction === KEY_ACTION.deactivateKey) {
        return `The key${ this.actionKeys.length > 1 ? 's were' : ' was' } deactivated successfully`;
      }
      return 'The operation was successful'
    }
  },
  mixins: [checkAll, debounce],
  methods: {
    unselectAll() {
      this.keysData.forEach((item) => {
        item.isActive = false;
      })
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === DESC ? ASC : DESC;
      this.sortBy = filterName
      this.$emit('sortBy', {sortBy: this.sortBy, order: this.order});
    },
    openKeysPopup(action, key = null) {
      this.popupAction = action;
      this.setActionKeys();
      if (key) {
        this.keyItem = key;
      }
      if (action === KEY_ACTION.generateKey) {
        this.generateNewKey(key);
      }
      this.$refs.keyManagementPopup.openPopups();
    },
    performKeyAction(keys, action) {
      if (action === KEY_ACTION.resendKey) {
        this.sendKey(keys);
      } else if (action === KEY_ACTION.deactivateKey) {
        this.deactivateKey(keys);
      }
    },
    setActionKeys() {
      if (this.popupAction === KEY_ACTION.resendKey) {
        this.actionKeys = this.keysData.filter(key =>
          key.isActive
          && (key.status.toLowerCase() === KEY_STATUS.active
          || key.status.toLowerCase() === KEY_STATUS.pending));
      } else if (this.popupAction === KEY_ACTION.generateKey) {
        this.actionKeys = this.keysData.filter(key =>
          key.isActive
          && (key.status.toLowerCase() === KEY_STATUS.expired
          || key.status.toLowerCase() === KEY_STATUS.deactivated));
      } else if (this.popupAction === KEY_ACTION.deactivateKey) {
        this.actionKeys = this.keysData.filter(key =>
          key.isActive
          && key.status.toLowerCase() !== KEY_STATUS.deactivated);
      } else {
        this.actionKeys = [];
      }
    },

    sendKey(keysIds) {
      const inactiveKeys = this.keysData.filter(key => !key.isActive);
      const keysToSend = inactiveKeys.length > 0 ? keysIds : [];
      this.$load(async () => {
        await this.$api.management.sendKey({keysIds: keysToSend })
        this.$refs.successPopup.openPopups();
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        }
      );
    },
    generateNewKey(key) {
      this.$load(async () => {
        this.keyIdToGenerate = key ? [key.id] : this.actionKeys.map(key => key.id)
        await this.$api.management.regenerateKey({keysIds: this.keyIdToGenerate });
        this.$emit('sortBy');
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
      });
    },
    deactivateKey(keys) {
      this.$load(async () => {
        await this.$api.management.deactivateKey({keysIds: keys});
        this.$refs.successPopup.openPopups();
        this.$emit('sortBy');
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
      });
    },
  },
  mounted() {
    this.debounce(() => {
      if (this.expiredKeys.length > 0) {
        this.errorMessage = 'You have expired keys. Generate new keys.';
        this.$refs.alert.showAlert();
      }
    });
  }
}
</script>

<style lang="scss" scoped>

.holder-table {
  margin: 15px 0 0;
}

.table-body {
  min-height: fit-content;
}

.table-header {
  max-height: 64px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 0;
}

.table-header-box {
  cursor: default;
}

.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box .header-table-text {
  margin: 0 5px 0 0;
  width: fit-content;
}

.table-header-box:hover .header-table-text {
  text-decoration: none;
}

.email {
  width: 20%;
}

.case {
  width: 8%;
}

.device {
  width: 8%;
}

.keyid {
  width: 14.5%;
}

.registered {
  width: 12.5%;
}

.usage {
  width: 17%;
}

.days-left {
  width: 10%;
}

.status {
  width: 10%;
}

.context-menu {
  width: 3%;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0;
  display: block;
  width: 100%;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.arrow {
  transition: transform 0.3s;
}

.checkbox-wrap .checkbox-unselect {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: initial;
  cursor: pointer;
  padding: 2px 0 0;
}

.holder-btn {
  display: flex;
  align-items: center;
  gap: 15px;

  .btn {
    display: flex;
    align-items: center;
    gap: 5px;

    .btn-span {
      margin-right: 12px;
      font-size: 14px;
      text-transform: none;
      color: var(--secondary-text-color);
    }
  }

  .btn-deactivate {
    &:hover svg path {
      fill: var(--error-color);
    }
  }

  .btn-generate,
  .btn-resend {
    &:hover svg path {
      fill: var(--accent-color);
    }
  }
}
</style>
