<template>
  <div class="notification-wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="holder-table">
      <table cellpadding="0" cellspacing="0" border="0">
        <TableHeader>
          <tr>
            <th class="email">
              <div
                :id="`sort_data_by_email`"
                @click="sortedBy('contactEmail')"
                class="table-header-box"
                :class="{
                  'sort-active': this.sortBy === 'contactEmail',
                  'sorted-desc': this.order === ASC,
                }"
                style="cursor: pointer"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.99968 0.426758L5.48634 2.00009H3.33301V3.33342L1.97967 4.19342C1.59301 4.42676 1.33301 4.84676 1.33301 5.33342V12.0001C1.33301 12.3537 1.47348 12.6929 1.72353 12.9429C1.97358 13.1929 2.31272 13.3334 2.66634 13.3334H13.333C14.073 13.3334 14.6663 12.7401 14.6663 12.0001V5.33342C14.6663 4.84676 14.4063 4.42676 14.0197 4.19342L12.6663 3.33342V2.00009H10.513L7.99968 0.426758ZM4.66634 3.33342H11.333V6.58676L7.99968 8.66676L4.66634 6.58676V3.33342ZM5.33301 4.00009V5.00009H10.6663V4.00009H5.33301ZM3.33301 4.92009V5.75342L2.66634 5.33342L3.33301 4.92009ZM12.6663 4.92009L13.333 5.33342L12.6663 5.75342V4.92009ZM5.33301 5.66676V6.66676H10.6663V5.66676H5.33301Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Custodian email</span>
                <arrow-down class="arrow"/>
              </div>
            </th>

            <th class="case">
              <div class="table-header-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z"
                    fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Case</span>
              </div>
            </th>

            <th class="device">
              <div class="table-header-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_16339_663)">
                    <path
                      d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                      fill="#D7DCE6"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_16339_663">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="header-table-text">Device</span>
              </div>
            </th>

            <th class="device-id">
              <div class="table-header-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_16339_663)">
                    <path
                      d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                      fill="#D7DCE6"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_16339_663">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="header-table-text">Device Id</span>
              </div>
            </th>

            <th class="collection-date">
              <div class="table-header-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_16339_663)">
                    <path
                      d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                      fill="#D7DCE6"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_16339_663">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="header-table-text">Date of collection</span>
              </div>
            </th>

            <th class="time">
              <div class="table-header-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_16339_663)">
                    <path
                      d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                      fill="#D7DCE6"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_16339_663">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="header-table-text">Time of collection</span>
              </div>
            </th>

            <th class="sources">
              <div
                :id="`sort_data_by_email`"
                @click="sortedBy('dataSources')"
                class="table-header-box"
                :class="{
                  'sort-active': this.sortBy === 'dataSources',
                  'sorted-desc': this.order === ASC,
                }"
                style="cursor: pointer"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_16339_663)">
                    <path
                      d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                      fill="#D7DCE6"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_16339_663">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="header-table-text">Data sources</span>
                <arrow-down class="arrow"/>
              </div>
            </th>
          </tr>
        </TableHeader>
        <Loader v-if="loadFlag" :load-flag="loadFlag"/>
        <template v-else>
          <tbody>
            <NotificationsSourcesItem
              v-for="(item, i) in notificationsSourcesList"
              :key="i"
              :item="item"
            />
          </tbody>
          <p v-if="!notificationsSourcesList.length" class="no-found">No data</p>
        </template>
      </table>
      <pagination v-if="notificationsSourcesList.length && count !== 1"
                  v-model="currentPage"
                  :total-pages="count"
                  :link-gen="linkGen"
      ></pagination>
    </div>
  </div>
</template>

<script>
import AlertErrorMsg from "../profile/AlertErrorMsg";
import TableHeader from "../table/TableHeader";
import {mapActions} from "vuex";
import Loader from "../loader/Loader";
import linkGenerator from "../../constants/linkGen";
import ArrowDown from '@/components/icons/ArrowDown';
import Pagination from "@/components/common/Pagination.vue";
import {ASC, DESC} from "@/constants/const";
import NotificationsSourcesItem from "@/components/notifications/NotificationsSourcesItem.vue";

export default {
  name: "NotificationsSources",
  components: {
    NotificationsSourcesItem,
    Pagination,
    Loader,
    AlertErrorMsg,
    TableHeader,
    ArrowDown,
  },
  props: {
    dataRange: {},
    searchText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      DESC,
      ASC,
      currentPage: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      sortBy: 'createdAt',
      order: 'desc',
      dateFrom: '',
      dateTo: '',
      notificationsSourcesList: [],
      notificationsSourcesNotViewList: [],
      loadFlag: false,
      errorMessage: '',
    }
  },
  watch: {
    searchText() {
      this.getNotificationsSources();
    },
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : '';
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : '';
      this.currentPage = 1;
      this.getNotificationsSources();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.currentPage = +newVal;
          this.getNotificationsSources();
        } else {
          this.currentPage = 1;
        }
      }
    },
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
  },
  methods: {
    ...mapActions({
      getNotificationCount: 'notifications/getNotificationCount',
      getNotificationAll: 'notifications/getNotificationAll'
    }),
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/notifications/sources')
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === DESC ? ASC : DESC
      this.sortBy = filterName
      this.getNotificationsSources()
    },
    fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    notificationView(oldArr, arr) {
      oldArr.forEach(item => {
        if (!item.viewed) {
          arr.push(item.id)
        }
      })
    },
    getNotificationsSources() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.notifications.getNoMatchingNotifications({
          sortBy: this.sortBy,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          order: this.order,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: this.currentPage,
          size: this.size,
        }).then((res) => {
          this.notificationsSourcesList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
          this.notificationsSourcesNotViewList = []
          this.notificationView(this.notificationsSourcesList, this.notificationsSourcesNotViewList)
          if (this.notificationsSourcesNotViewList.length) {
            this.changeStatusNotificationType()
          }
        }, (error) => {
          if (error) {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          }
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    changeStatusNotificationType() {
      this.$load(async () => {
        await this.$api.notifications.changeStatusNotificationType({
          notificationIds: this.notificationsSourcesNotViewList.toString(),
        }).then(() => {
          this.getNotificationAll({}).then(() => {
            this.getNotificationCount({})
          })
        })
      })
    },
  },
  mounted() {
    this.currentPage = +this.$route.query.page || 1;
    this.getNotificationsSources()
  }
}
</script>

<style scoped>
.holder-table {
  position: relative;
}

svg {
  min-width: 16px;
  min-height: 16px;
}

.arrow {
  min-width: 12px;
  min-height: 6px;
}

.email {
  width: 20%;
}

.case {
  width: 15%;
}

.device {
  width: 8%;
}

.device-id {
  width: 10%;
}

.collection-date {
  width: 10%;
}

.time {
  width: 8%;
}

.sources {
  width: 15%;
}
</style>
