<template>
  <div class="card">

    <div class="email">
      <div class="holder-info">
        <span>{{ session.email }}</span>
      </div>
    </div>

    <div class="device">
      <div class="holder-info">
        <div v-if="session.device && /\S/.test(session.device)" class="device-info">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#F8FAFC"/>
            <path d="M19.3654 8H12.6331C11.9893 8 11.4691 8.52214 11.4691 9.16598V22.8355C11.4691 23.4774 11.9893 24 12.6331 24H19.3654C20.0082 24 20.5303 23.4779 20.5303 22.8355V9.16598C20.5298 8.52214 20.0082 8 19.3654 8ZM19.6572 20.9636H12.3422V9.96686H19.6572V20.9636ZM17.5601 9.18315H14.4389V8.8428H17.5606L17.5601 9.18315ZM18.8958 9.02762C18.8958 9.19224 18.7614 9.32555 18.5973 9.32555C18.4327 9.32555 18.2989 9.19173 18.2989 9.02762C18.2989 8.8635 18.4327 8.72968 18.5973 8.72968C18.7619 8.72918 18.8958 8.863 18.8958 9.02762ZM17.4202 22.7578H14.5777V21.8832H17.4202V22.7578Z" fill="#082655"/>
          </svg>
          <span class="info-name">{{ session.device }}</span>
        </div>
        <div v-if="session.desktop && /\S/.test(session.desktop)" class="device-info">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#F8F5FF"/>
            <g clip-path="url(#clip0_17342_319)">
              <path d="M24.4023 19.7441V18.9876V14.4465V9.90545C24.4023 9.48984 24.0527 9.14893 23.6249 9.14893H8.06996C7.64212 9.14893 7.29153 9.48816 7.29153 9.90545V14.4465V18.9876V19.7441L5.34741 22.0146C5.34741 22.6402 5.86948 23.1489 6.51408 23.1489H25.1807C25.8244 23.1489 26.3474 22.6399 26.3474 22.0146L24.4023 19.7441ZM17.404 22.7719H14.2925C14.185 22.7719 14.0979 22.6878 14.0979 22.5825C14.0979 22.4772 14.185 22.3931 14.2925 22.3931H17.4043C17.5108 22.3931 17.5989 22.4772 17.5989 22.5825C17.5959 22.6881 17.5106 22.7719 17.404 22.7719ZM12.6414 22.0153L13.2348 21.3223H18.4588L19.0532 22.0153H12.6414ZM23.3077 18.4056C23.3077 18.7707 23.0022 19.0661 22.6297 19.0661H9.0649C8.69241 19.0661 8.38685 18.768 8.38685 18.4056V10.4889C8.38685 10.1238 8.69241 9.82836 9.0649 9.82836H22.6299C23.0024 9.82836 23.308 10.1257 23.308 10.4889C23.3077 10.4889 23.3077 18.4056 23.3077 18.4056Z" fill="#9B7EDE"/>
            </g>
            <defs>
              <clipPath id="clip0_17342_319">
                <rect width="21" height="20" fill="white" transform="translate(5.34741 6)"/>
              </clipPath>
            </defs>
          </svg>
          <span class="info-name">{{ session.desktop }}</span>
        </div>
      </div>
    </div>

    <div class="session">
      <div class="holder-info">
        <span>{{ session.sessionId }}</span>
        <svg @click="copySessionIdToBuffer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.1875 0.47583H4.1875C4.03832 0.47583 3.89524 0.535093 3.78975 0.640583C3.68426 0.746072 3.625 0.889146 3.625 1.03833V3.85083H0.8125C0.663316 3.85083 0.520242 3.91009 0.414753 4.01558C0.309263 4.12107 0.25 4.26415 0.25 4.41333V13.4133C0.25 13.5625 0.309263 13.7056 0.414753 13.8111C0.520242 13.9166 0.663316 13.9758 0.8125 13.9758H9.8125C9.96168 13.9758 10.1048 13.9166 10.2102 13.8111C10.3157 13.7056 10.375 13.5625 10.375 13.4133V10.6008H13.1875C13.3367 10.6008 13.4798 10.5416 13.5852 10.4361C13.6907 10.3306 13.75 10.1875 13.75 10.0383V1.03833C13.75 0.889146 13.6907 0.746072 13.5852 0.640583C13.4798 0.535093 13.3367 0.47583 13.1875 0.47583ZM9.25 12.8508H1.375V4.97583H9.25V12.8508ZM12.625 9.47583H10.375V4.41333C10.375 4.26415 10.3157 4.12107 10.2102 4.01558C10.1048 3.91009 9.96168 3.85083 9.8125 3.85083H4.75V1.60083H12.625V9.47583Z"
            fill="#696F79" />
        </svg>
        <transition name="fade">
          <span v-if="showTooltip" class="tooltip">Copied</span>
        </transition>
      </div>
    </div>

    <div class="started">
      <div class="holder-info">
        <span>{{ reformatDate(session.started) }}</span>
      </div>
    </div>

    <div class="finished">
      <div class="holder-info">
        <span>{{ session.finished }}</span>
      </div>
    </div>

    <div class="card-arrow" @click="onSessionClick(session, sessionIndex)">
      <div class="holder-info">
        <arrowDown class="arrow" :class="{ 'active-arrow': session.isActive }" />
      </div>
    </div>

  </div>
</template>
<script>
import ArrowDown from "@/components/icons/ArrowDown.vue";
import reformatDate from "@/mixins/reformatDate";
export default {
  name: "CustodianSessionItem",
  components: {
    ArrowDown,
  },
  emits: ["activeSession"],
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  mixins: [reformatDate],
  methods: {
    onSessionClick(currentSession, index) {
      this.$emit("activeSession", currentSession, index);
    },
    copySessionIdToBuffer() {
      navigator.clipboard.writeText(this.session.sessionId)
        .then(() => {
          this.showTooltip = true;

          setTimeout(() => {
            this.showTooltip = false;
          }, 2000);
        })
    },
  },
}
</script>
<style scoped>
.card {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  transition: all 0.5s;
  position: relative;
  border-bottom: 1px solid var(--case-btn-border-color);
  width: 100%;
}

.email {
  width: 18%;
}

.device {
  width: 25%;
}

.session {
  width: 15%;
}

.started {
  width: 18%;
}

.finished {
  width: 20%;
}

.holder-info {
  display: flex;
  align-items: center;
  margin: 0 0 0 15px;
  color: var(--secondary-text-color);
}

.session .holder-info span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  width: 100%;
}

.session .holder-info svg {
  cursor: pointer;
}

.device .holder-info {
  display: flex;
  align-items: center;
  gap: 25px;
}

.device .holder-info span {
  margin: 0 0 0 5px;
  color: var(--secondary-text-color);
}

.device .holder-info .device-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.arrow {
  transition: transform 0.3s;
}

.card-arrow {
  cursor: pointer;
}

.card-arrow .holder-info .arrow {
  width: 21px;
  height: 21px;
}

.active-arrow {
  transform: rotate(-180deg);
}

.tooltip {
  display: inline-block;
  position: absolute;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  z-index: 100;
  border-radius: 4px;
  font-size: 12px;
  transform: translate(60%, -100%);
  transition: all .5s ease;
  max-width: 10px;
}
</style>