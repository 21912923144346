<template>
  <div class="notification-wrapper">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="holder-table">
        <table cellpadding="0" cellspacing="0" border="0">
          <TableHeader>
          <tr>
            <th class="w-300">
              <div
                  id="notification_users_sort_by_name"
                  class="table-header-box"
                  @click="sortedBy('userName')"
                  :class="{
                    'sort-active': this.sortBy === 'userName',
                    'sorted-desc': this.order === 'desc',
                  }"
              >
                <CaseIcon />
                <span class="header-table-text">Contact</span>
                <arrowDown class="arrow" />
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.34961 0.861328C1.24504 0.861328 0.349609 1.75676 0.349609 2.86133V16C0.349609 17.1046 1.24504 18 2.34961 18H17.6522C18.7568 18 19.6522 17.1046 19.6522 16V2.86133C19.6522 1.75676 18.7568 0.861328 17.6522 0.861328H2.34961ZM11.1918 10.9515C10.4486 10.8618 9.63781 10.817 8.75946 10.817C8.71442 10.817 8.66937 10.8226 8.62433 10.8338C8.57928 10.845 8.53424 10.8618 8.4892 10.8842C8.44415 10.9067 8.40474 10.9347 8.37096 10.9683C8.26961 11.0803 8.21893 11.2091 8.21893 11.3548V13.8418L2.5941 9.2038L8.21893 4.61619V7.03603C8.21893 7.18167 8.27242 7.3077 8.3794 7.41413C8.48638 7.52056 8.61307 7.57377 8.75946 7.57377C11.8675 7.57377 14.1591 8.4308 15.6343 10.1449C16.6027 11.2539 17.1939 12.6207 17.4079 14.2451C17.1939 13.9427 16.9687 13.6682 16.7322 13.4217C16.4957 13.1753 16.2029 12.9148 15.8538 12.6403C15.5048 12.3658 15.1078 12.125 14.663 11.9177C14.2182 11.7105 13.7143 11.52 13.1512 11.3464C12.5882 11.1727 11.935 11.0411 11.1918 10.9515Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Action</span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.3985 3.35802C14.8482 3.35802 15.2291 3.52263 15.5414 3.85185C15.8536 4.18107 16.0098 4.58272 16.0098 5.05679V14.3012C16.0098 14.7753 15.8536 15.177 15.5414 15.5062C15.2291 15.8354 14.8482 16 14.3985 16H1.62101C1.17136 16 0.790406 15.8354 0.47815 15.5062C0.165894 15.177 0.00976562 14.7753 0.00976562 14.3012V5.05679C0.00976562 4.58272 0.165894 4.18107 0.47815 3.85185C0.790406 3.52263 1.17136 3.35802 1.62101 3.35802H4.80602V1.65926C4.80602 1.21152 4.96215 0.823045 5.2744 0.493827C5.58666 0.164609 5.96761 0 6.41726 0H9.60227C10.0519 0 10.4329 0.164609 10.7451 0.493827C11.0574 0.823045 11.2135 1.21152 11.2135 1.65926V3.35802H14.3985ZM9.60227 3.35802V1.65926H6.41726V3.35802H9.60227Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Case</span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 7.5C9.70938 7.5 11.5 5.82129 11.5 3.75C11.5 1.67871 9.70938 0 7.5 0C5.29063 0 3.5 1.67871 3.5 3.75C3.5 5.82129 5.29063 7.5 7.5 7.5ZM10.3 8.4375H9.77812C9.08437 8.73633 8.3125 8.90625 7.5 8.90625C6.6875 8.90625 5.91875 8.73633 5.22188 8.4375H4.7C2.38125 8.4375 0.5 10.2012 0.5 12.375V13.5938C0.5 14.3701 1.17188 15 2 15H13C13.8281 15 14.5 14.3701 14.5 13.5938V12.375C14.5 10.2012 12.6187 8.4375 10.3 8.4375Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Actor</span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.40871 7.51465C9.04274 7.81854 8.45774 8.04257 7.99969 8.04257C7.54163 8.04257 6.95649 7.81895 6.59052 7.51507C5.97607 7.88104 5.61941 8.53326 5.56982 9.42215H10.4295C10.38 8.53326 10.0233 7.88076 9.40871 7.51465Z" fill="#DDE3E3"/>
                  <path d="M8.00004 6.85858C8.43545 6.86053 8.82906 6.59969 8.99712 6.19803C9.16504 5.79622 9.0742 5.33289 8.76698 5.02428C8.45976 4.71567 7.9967 4.62275 7.5942 4.789C7.19184 4.95511 6.9292 5.34761 6.9292 5.78303C6.92851 6.37553 7.40753 6.85678 8.00004 6.85858Z" fill="#DDE3E3"/>
                  <path d="M6.52721 7.06322C6.61068 7.02127 6.71179 7.03849 6.77666 7.10572C7.04221 7.3821 7.60249 7.61544 7.99999 7.61544C8.39749 7.61544 8.95749 7.38169 9.22304 7.1053C9.28804 7.03808 9.38916 7.02113 9.47249 7.06335C10.3025 7.47933 10.7999 8.31988 10.8569 9.4221H10.951V2.87988H5.01318V9.4221H5.14277C5.19999 8.31988 5.69721 7.47919 6.52721 7.06322ZM7.99985 4.28072C8.60791 4.27891 9.15707 4.64363 9.3911 5.20488C9.62513 5.76599 9.49777 6.41294 9.06846 6.84349C8.63929 7.27419 7.99277 7.40349 7.43082 7.17127C6.86888 6.93905 6.50235 6.39099 6.50235 5.78294C6.50193 4.95502 7.17179 4.28294 7.99985 4.28072Z" fill="#DDE3E3"/>
                  <path d="M6.16548 1.20867H9.83493C9.95048 1.20867 10.0483 1.13617 10.0483 1.04867C10.0483 0.961172 9.95048 0.888672 9.83493 0.888672H6.16548C6.04993 0.888672 5.95215 0.961172 5.95215 1.04867C5.95215 1.13617 6.04993 1.20867 6.16548 1.20867Z" fill="#DDE3E3"/>
                  <path d="M12.4802 0H3.52024C3.24538 0 3.02246 0.222917 3.02246 0.497778V15.5022C3.02246 15.7771 3.24538 16 3.52024 16H12.4802C12.7551 16 12.978 15.7771 12.978 15.5022V0.497778C12.978 0.222917 12.7551 0 12.4802 0ZM6.16552 0.462222H9.83496C10.1877 0.462222 10.475 0.724583 10.475 1.04889C10.475 1.37319 10.1877 1.63556 9.83496 1.63556H6.16552C5.81274 1.63556 5.52552 1.37319 5.52552 1.04889C5.52552 0.724583 5.81274 0.462222 6.16552 0.462222ZM4.58691 2.80889C4.58691 2.6125 4.74607 2.45333 4.94246 2.45333H11.0225C11.2189 2.45333 11.378 2.6125 11.378 2.80889V9.49333C11.378 9.68972 11.2189 9.84889 11.0225 9.84889H4.94246C4.74607 9.84889 4.58691 9.68972 4.58691 9.49333V2.80889ZM4.90691 11.52C4.78913 11.52 4.69357 11.4244 4.69357 11.3067C4.69357 11.1889 4.78913 11.0933 4.90691 11.0933H11.058C11.1758 11.0933 11.2714 11.1889 11.2714 11.3067C11.2714 11.4244 11.1758 11.52 11.058 11.52H4.90691ZM10.738 12.7644C10.738 12.8822 10.6425 12.9778 10.5247 12.9778H5.44024C5.32246 12.9778 5.22691 12.8822 5.22691 12.7644C5.22691 12.6467 5.32246 12.5511 5.44024 12.5511H10.5247C10.6425 12.5511 10.738 12.6467 10.738 12.7644ZM11.5914 14.4H4.37357C4.25579 14.4 4.16024 14.3044 4.16024 14.1867C4.16024 14.0689 4.25579 13.9733 4.37357 13.9733H11.5914C11.7091 13.9733 11.8047 14.0689 11.8047 14.1867C11.8047 14.3044 11.7091 14.4 11.5914 14.4Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Actor’s Role</span>
              </div>
            </th>
            <th>
              <div
                  id="notification_users_sort_by_date"
                  class="table-header-box"
                  @click="sortedBy('createdAt')"
                  :class="{
                    'sort-active': this.sortBy === 'createdAt',
                    'sorted-desc': this.order === 'desc',
                  }"
              >
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Date</span>
                <arrowDown class="arrow" />
              </div>
            </th>
          </tr>
          </TableHeader>
          <Loader v-if="loadFlag" :load-flag="loadFlag" />
          <template v-else>
            <NotificationTeamItem
                v-if="notificationsTeamList.length"
                :notificationsTeamList="notificationsTeamList"
            />
            <p v-if="!notificationsTeamList.length" class="no-found">No data</p>
          </template>
        </table>
      <pagination v-if="notificationsTeamList.length && count !== 1"
                  v-model="currentPage"
                  :total-pages="count"
                  :link-gen="linkGen"
      ></pagination>
    </div>
  </div>
</template>

<script>
import linkGenerator from "../../constants/linkGen";
import AlertErrorMsg from "../profile/AlertErrorMsg";
import NotificationTeamItem from "./NotificationTeamItem";
import TableHeader from "../table/TableHeader";
import {mapActions} from "vuex";
import Loader from "../loader/Loader";
import ArrowDown from "../icons/ArrowDown";
import CaseIcon from "../icons/CaseIcon";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "NotificationsTeam",
  components: {
    Pagination,
    Loader,
    CaseIcon,
    ArrowDown,
    AlertErrorMsg,
    NotificationTeamItem,
    TableHeader,
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      sortBy: 'createdAt',
      order: 'desc',
      dateFrom: '',
      dateTo: '',
      notificationsTeamList: [],
      notificationsTeamNotViewList: [],
      loadFlag: false,
      errorMessage: '',
    }
  },
  props: {
    dataRange: {},
    searchText: {
      type: String,
      required: true,
    },
  },
  watch: {
    searchText() {
      this.getNotificationTeam()
    },
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1])  : ''
      this.currentPage = 1;
      this.getNotificationTeam();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.currentPage = +newVal;
          this.getNotificationTeam();
        } else {
          this.currentPage = 1;
        }
      }
    },
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
  },
  methods: {
    ...mapActions({
      getNotificationCount: 'notifications/getNotificationCount',
      getNotificationAll: 'notifications/getNotificationAll'
    }),
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/notifications/team')
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getNotificationTeam()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    notificationView(oldArr, arr) {
      oldArr.forEach(item => {
        if(!item.viewed) {
          arr.push(item.id)
        }
      })
    },
    getNotificationTeam() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.notifications.getNotificationTeam({
          sortBy: this.sortBy,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          order: this.order,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: this.currentPage,
          size: this.size,
        }).then((res) => {
          this.notificationsTeamList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
          this.notificationsTeamNotViewList = []
          this.notificationView(this.notificationsTeamList, this.notificationsTeamNotViewList)
          if(this.notificationsTeamNotViewList.length) {
            this.changeStatusNotificationType()
          }
        }, (error) => {
          if(error) {
            if(error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            }else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          }
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    changeStatusNotificationType() {
      this.$load(async () => {
        await this.$api.notifications.changeStatusNotificationType({
          notificationIds : this.notificationsTeamNotViewList.toString(),
        }).then(() => {
          this.getNotificationAll({}).then(() => {
            this.getNotificationCount({})
          })
        })
      })
    },
  },
  mounted() {
    this.currentPage = +this.$route.query.page || 1;
    this.getNotificationTeam()
  }
}
</script>

<style scoped>
.holder-table {
  position: relative;
}
</style>
