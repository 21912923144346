let checkAll = {
    computed: {
        checkAll: {
            get() {
                if(this.users && this.users.length) {
                    for (let i = 0; i < this.users.length; i++) {
                        if (!this.users[i].isActive) return false;
                    }
                    return true;
                }
                return false
            },
            set(value) {
                if (this.users && this.users.length) {
                    this.users.forEach(function(item) {
                        item.isActive = value;
                    })
                }
            }
        },
        checkSome() {
            return this.users.filter(i => i.isActive)
        },
        checkAllKeys: {
            get() {
                if(this.keysData.length) {
                    for (let i = 0; i < this.keysData.length; i++) {
                        if (!this.keysData[i].isActive) return false;
                    }
                    return true;
                }
                return false
            },
            set(value) {
                this.keysData.forEach(function(item) {
                    item.isActive = value;
                })
            }
        },
        checkSomeKeys() {
            return this.keysData.filter(i => i.isActive)
        },
    }
}

export default checkAll